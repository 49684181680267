import { Subscription } from 'rxjs';
import { IEvent } from '../../interfaces/events/event.interface';
import { EventsService } from '../../services/events/events.service';


export class EventCreateClass {
  protected _step: number = 1;
  protected _showTutorial: boolean = true;
  protected _event: IEvent = {
    name: 'Name',
    description: '',
    categoryId: 1
  }

  constructor(protected eventsService: EventsService) {
    // do nothing
  }

  protected emitEventCreationFinished(event: IEvent){
    // must override
  }

  protected init(): void {
    this.eventsService.addPollingClient('EventCreateClass');
  }
  protected destroy(): void {
    this.eventsService.removePollingClient('EventCreateClass');
  }

  protected onEventCreated(event: IEvent): void {
    console.log('Step 2');
    this._event = event;
    this._step = 2;
  }

  protected onEventStart(event: IEvent): void{
    console.log('Step 3');
    this._event = event;
    this._step = 3;
  }

  protected onEventCreationFinished(event: IEvent): void {
      this.emitEventCreationFinished(event);
  }

  protected goToStudio(event: IEvent, newWindow: boolean): void{
    //Must be overriden
  }
}
