import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, first, from, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';

import { API } from '../../const/api.const';
import { COMMON } from '../../const/common.const';
import { DeviceService } from '../../services/device/device.service';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  refreshingToken: boolean;
  skipIntercept: boolean;
  skipRequestUrls: string[];

  private _token: string | undefined;
  private loginAttempt: number = 0;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private authService: AuthService,
              private userService: UserService,

              private router: Router,
              private deviceService: DeviceService) {
    this.skipRequestUrls = COMMON.interceptors.skip;
    this.refreshingToken = false;
    this.skipIntercept = false;
  }

  get token(): string | undefined {
    return this._token;
  }

  set token(token: string | undefined) {
    this._token = 'Bearer ' + token;
  }

  static isServerError(err: any): boolean {
    return (err.status > 499 && err.status < 600);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | Observable<any> {
    this.token = this.authService.accessToken;

    this.skipRequestUrls.forEach(curSkipRequest => {
      if (request.url.includes(curSkipRequest)) {
        console.log('**** Interceptor Skip: ' + request.url);
        this.skipIntercept = true;
      }
    });

    if (this.skipIntercept) {
      this.skipIntercept = false;
      return next.handle(request);

    } else {

      const TOKENIZED_REQUEST = this.addAuthHeaders(request);
      return next.handle(TOKENIZED_REQUEST)
        .pipe(
         /* retryWhen(errors =>
            errors.pipe(
              concatMap((e, i) =>
                iif(
                  () => i > API.attempts || !AuthInterceptor.isServerError(e),
                  throwError(() => e),
                  of(e).pipe(
                    tap(() => {
                      console.log(`Error 500 received. Retrying (${i})...`);
                    }),
                    delay(API.delayAttempts))
                )
              )
            )
          ),*/
          timeout(API.timeout),
          catchError((err: Error) => {
            if (err instanceof HttpErrorResponse) {

              if (err.status === 401) {
                  if (err.error.message === 'Token expired') {
                    return this.handleTokenExpiredError(
                      TOKENIZED_REQUEST,
                      next
                    );
                  } else if(err.error.message === 'Login required to do this action') {
                    // This development ensures that even if you have two tabs in the browser,
                    // the access tokens are updated to keep the session open.
                    if (this.authService.accessToken && this.authService.refreshToken  && this.loginAttempt < 1) {
                      this.loginAttempt = 1;
                      this.authService.autoAuthAvailable()
                        .pipe(first())
                        .subscribe({
                        next: (result: boolean) => {
                          if(result) {
                            this.loginAttempt = 0;
                          }
                        }
                      });
                    } else {
                      this.loginAttempt = 0;
                      this.authService.authDisallowed()
                        .catch(console.error);
                      this.handleAuthError();
                    }
                  } else {
                    if (err.error.code === '') {
                      this.handleAuthError();
                    }
                  }

              } else {
                if (AuthInterceptor.isServerError(err)) {
                  this.handleServerError();
                } else {
                  if (err.status === 0) {
                    this.handleUnknownError();
                  }
                }
              }
            } else {
              if (err.name && err.name === 'TimeoutError') {
                err.message = 'Request Time-out';
              }
            }
            return throwError(() => err);
          }));
    }
  }

  private handleTokenExpiredError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshingToken) {

      this.refreshingToken = true;
      this.refreshTokenSubject.next('');

      return this.userService.renewToken(0)
        .pipe(
          switchMap((result: boolean) => {
            this.refreshingToken = false;
            if (result) {
              this.token = this.authService.accessToken;
              this.refreshingToken = false;
              this.refreshTokenSubject.next(this.token);
              return next.handle(this.addAuthHeaders(request));
            } else {
              return from(this.authService.authDisallowed());
            }

          }),
          catchError((err) => {
            this.refreshingToken = false;
            this.authService.authDisallowed()
              .catch(console.error);
            return throwError(() => err);
          })
        );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => !!token),
      take(1),
      switchMap(() => next.handle(this.addAuthHeaders(request)))
    );
  }

  private addAuthHeaders(request: HttpRequest<any>) {

    let headers: HttpHeaders = request.headers;
    if (this.deviceService.source) {
      headers = headers.set(API.headers.source, this.deviceService.source);
    }
    if (this.authService.accessToken && this.authService.accessToken !== '') {
      headers = headers.set(API.headers.authToken, `Bearer ${this.authService.accessToken}`);
    }
    return request.clone({headers});
  }

  private handleServerError() {
    if (this.router.url !== COMMON.urlsAbs.publish) {
      //SHOW SERVER ERROR WARNING
    }
  }

  private handleAuthError() {
    //SHOW ERROR ACCESS DENIED
    this.userService.resetUser();
    this.router.navigateByUrl(COMMON.urls.login)
      .catch(console.error);

  }

  private handleUnknownError() {
    if (this.router.url !== COMMON.urlsAbs.publish) {
      //SHOW SERVER ERROR WARNING
    }
  }
}
