import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';

import { TranslateService } from '@ngx-translate/core';
import { ENV_COMMON } from '@azz-life-streamer/environments';
import {
  AlertService,
  AlertStatus,
  COMMON,
  DeviceService,
  IAlertDataButton,
  IEvent,
  IEventShareInfoSection,
} from '@azz-life-streamer/shared';


@Injectable({
  providedIn: 'root'
})
export class ShareInfoService {

  constructor(private translate: TranslateService,
              private deviceService: DeviceService,
              private alertService: AlertService) { }

  public getSharingData(event: IEvent, section: string): IEventShareInfoSection {
    const TITLE: string = 'Azzulei TV - ' + this.translate.instant('eventStatus.shareTitle.' + section);
    let text: string = 'Azzulei TV\n\n';
    let url: string = ENV_COMMON.web;

    switch (section) {
      case 'produce':
        text += this.translate.instant('eventStatus.shareMessage.' + section,
          {
            token: event.producerToken,
            name: event.name,
            server: ENV_COMMON.web
          });
        url += COMMON.urls.produce + '/' + event.producerToken;
        break;
      case 'publish':
        text += this.translate.instant('eventStatus.shareMessage.' + section,
          {
            token: event.publisherToken,
            name: event.name,
            server: ENV_COMMON.web
          });
        url += COMMON.urls.publish + '/' + event.publisherToken;
        break;
      case 'watch':
        text += this.translate.instant('eventStatus.shareMessage.' + section,
          {
            token: event.viewerToken,
            name: event.name,
            server: ENV_COMMON.web
          });
        url += section + '/' + event.viewerToken;
        break;
    }
    return {title: TITLE, text, url};
  }

  public shareToken(options: IEventShareInfoSection): void {
    if(this.deviceService.device?.platform !== 'web') {
      Share.share(options)
        .catch((error: any) => {
          this.showError(error);
        });
    } else {
      navigator.share(options)
        .catch((error: any) => {
          this.showError(error);
        });
    }
  }

  private showError(error: any): void {
    const BUTTONS: IAlertDataButton[] = [];
    BUTTONS.push({
      text: 'general.close',
      color: 'primary',
      handler: () => {
        this.alertService.close();
      },
    });

    let title: string = this.translate.instant('general.error');
    let text: string = this.translate.instant('general.serviceUnavailable');

    if (error.includes('not available')) {
      title = this.translate.instant('general.shareUnavailable');
      text = this.translate.instant('general.shareUnavailableHelp');
    }

    this.alertService.showAlert({
      show: true, status: AlertStatus.error, title, text, closeButton: true, buttons: BUTTONS,
    });
  }
}
