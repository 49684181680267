import { Subscription } from 'rxjs';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAvProdComposerSettings, IAvProdOverlayScore } from '../../interfaces/av-producer/composer-settings.interface';
import { AvProdOverlayScoreSport, AvProdSettingsType } from '../../const/av-producer.const';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { ModalDialogId } from '../../enums/utils.enum';

export class ToolboxScoreClass {

  protected lastChangeTS: number = 0;
  protected newSettingsSubscription: Subscription | undefined;
  protected scoreSettings: IAvProdOverlayScore | undefined;
  protected settingsScoreForm: FormGroup = new FormGroup([]);

  protected itemSettingEnable: IAvSettingsItemConfig = {
      id: 'visible',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
  };

  constructor(protected avProd: AvProducerService, protected modalService: ModalManagerService) {
    this.settingsScoreForm.addControl(this.itemSettingEnable.id, new FormControl());
  }

  init(): void {
    this.updateScoreSettings();
    this.newSettingsSubscription = this.avProd.onNewComposerSettings$.subscribe(() => this.updateScoreSettings());
  }

  close(): void {
    if (this.newSettingsSubscription !== undefined) this.newSettingsSubscription.unsubscribe();
  }

  protected onConfigScore(){
    this.modalService.show({id: ModalDialogId.producerSettings, title: 'producer.producerSettings', subId: 4}, false);
  }

  protected updateScoreSettings(){
    this.scoreSettings = this.avProd.composerSettings.overlayScore;
    this.lastChangeTS = (new Date()).getTime();
    this.settingsScoreForm.get('visible')?.setValue(this.avProd.composerSettings.overlayScore?.enabled);
    console.log('[ToolboxScoreClass] updateScoreSettings: ' + JSON.stringify(this.scoreSettings));
  }

  protected onSettingChanged(event: any) {
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] onSettingChanged');
    settings.overlayScore = {};
    settings.overlayScore.enabled = this.settingsScoreForm.get('visible')?.value;
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected increaseScore(index: number, increment: number){
    let settings: IAvProdComposerSettings = {};
    let newValue: number = 0;
    console.log('[ToolboxScoreClass] increaseScore: ' + index + ' ' + increment);
    if (this.scoreSettings?.values !== undefined){
      let values: number[] = [];
      for (let i=0; i<this.scoreSettings?.values.length; i++) {
        if (i === index) {
          newValue = this.scoreSettings?.values[i] + increment;
          if (newValue >= 0){
            values.push(newValue);
          }
          else{
            values.push(0);
          }
        }
        else {
          values.push(this.scoreSettings?.values[i]);
        }
      }

      // Check sport special features and set change
      if (this.scoreSettings.sport === AvProdOverlayScoreSport.tennis){

          if ((values[index] >= 4)&&
              (values[index] - values[(index+1)%2] > 1)){
            // Game won
            // Increase games and update sets
            // ...

          }
          else if ((values[0] === 4)&&
                   (values[1] === 4)){
            // Back to Deuce
            values[0] = 3;
            values[1] = 3;
          }
      }
      this.scoreSettings.values = values;

      settings.overlayScore = {};
      settings.overlayScore.values = values;
      this.avProd.azzChangeComposerSettings(settings);
    }
  }

  protected increaseScoreSet(index: number, increment: number){
    let settings: IAvProdComposerSettings = {};
    let newValue: number = 0;
    console.log('[ToolboxScoreClass] increaseScoreSet: ' + index + ' ' + increment);
    if (this.scoreSettings?.valuesSets !== undefined){
      let values: number[] = [];
      for (let i=0; i<this.scoreSettings?.valuesSets.length; i++) {
        if (i === index) {
          newValue = this.scoreSettings?.valuesSets[i] + increment;
          if (newValue >= 0){
            values.push(newValue);
          }
          else{
            values.push(0);
          }
        }
        else {
          values.push(this.scoreSettings?.valuesSets[i]);
        }
      }
      this.scoreSettings.valuesSets = values;

      settings.overlayScore = {};
      settings.overlayScore.valuesSets = values;
      this.avProd.azzChangeComposerSettings(settings);
    }
  }
}
