import { Subscription } from 'rxjs';

import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAvProdInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdInputType, AvProdInputTypeNumber } from '../../const/av-producer.const';


export class ToolboxSoundsClass {

  protected listInputs: IAvProdInput[] = [];

  protected inputListSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerService) {
    // do nothing
  }

  init(): void {
    this.inputListSubscription = this.avProd.components$.subscribe( () => this.updateList());
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe( () => this.updateList());
    this.updateList();
  }

  close(): void {
    if (this.inputListSubscription !== undefined) this.inputListSubscription.unsubscribe();
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
  }

  protected updateList() {
    console.log('[ToolboxHighlightsClass] update');
    this.listInputs = this.avProd.inputs.filter( input => input.info.inputTypeNumber === AvProdInputTypeNumber.audioClip);
  }

  protected toggleAudioClip(id: number) {
    const CLIP: IAvProdInput | undefined = this.listInputs.find(input => input.info.id === id);
    if ((CLIP !== undefined) && (CLIP.info.inputTypeNumber === AvProdInputTypeNumber.audioClip)) {
      this.avProd.azzCmdInputToggleClipPlay(id);
    }
  }
}
