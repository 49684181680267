import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { from, Observable, switchMap, tap } from 'rxjs';
import { GoogleAuth, User, Authentication } from '@codetrix-studio/capacitor-google-auth';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { ILoginGoogleUser } from '../../interfaces/user/user.interface';
import { COMMON } from '../../const/common.const';
import { UserService } from '../user/user.service';
import { DeviceService } from '../device/device.service';


@Injectable({
  providedIn: 'root'
})
export class GoogleSSOService {

  public loginGoogleUser: ILoginGoogleUser = {
    clientId: '',
    credential: ''
  };

  constructor(private userService: UserService,
              private deviceService: DeviceService,
              private platform: Platform) {
    this.initializeApp();
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      // Send options in initialize just for debug in web
      // For app capacitor sends the configuration to plugin (capacitor.config.json)
      GoogleAuth.initialize({
        clientId: ENV_COMMON.googleSsoClientId.web,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      })
    })
  }

  signIn(versionCode: number): Observable<boolean> {
    return from(GoogleAuth.signIn())
      .pipe(
        tap((user: User) => {
          const CLIENT_ID: string | string[] = this.deviceService.device?.platform === 'ios' ? ENV_COMMON.googleSsoClientId.ios :
            this.deviceService.device?.platform === 'android' ? ENV_COMMON.googleSsoClientId.android :
              ENV_COMMON.googleSsoClientId.web;
          if (user) {
            this.loginGoogleUser.credential = user.authentication.idToken;
            this.loginGoogleUser.clientId = CLIENT_ID;
            this.loginGoogleUser.version = versionCode;
            this.saveAuthentication(user.authentication);
          } else {
            throw new Error('not logged');
          }
        }),
        switchMap(() => this.userService.loginGoogle(this.loginGoogleUser))
      );
  }

  signOut(): Observable<any> {
    return from(GoogleAuth.signOut())
      .pipe(
        tap(console.warn),
        /* tap((result: any) => {

         })*/
      );
  }

  refresh(): Observable<Authentication> {
    return from(GoogleAuth.refresh())
      .pipe(
        tap(console.warn),
        /* tap((auth: Authentication) => {

         })*/
      );
  }

  saveAuthentication(auth?: Authentication): void {
    if (auth) {
      localStorage.setItem(COMMON.storageKeys.googleAuth, JSON.stringify(auth));
    } else {
      localStorage.removeItem(COMMON.storageKeys.googleAuth);
    }
  }

  getAuthentication(): Authentication | undefined {
    const AUTH: string | null = localStorage.getItem(COMMON.storageKeys.googleAuth)
    if (AUTH !== null) {
      return JSON.parse(AUTH);
    }
    return undefined;
  }
}
