import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DisplayOptions } from '../../enums/publish-stream.enum';
import { IPublisherSettings } from '../../interfaces/publish-stream/settings.interface';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { IMediaDevice, IMediaInputDevices } from '../../interfaces/publish-stream/devices.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { PublishStreamService } from '../../services/publish-stream/publish-stream.service';
import { UserService } from '../../services/user/user.service';


export class BroadcasterSettingsInitClass {
  protected _slotId: string | undefined;
  protected _displayOption: string = DisplayOptions.outputInput;
  protected _container: string = 'publisher';
  protected _enabled: boolean = true;

  //protected mirroredImage: boolean = true;
  protected settingsForm: FormGroup = new FormGroup([]);
  protected itemsSettings: IAvSettingsItemConfig[] = [
    {
      id: 'streamName',
      type: AvProdSettingsType.text,
      name: 'publishSettings.streamName',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: '',
      validators: [Validators.required]
    },
    {
      id: 'videoEnabled',
      type: AvProdSettingsType.none,
      name: 'publishSettings.videoEnabled',
      visible: false,
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: true,
    },
    {
      id: 'audioEnabled',
      type: AvProdSettingsType.none,
      name: 'publishSettings.audioEnabled',
      visible: false,
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: true
    }

  ];

  protected mediaInputDeviceSubscription: Subscription | undefined = undefined;
  private publishStreamSubscription: Subscription | undefined;

  protected _settings: IPublisherSettings | undefined;

  constructor(
    protected publishService: PublishStreamService,
    protected userService: UserService) {
    this.itemsSettings.forEach((element: IAvSettingsItemConfig) => {
      this.settingsForm.addControl(element.id, new FormControl(element.value, element.validators ?? []));
    });
  }

  protected init(): void {

    this.publishStreamSubscription?.unsubscribe();
    this.publishStreamSubscription = this.publishService.slotChangeSource.subscribe((slotId: string)=> {
      if ((this._slotId !== undefined)&&(slotId === this._slotId)){
        this.setSlotId(this._slotId);
      }
    });

    this.mediaInputDeviceSubscription = this.publishService.mediaInputDevices.subscribe({
      next: (devices: IMediaInputDevices) => {
        if (this._settings !== undefined) {
          if (this._settings.videoDevice === undefined) {
            this._settings.videoDevice = this.publishService.defaultVideoInput ?? devices.videoInputs[0];
          }
          if (this._settings.audioInputDevice === undefined) {
            this._settings.audioInputDevice = this.publishService.defaultAudioInput ?? devices.audioInputs[0];
          }
        }
      }
    });
    if ((this._settings?.streamName === '') && (this.userService.user.name !== undefined)) {
      this._settings.streamName = this.userService.user.name;
    }
    this.settingsForm.get('streamName')?.setValue(this._settings?.streamName);
  }

  protected destroy(): void {
    if (this.mediaInputDeviceSubscription) {
      this.mediaInputDeviceSubscription.unsubscribe();
    }
  }

  protected setSlotId(value: string | undefined):void {
    this._slotId = value;
    console.log('[BroadcasterSettingsInitClass] setSlotId: ' + value);
    if (this._slotId !== undefined) {
      const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this._slotId);
      if (SETTINGS !== null) {
        if(SETTINGS.videoDevice === undefined &&
          (this.publishService.userSettings?.videoDevice !== undefined
            || this.publishService.defaultVideoInput !== undefined)) {
          SETTINGS.videoDevice = this.publishService.userSettings?.videoDevice ?? this.publishService.defaultVideoInput;
        }
        this._settings = SETTINGS;
        this.settingsForm.get('streamName')?.setValue(this._settings.streamName);
      }
    }
  }

  protected setDisplayOption(value: string): void {
    this._displayOption = value;
  }

  protected setContainer(value: string): void {
    this._container = value;
  }

  protected setEnabled(value: boolean): void {
    this._enabled = value;
  }

  protected emitChangeDisplayOption(value: string): void {
    //must be overridden
  }

  protected emitStartBroadcast(value: IPublisherSettings): void {
    //must be overridden
  }

  protected onSettingChanged(setting: string): void {
    switch (setting) {
      case 'streamName':
        if (this._settings) {
          this._settings.streamName = this.settingsForm.get('streamName')?.value;
        }
        break;
    }
    if ((this._slotId !== undefined) && (this._settings !== undefined)) {
      this.publishService.changeStreamSlotSettings(this._slotId, this._settings);
    }
    //this.startBroadcasting();
  }

  protected onChangeVideoMirror(value: boolean): void {
    if (this._settings !== undefined){
      this._settings.videoMirror = value;
    }
  }

  protected onChangeVideoZoom(value: number): void {
    if (this._settings !== undefined){
      this._settings.videoZoom = value;
    }
  }

  protected startBroadcasting(): void {
    console.log('[BroadcasterSettingsInit] startBroadcasting');
    if ((this._slotId !== undefined) &&
      (this._settings !== undefined)) {
      this.publishService.changeStreamSlotSettings(this._slotId, this._settings);
      this.emitStartBroadcast(this._settings);
    }
  }

  protected onCameraToggled(device: IMediaDevice): void {
    if ((this._slotId !== undefined) && (this._settings !== undefined)) {
      this._settings.videoDevice = device;
      this.publishService.changeStreamSlotSettings(this._slotId, this._settings);
    }
  }
}
