import { AlertStatus } from '../../enums/common.enum';
import { IAlertData, ITabItemExtended } from '../../interfaces/utils/utils.interface';


export class ProducerSettingsClass {

  protected tabItems: ITabItemExtended[] = [
    {
      id: 1,
      label: 'producerSettings.eventSettings',
      title: 'producerSettings.eventSettings',
      icon: 'card-list',
      iconApp: 'card-list'
    },
    {
      id: 2,
      label: 'producerSettings.video',
      title: 'producerSettings.video',
      icon: 'display',
      iconApp: 'display'
    },
    {
      id: 7,
      label: 'producerSettings.layouts',
      title: 'producerSettings.layouts',
      icon: 'grid',
      iconApp: 'grid'
    },
    {
      id: 4,
      label: 'producer.score',
      title: 'producer.score',
      icon: 'score',
      iconApp: 'score'
    },
    {
      id: 5,
      label: 'producer.highlights',
      title: 'producer.highlights',
      icon: 'replay',
      iconApp: 'collection-play'
    },
    {
      id: 6,
      label: 'producerSettings.output',
      title: 'producerSettings.output',
      icon: 'box-arrow-right',
      iconApp: 'box-arrow-right'
    }
  ]
  protected tabSelectedItem: ITabItemExtended = this.tabItems[0];

  protected displayAlert(data: IAlertData): void {
    // must be overridden
  }
  protected setInitialTab(value: number): void {
    const INIT_TAB: ITabItemExtended | undefined = this.tabItems.find(element => element.id === value);
    if (INIT_TAB !== undefined){
      this.tabSelectedItem = INIT_TAB;
    }
  }

  protected onTabClick(item: ITabItemExtended): void {
    if (this.tabSelectedItem.dirty === true){
      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'producerSettings.closeDirtyTabConfirmation',
        closeButton: true,
        buttons: [{
          text: 'general.cancel',
          color: 'outline-primary',
          closeButton: true,
        },
        {
          text: 'general.confirm',
          color: 'primary',
          closeButton: true,
          handler: (): void => {
            this.tabSelectedItem = item;
          }
        }]
      });
    }
    else {
      this.tabSelectedItem = item;
    }
  }

  protected onTabDirty(id: number, dirty: boolean): void {
    const TAB: ITabItemExtended | undefined = this.tabItems.find((element: ITabItemExtended) => element.id === id);
    if (TAB !== undefined) {
      TAB.dirty = dirty;
    }
  }
}
