import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { AV_PROD_OPTIONS_SCORE_COLOR_FORMAT, AV_PROD_OPTIONS_SCORE_SPORT } from '../../const/av-producer-options';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';


export class ProducerSettingsScoreClass {
    protected useSaveButton: boolean = false;
    protected settingsForm: FormGroup = new FormGroup([]);
    protected valuesForms: FormGroup[] = [];
    protected teamsForms: FormGroup[] = [];
    protected isBusy: boolean = false;
    protected isDirty: boolean = false;
    protected isResetNeeded: boolean = false;
  
    protected teamsCount: number = 2;
    protected tabSelectedTeam: number = 0;
  
    protected outputSettingsSubscription: Subscription = new Subscription();
  
    protected itemsSettings: IAvSettingsItemConfig[] = [
      {
        id: 'scoreSport',
        type: AvProdSettingsType.selectComboNumber,
        name: 'producerSettings.sport',
        min: 0,
        max: 0,
        step: 1,
        options: AV_PROD_OPTIONS_SCORE_SPORT,
        placeholder: '',
        visible: false
      },
      {
        id: "scoreUseSets",
        type: AvProdSettingsType.switchBoolean,
        name: "producerSettings.useSets",
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: ""
      },
      {
        id: 'scorePlayersCount',
        type: AvProdSettingsType.selectComboNumber,
        name: 'producerSettings.playersCount',
        min: 1,
        max: 10,
        step: 1,
        options: [],
        placeholder: '',
        visible: false
      }
    ]
  
    protected itemsTeam: IAvSettingsItemConfig[] = [
      {
        id: 'scoreTeamName',
        type: AvProdSettingsType.text,
        name: 'producerSettings.scoreTeamName',
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: ''
      },
      {
        id: 'scoreTeamNameShort',
        type: AvProdSettingsType.text,
        name: 'producerSettings.scoreTeamNameShort',
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: ''
      },
      {
        id: 'scoreTeamColorFormat',
        type: AvProdSettingsType.selectComboNumber,
        name: 'producerSettings.scoreTeamColorFormat',
        min: 0,
        max: 0,
        step: 1,
        options: AV_PROD_OPTIONS_SCORE_COLOR_FORMAT,
        placeholder: ''
      },
      {
        id: 'scoreTeamColor1',
        type: AvProdSettingsType.color,
        name: 'producerSettings.scoreTeamColor1',
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: ''
      },
      {
        id: 'scoreTeamColor2',
        type: AvProdSettingsType.color,
        name: 'producerSettings.scoreTeamColor2',
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: ''
      }
    ]
  
    protected itemsValue: IAvSettingsItemConfig[] = [
      {
        id: 'scoreValue',
        type: AvProdSettingsType.numberButtons,
        name: 'producerSettings.teamName',
        min: 0,
        max: 200,
        step: 1,
        options: [],
        placeholder: '',
        translate: false
      },
      {
        id: 'scoreValueSets',
        type: AvProdSettingsType.numberButtons,
        name: 'producerSettings.teamNameSets',
        min: 0,
        max: 30,
        step: 1,
        options: [],
        placeholder: '',
        translate: false
      }
    ]
  
    constructor(protected avProd: AvProducerService) {}
  
    protected init(): void {
      this.itemsSettings.forEach(element => {
        this.settingsForm.addControl(element.id, new FormControl());
      });
  
      this.readServerSettings();
      this.outputSettingsSubscription = this.avProd.onNewOutputSettings$.subscribe(settings => this.onNewServerSettings());
    }
  
    protected destroy(): void {
      if (this.outputSettingsSubscription !== undefined) this.outputSettingsSubscription.unsubscribe();
    }
  
    protected emitDirty(value: boolean){
      // must override
    }
    protected displayToast(config: IToastConfig){
      // must override
    }
  
    protected refreshTeamControls() {
      if (this.teamsCount > this.teamsForms.length){
        for (let i:number = 0; i<this.teamsCount; i++) {
          if (i >= this.teamsForms.length){
            // Create FormGroup for player settings
            let formTeam: FormGroup = new FormGroup([]);
            this.itemsTeam.forEach(element => {
              formTeam.addControl(element.id, new FormControl());
            });
            this.teamsForms.push(formTeam);
          }
        }
      }
      else {
        while (this.teamsForms.length > this.teamsCount){
          this.teamsForms.pop();
        }
      }
    }
  
    protected readServerSettings() {
      if (this.isDirty === false){
  
        if (this.avProd.composerSettings.overlayScore?.sport !== undefined){
          this.settingsForm.get('scoreSport')?.setValue(this.avProd.composerSettings.overlayScore?.sport);
        }
        if (this.avProd.composerSettings.overlayScore?.sets !== 0){
          this.settingsForm.get('scoreUseSets')?.setValue(true);
        }
        else{
          this.settingsForm.get('scoreUseSets')?.setValue(false);
        }
        if (this.avProd.composerSettings.overlayScore?.teams !== undefined){
          this.teamsCount = this.avProd.composerSettings.overlayScore?.teams.length;
          this.refreshTeamControls();
          // Update form fields
          this.settingsForm.get('scorePlayersCount')?.setValue(this.teamsCount);
          for (let i:number = 0; i<this.avProd.composerSettings.overlayScore?.teams.length; i++){
            if (this.teamsForms[i] !== undefined){
              this.teamsForms[i].get('scoreTeamName')?.setValue(this.avProd.composerSettings.overlayScore?.teams[i].name);
              this.teamsForms[i].get('scoreTeamNameShort')?.setValue(this.avProd.composerSettings.overlayScore?.teams[i].nameShort);
              this.teamsForms[i].get('scoreTeamColorFormat')?.setValue(this.avProd.composerSettings.overlayScore?.teams[i].colorFormat);
              if (this.avProd.composerSettings.overlayScore?.teams[i].colors !== undefined) {
                this.teamsForms[i].get('scoreTeamColor1')?.setValue(this.avProd.composerSettings.overlayScore?.teams[i].colors![0]);
                this.teamsForms[i].get('scoreTeamColor2')?.setValue(this.avProd.composerSettings.overlayScore?.teams[i].colors![1]);
              }
            }
          }
        }
  
        this.settingsForm.markAsPristine();
        this.isResetNeeded = false;
        this.isDirty = false;
        this.emitDirty(this.isDirty);
      }
      else {
        this.isResetNeeded = true;
      }
    }
  
    protected onSettingChanged(event: any) {
      this.refreshDirtyFlags();
      if ((this.useSaveButton === false)&&(this.isDirty)){
        this.onSave();
      }
    }
  
    protected refreshDirtyFlags() {
      let dirty: boolean = this.settingsForm.dirty;
      for (let i:number=0; i<this.teamsForms.length; i++){
        if (this.teamsForms[i].dirty === true){
          dirty = true;
        }
      }
      this.isDirty = dirty;
      if (this.isDirty === true) {
        this.isResetNeeded = true;
      }
      this.emitDirty(this.isDirty);
    }
  
    protected onNewServerSettings() {
      if (this.isDirty) {
        this.isResetNeeded = true;
      }
      else {
        this.readServerSettings();
      }
    }
  
    protected onSave() {
      let settings: IAvProdComposerSettings = {};
      this.isBusy = true;
      settings.overlayScore = {};
      settings.overlayScore.sport = this.settingsForm.get('scoreSport')?.value;
      settings.overlayScore.sets = this.settingsForm.get('scoreUseSets')?.value? 1:0;
      settings.overlayScore.teams = [];
      for (let i:number = 0; i<this.teamsCount; i++){
        settings.overlayScore.teams[i] = {};
        settings.overlayScore.teams[i].name = this.teamsForms[i].get('scoreTeamName')?.value;
        settings.overlayScore.teams[i].nameShort = this.teamsForms[i].get('scoreTeamNameShort')?.value;
        settings.overlayScore.teams[i].colorFormat = this.teamsForms[i].get('scoreTeamColorFormat')?.value;
        settings.overlayScore.teams[i].colors = [];
        settings.overlayScore.teams[i].colors?.push(this.teamsForms[i].get('scoreTeamColor1')?.value);
        settings.overlayScore.teams[i].colors?.push(this.teamsForms[i].get('scoreTeamColor2')?.value);
        this.teamsForms[i].markAsPristine();
      }
      this.avProd.azzChangeComposerSettings(settings);
  
      this.settingsForm.markAsPristine();
      this.refreshDirtyFlags();
  
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.success,
          title: 'general.done',
          text: 'producer.settingsSavedSuccess',
          alignText: 'text-center',
          buttons: []
        },
      });
  
      this.isBusy = false;
    }
  
    protected onReset() {
      this.isDirty = false;
      this.readServerSettings();
      this.emitDirty(this.isDirty);
    }
  
    protected onTabTeamClick(index: number){
      this.tabSelectedTeam = index;
    }  
}
