import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import {
  IUpdateUser, IActivateUser, ILoginUser, IForgotPassword, ISignUpUser, IUser, IUserAuthKey, IRefreshToken,
  IPushNotification, ILoginGoogleUser, ILoginAppleUser, IUserPlanConfig
} from '../../interfaces/user/user.interface';
import { IApi, IContactData, IHttpResponse } from '../../interfaces/utils/utils.interface';
import { API } from '../../const/api.const';
import { AuthService } from '../auth/auth.service';
import { CryptService } from '../crypt/crypt.service';
import { DeviceService } from '../device/device.service';
import { LanguageService } from '../language/language.service';


@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  pushSubscription: Subscription | undefined;
  onInitialized$: Observable<boolean>;

  private onInitialized: Subject<boolean> = new Subject(); // Indicates if service (user or anonymous User initiated) has been initialized
  private pInitialized: boolean = false;
  private pUser: IUser | undefined;
  private pAnonymousUser: boolean = true;
  private pUserActivation: string = '';

  protected apiErrorData: IContactData = {
    name: 'Team support',
    email: 'team@azzulei.com',
    subject: '',
    comments: '',
    version: '',
  };

  constructor(
    private http: HttpClient,
    private deviceService: DeviceService,
    public cryptService: CryptService,
    private languageService: LanguageService,
    private authService: AuthService) {
    this.onInitialized$ = this.onInitialized.asObservable();
  }

  public get user(): IUser {
    return this.pUser as IUser;
  }

  public set user(user: IUser | undefined) {
    this.pUser = user;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get anonymousUser(): boolean {
    return this.pAnonymousUser as boolean;
  }

  public set anonymousUser(anonymous: boolean) {
    this.pAnonymousUser = anonymous;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get userActivation(): string {
    return this.pUserActivation;
  }

  public set userActivation(email: string) {
    this.pUserActivation = email;
  }

  public get isInitialized(): boolean {
    return this.pInitialized;
  }

  public set isInitialized(initialized: boolean) {
    this.pInitialized = initialized;
    this.onInitialized.next(this.pInitialized);
  }

  /**
   * Checks if auth key response has an extra code
   *
   * @param response
   * @returns
   */
  static handleAuthKeyResponse(response: IHttpResponse): IHttpResponse | Observable<never> {
    if (!response.error) {
      if (response.code !== 0) {
        throw new HttpErrorResponse({
          error: {
            code: response.code,
            error: true,
            message: response.message,
          },
          status: 200,
          statusText: response.message,
        });
      } else {
        return response;
      }
    } else {
      return throwError(() => response.error);
    }
  }

  /**
   * Handle data from Sign up response and returns user id & auth key for encryption or throw errors if request's failed
   *
   * @param response
   * @returns {userId, authKey}
   */
  static handleSignUpResponse(response: IHttpResponse): Observable<{ id: number; authKey: string }> {
    if (!response.error) {
      if (response.code === 0) {
        return of({
          id: response.data.id,
          authKey: response.data.auth_key,
        });
      } else {
        return throwError(() => response.code);
      }
    } else {
      return throwError(() => response.message);
    }
  }

  /**
   * Handle data from first pass request response and returns encrypt code or throw 600 error if it fails
   *
   * @param response
   */
  static handleFirstPasswordResponse(response: IHttpResponse): number {
    if (!response.error) {
      return response.code;
    } else {
      return 600;
    }
  }

  /**
   * Handle data from Forgot password
   *
   * @param response
   */
  static handleForgotPasswordResponse(response: IHttpResponse): IHttpResponse | Observable<never> {
    if (!response.error) {
      return response;
    } else {
      return throwError(() => response.error);
    }
  }

  ngOnDestroy(): void {
    if (this.pushSubscription) {
      this.pushSubscription.unsubscribe();
    }
  }

  /**
   * If user doesn't already exist, inits user in service if access_token exist in localStorage
   * If token exists: request API user data
   *
   * @returns true if user data has been received, false otherwise
   */
  public initUser(): Observable<boolean> {
    if (this.user) {
      return of(true);
    }
    const TOKEN: string = this.authService.accessToken;
    if (TOKEN !== '') {
      return this.getUserData();
    } else {
      return of(false);
    }
  }

  /**
   * Inits anonymous user in service if access_token does not exist in localStorage
   *
   * @returns true if user data has been received, false otherwise
   */
  public initUserAnonymous(): void {
    this.pAnonymousUser = true;
    this.isInitialized = true;
  }

  /**
   * Signs Up user with signUp form data
   *
   * @param signUpUser
   */
  public signUp(signUpUser: ISignUpUser): Observable<number> {
    const DATA_SIGN_UP: ISignUpUser = this.formatDataSignUp(signUpUser);
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.signUp}`,
        DATA_SIGN_UP
      )
      .pipe(
        switchMap((response: IHttpResponse) =>
          UserService.handleSignUpResponse(response)
        ),
        map((response: { id: number; authKey: string }) => {
          signUpUser.id = response.id;
          return this.formatPasswordSignUp(signUpUser, response.authKey);
        }),
        switchMap((signUpUserPass: ISignUpUser) =>
          this.sendFirstPassword.bind(this)(signUpUserPass)
        ),
        tap(() => (this.userActivation = DATA_SIGN_UP.email)),
        catchError((e) => {
          if (e) {
            return of(e);
          }
          return of(600);
        })
      );
  }

  /**
   * Activates user´s account
   */
  public activateAccount(activateAccount: IActivateUser): Observable<boolean> {
    const DATA_ACT_ACC: IActivateUser | null = this.formatDataActivateAccount(activateAccount);
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.activateAccount}`,
        DATA_ACT_ACC
      )
      .pipe(
        map((response: IHttpResponse) => this.handleLoginResponse(response)),
        switchMap(this.getUserData.bind(this)),
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this)),
        catchError((e) => throwError(() => e))
      );
  }

  /**
   * User Login
   * Requests auth key to send password encrypted before login
   *
   * @param loginUser
   * @param versionCode
   */
  public login(loginUser: ILoginUser, versionCode: number): Observable<boolean> {
    return this.getLoginAuthKey({email: loginUser.email?.toLowerCase() ?? '',})
      .pipe(
        map((response: IHttpResponse) =>
          this.formatDataLogin(loginUser, response.data, versionCode)
        ),
        switchMap((loginData: ILoginUser) => this.sendLogin(loginData)),
        switchMap(this.getUserData.bind(this))
      // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
      //tap(this.pushNotificationRegister.bind(this))
    );
  }

  /**
   * User Login
   * Requests auth key to send password encrypted before login
   *
   * @param loginUser
   */
  public loginGoogle(loginUser: ILoginGoogleUser): Observable<boolean> {
    const LOGIN_USER_GOOGLE: ILoginGoogleUser =
      this.formatDataLoginGoogle(loginUser);

    return this.sendLogin(LOGIN_USER_GOOGLE, '?method=google')
      .pipe(
        switchMap(this.getUserData.bind(this))
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this))
        );
  }

  /**
   * Gets apple sign in response to log user in azzulei
   *
   * @param loginUser
   */
  public loginApple(loginUser: ILoginAppleUser): Observable<boolean> {
    const LOGIN_USER_APPLE: ILoginAppleUser = this.formatDataLoginApple(loginUser);

    return this.sendLogin(LOGIN_USER_APPLE, '?method=apple')
      .pipe(
        switchMap(this.getUserData.bind(this))
        // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
        //tap(this.pushNotificationRegister.bind(this))
      );
  }

  /**
   * Updates user with update form data
   * First requests update auth key to encrypt new pass & current pass
   *
   * @param updateUser
   */
  public update(updateUser: IUpdateUser): Observable<boolean> {
    if (!this.user.email) {
      return throwError(() => false);
    }

    if (updateUser.curPassword !== undefined) {
      return this.getUpdateAuthKey({ email: this.user.email }).pipe(
        map((response: IHttpResponse) =>
          this.formatDataUpdateUser(updateUser, response.data)
        ),
        switchMap((updateData: IUpdateUser) => this.sendUpdate(updateData)),
        switchMap((codeResult: number) => {
          if (codeResult === 0) {
            return this.getUserData();
          } else {
            return throwError(() => codeResult);
          }
        })
      );
    } else {
      const UPD_DATA: IUpdateUser = this.formatDataUpdateUser(updateUser);
      return this.sendUpdate(UPD_DATA).pipe(
        switchMap((codeResult: number) => {
          if (codeResult === 0) {
            return this.getUserData();
          } else {
            return throwError(() => codeResult);
          }
        })
      );
    }
  }

  public updateLanguage(lang: string): void {
    if (this.user !== undefined && this.user.language !== lang) {
      const UPDATE_USER: IUpdateUser = { language: lang };
      this.update(UPDATE_USER).subscribe();
    }
  }

  /**
   * Gets a new access token from server if access token has expired & refresh token is set
   */
  public renewToken(versionCode: number): Observable<boolean> {
    if (this.authService.refreshToken) {
      const BODY = this.formatDataRenewToken(versionCode);
      return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.renewToken}`, BODY)
        .pipe(
          filter((response: IHttpResponse) => !!response),
          switchMap((response: IHttpResponse) =>
            this.handleLoginResponse(response)
          ),
          catchError(() => of(false))
        );
    } else {
      return of(false);
    }
  }

  /**
   * Requests user's logout to server and unauthorized current users in its session
   */
  public logout(): Subscription {
    return this.http
      .get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.logout}`)
      .pipe(
        tap(() => {
          /*if(result.data) {*/
          this.anonymousUser = true;
          this.pUser = undefined;
          // Todo: Susana Mirar si el servicio de push debería de llamarse solo desde la app mobile
          //this.pushService.removeData();
          //this.removeSectionPermissions();
          if (this.pushSubscription) {
            this.pushSubscription.unsubscribe();
          }
          /* }*/
        }),
        switchMap(() => from(this.authService.authDisallowed())),
        catchError((err) => {
          //TODO: Susana revisar los posibles mensajes de error que puede devolver la api para que no haya problemas con el log out
          console.error(err);
          this.anonymousUser = true;
          this.pUser = undefined;
          this.authService.authDisallowed().catch(console.error);
          if (this.pushSubscription) {
            this.pushSubscription.unsubscribe();
          }
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  /**
   * Requests user's deletion account to server
   */
  public deleteAccount(): Observable<boolean> {
    this.anonymousUser = true;
    return this.http
      .delete(`${API.urls.server}/${API.urls.actions.users.deleteAccount}`, {
        body: { email: this.user.email },
      })
      .pipe(switchMap(() => from(this.authService.authDisallowed())));
  }

  /**
   * Requests to send new activation code from server to user
   *
   * @param activateUser
   */
  public sendActivationCodeFromLogin(activateUser: IActivateUser): void {
    this.userActivation = activateUser.email;
    activateUser.device = this.deviceService.formatDeviceOutput();
    this.sendActivation(activateUser).subscribe();
  }

  /**
   * Sends a new email with activation code
   *
   * @param activateAccount
   */
  public sendActivation(activateAccount: IActivateUser): Observable<boolean> {
    const DATA_ACT_ACC: IActivateUser | null = this.formatDataActivateAccount(activateAccount);
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.sendActivationCode}`,
        DATA_ACT_ACC
      )
      .pipe(
        map((response: IHttpResponse) => !response.error),
        catchError(() => of(false))
      );
  }

  /**
   * Sends an email with password restoration code
   *
   * @param resetPassword
   */
  public sendForgotPassword(resetPassword: IForgotPassword): Observable<any> {
    const DATA_ACT_ACC: IForgotPassword = this.formatForgotPassword(resetPassword);
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.sendForgotPasswordCode}`,
        DATA_ACT_ACC
      )
      .pipe(
        map((response: IHttpResponse) =>
          UserService.handleForgotPasswordResponse(response)
        )
      );
  }

  /**
   * Checks restoration code
   *
   * @param resetPassword
   */
  public checkForgotPasswordCode(resetPassword: IForgotPassword): Observable<any> {
    const DATA_ACT_ACC = this.formatForgotPassword(resetPassword);
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.checkForgotPasswordCode}`,
        DATA_ACT_ACC
      )
      .pipe(
        map((response: IHttpResponse) =>
          UserService.handleForgotPasswordResponse(response)
        )
      );
  }

  /**
   * Resets password
   *
   * @param resetPassword
   */
  public resetPassword(resetPassword: IForgotPassword): Observable<any> {
    const DATA_ACT_ACC = this.formatForgotPassword(resetPassword);
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.resetPassword}`,
        DATA_ACT_ACC
      )
      .pipe(
        map((response: IHttpResponse) =>
          UserService.handleForgotPasswordResponse(response)
        )
      );
  }

  /**
   * Checks if current user has active events
   *
   * @param userId
   */
  public hasActiveEvents(userId: number): Observable<boolean> {
    return this.http
      .get<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.hasActiveEvents}/${userId}`
      )
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) => response.data),
        catchError(() => of(false))
      );
  }

  public resetUser(): void {
    this.user = undefined;
    this.anonymousUser = true;

  }

  /**
   * Changes the push notifications data associated with the user
   *
   * @param pushData
   */
  public changePush(pushData: IPushNotification): void {
    this.user.push = pushData;
    this.update({ push: this.user.push }).subscribe();
  }

  /**
   * Sends signup password to server after auth key is received
   *
   * @param signUpUser
   * @private
   */
  private sendFirstPassword(signUpUser: ISignUpUser): Observable<number> {
    return this.http
      .put<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.users.setFirstPassword}`,
        signUpUser
      )
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) =>
          UserService.handleFirstPasswordResponse(response)
        ),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Sends login data to server after auth key is received
   *
   * @param loginData
   * @param method
   * @private
   */
  private sendLogin(loginData: ILoginUser | ILoginGoogleUser | ILoginAppleUser, method: string = ''): Observable<any> {
    return this.http
      .post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.login}${method}`, loginData)
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) => this.handleLoginResponse(response)),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Sends current user's updated data after update auth key is received
   *
   * @param userData
   * @returns
   */
  private sendUpdate(userData: IUpdateUser): Observable<number> {
    return this.http
      .put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.update}`, userData)
      .pipe(
        map((response: IHttpResponse) =>
          this.handleUpdateResponse(response, userData)
        ),
        catchError(() => of(600))
      );
  }

  /**
   * Gets user data if token is set
   *
   * @returns true if user data has been retrieved, false otherwise
   */
  public getUserData(): Observable<boolean> {
    //console.log('[UserService] getUserData');
    return this.http
      .get<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.getData}`)
      .pipe(
        filter((response: IHttpResponse) => !response.error),
        map((response: IHttpResponse) => this.handleUserDataResponse(response)),
        map((user: IUser | undefined) => {
          //console.log('[UserService] getUserData: Receive user data ' + JSON.stringify(user));
          this.isInitialized = true;
          if (user) {
            this.anonymousUser = false;
            this.user = user;
            if (this.user.language !== undefined) {
              this.languageService.changeLanguage(this.user.language)
                .catch(console.error);
            }
            // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
            //this.pushService.initialize(this.user.id)
            //.catch(console.error);
            return true;
          } else {
            this.anonymousUser = true;
            return false;
          }
        }),
        catchError(() => of(false))
      );
  }

  /**
   * Gets auth key to encrypt password
   *
   * @param data
   * @returns
   */
  private getLoginAuthKey(data: IUserAuthKey): Observable<any> {
    return this.http
      .post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.getLoginAuthKey}`, data)
      .pipe(
        filter((response: IHttpResponse) => !!response),
        map((response: IHttpResponse) =>
          UserService.handleAuthKeyResponse(response)
        )
      );
  }

  /**
   * Gets auth key to encrypt password
   *
   * @param data
   * @returns
   */
  private getUpdateAuthKey(data: IUserAuthKey): Observable<any> {
    return this.http
      .post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.getUpdateAuthKey}`, data)
      .pipe(
        filter((response: IHttpResponse) => !!response),
        map((response: IHttpResponse) =>
          UserService.handleAuthKeyResponse(response)
        )
      );
  }

  /**
   * Sends push notifications data to update
   *
   * @param data
   * @private
   */
  /* private setPushData(data: IPushNotification): Observable<boolean | IHttpResponse> {
    const PUSH_DATA: IApi<IPushNotification> = this.formatPushData(data);
    return this.http.put<IHttpResponse>(`${API.urls.server}/${API.urls.actions.users.setPushData}`, {push:  PUSH_DATA})
      .pipe(
        filter((response: IHttpResponse) => !!response),
        catchError(() => of(false))
      );
  }*/

  /**
   * Formats a LoginData API response into user interface
   *
   * @param response
   */
  private handleLoginResponse(response: IHttpResponse): Observable<boolean> {
    if (!response.error) {
      this.anonymousUser = false;
      return from(
        this.authService.authAllowed(
          response.data.access_token,
          response.data.refresh_token
        )
      );
    } else {
      return of(false);
    }
  }

  /**
   * Formats a datUserData API response into user interface
   *
   * @param response
   */
  private handleUserDataResponse(response: IHttpResponse): IUser | undefined {
    if (!response.error) {
      const USER: IUser = this.formatUserInput(response.data);
      return USER ?? undefined;
    } else {
      return undefined;
    }
  }

  /**
   * Validates Update response
   *
   * @param response
   * @param newData
   */
  private handleUpdateResponse(response: IHttpResponse, newData: IUpdateUser): number {
    if (!response.error) {
      if (response.data) {
        if (response.code === 0) {
          if (newData.name !== '' || newData.name !== undefined) {
            this.user.name = newData.name;
          }
          if (newData.language !== undefined && newData.language !== '') {
            this.user.language = newData.language;
          }
        }
      }
      return response.code;
    } else {
      return 600;
    }
  }

  /**
   * Formats SignUp data
   *
   * @param user
   * @private
   */
  private formatDataSignUp(user: ISignUpUser): ISignUpUser {
    return {
      id: 0,
      name: user.name,
      email: user.email,
      password: '',
      language: this.languageService.getPriorityLanguage().iso,
    };
  }

  /**
   * Formats Password SignUp data
   *
   * @param user
   * @param authKey
   * @private
   */
  private formatPasswordSignUp(user: ISignUpUser, authKey: string): ISignUpUser {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      password: this.cryptService.encrypt(user.password, authKey),
      language: this.languageService.getPriorityLanguage().iso,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @param authKey
   * @param versionCode
   * @private
   */
  private formatDataLogin(user: ILoginUser, authKey: string, versionCode: number): ILoginUser {
    return {
      email: user.email?.toLowerCase(),
      password: user.password
        ? this.cryptService.encrypt(user.password, authKey)
        : '',
      device: this.deviceService.formatDeviceOutput(),
      version: versionCode,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @private
   */
  private formatDataLoginGoogle(user: ILoginGoogleUser): ILoginGoogleUser {
    return {
      clientId: user.clientId,
      credential: user.credential,
      device: this.deviceService.formatDeviceOutput(),
      version: user.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats Login data
   *
   * @param user
   * @private
   */
  private formatDataLoginApple(user: ILoginAppleUser): ILoginAppleUser {
    user.language = this.languageService.deviceLang.preferred.iso;
    return {
      identityToken: user.identityToken,
      authorizationCode: user.authorizationCode,
      name: user.name,
      language: user.language,
      user: user.user,
      device: this.deviceService.formatDeviceOutput(),
      version: user.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats user's update data and encrypt password if necessary
   *
   * @param user
   * @param authKey
   * @private
   */
  private formatDataUpdateUser(user: IUpdateUser, authKey?: string): IUpdateUser {
    if (!user.name) {
      user.name = '';
    }

    if (!user.curPassword) {
      user.curPassword = '';
    }

    if (!user.newPassword) {
      user.newPassword = '';
    }

    if (!user.push) {
      user.push = undefined;
    }

    return {
      email: this.user.email,
      name: user.name,
      curPassword:
        authKey && user.curPassword !== ''
          ? this.cryptService.encrypt(user.curPassword, authKey)
          : '',
      newPassword:
        authKey && user.newPassword !== ''
          ? this.cryptService.encrypt(user.newPassword, authKey)
          : '',
      language: user.language,
      push: user.push,
    };
  }

  /**
   * Formats Renew token data
   *
   * @private
   */
  private formatDataRenewToken(versionCode: number): IRefreshToken {
    return {
      email: this.authService.parseJwt().user,
      device: this.deviceService.formatDeviceOutput(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: this.authService.refreshToken,
      version: versionCode,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats activate account data
   *
   * @param activateAccount
   * @private
   */
  private formatDataActivateAccount(activateAccount: IActivateUser): IActivateUser | null {
    if (!this.userActivation) {
      return null;
    }
    return {
      email: this.userActivation,
      device: this.deviceService.formatDeviceOutput(),
      code: activateAccount.code,
      version: activateAccount.version,
      push: this.user?.push ?? undefined,
    };
  }

  /**
   * Formats forgot password data
   *
   * @param forgotPassword
   * @private
   */
  private formatForgotPassword(forgotPassword: IForgotPassword): IForgotPassword {
    return {
      email: forgotPassword.email ? forgotPassword.email : '',
      code: forgotPassword.code ?? undefined,
      password: forgotPassword.password
        ? this.cryptService.encrypt(
            forgotPassword.password,
            forgotPassword.authKey
          )
        : '',
    };
  }

  /**
   * Formats forgot password data
   *
   * @private
   * @param pushData
   */
  /* private formatPushData(pushData: IPushNotification): IApi<IPushNotification> {
    return {
        id: pushData.id ?? '',
        timestamp: pushData.timestamp ?? 0,
        error: pushData.error ?? '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        error_timestamp: pushData.errorTimestamp ?? 0,
        allowed: true,
    };
  }*/

  private formatUserInput(user: IApi<IUser>): IUser {
    return {
      id: user.id,
      name: user.name,
      phone: user.phone,
      email: user.email,
      password: user.password,
      device: user.device,
      language: user.language,
      image: user.image,
      lastLogin: user.last_login,
      code: user.code,
      push: user.push,
      productKey: user.product_key,
      storageUsed: user.storage_used,
      storageTotal: user.storage_total,
      plan: this.formatUserPlanConfigInput(user.plan)
    };
  }

  private formatUserPlanConfigInput(userPlan: IApi<IUserPlanConfig> | undefined): IUserPlanConfig | undefined {
    if (userPlan !== undefined){
      return {
        channel: userPlan.channel,
        concurrentEvents: userPlan.concurrent_events,
        customInputs: userPlan.custom_inputs,
        customRtmp: userPlan.custom_rtmp,
        customSrt: userPlan.custom_srt,
        dvrTime: userPlan.dvr_time,
        extraAudioInputs: userPlan.extra_audio_inputs,
        maxDestinations: userPlan.max_destinations,
        maxDuration: userPlan.max_duration,
        maxHighlights: userPlan.max_highlights,
        maxHtmlGraphics: userPlan.max_html_graphics,
        maxLiveInputs: userPlan.max_live_inputs,
        maxProducers: userPlan.max_producers,
        maxResolution: userPlan.max_resolution,
        maxSetup: userPlan.max_setup,
        maxViewers: userPlan.max_viewers,
        storage: userPlan.storage,
        streamingTime: userPlan.streaming_time,
        watermark: userPlan.watermark
      };
    }
    else{
      return undefined;
    }
  }

  // Todo: Susana mirar si el servicio de push debería utilizarse solo desde la app mobile
  /* private pushNotificationRegister() {
    if(!this.pushSubscription) {
      this.pushSubscription = this.pushService.pushData
        .pipe(
          filter(val => val !== undefined && val !== null),
          first(),
          switchMap((data: IPushNotification) => this.setPushData(data))
        )
        .subscribe();
    }
  }*/
}
