import { Subscription } from 'rxjs';

import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAvProdOutputSettings, IAvProdOutputSettingsDestination } from '../../interfaces/av-producer/output-settings.interface';
import { AV_PROD_OPTIONS_OUTPUT_AUDIO_BIT_RATE, AV_PROD_OPTIONS_OUTPUT_AUDIO_SAMPLE_RATE, AV_PROD_OPTIONS_OUTPUT_VIDEO_BIT_RATE } from '../../const/av-producer-options';


export class ProducerSettingsOutputClass {
    protected useSaveButton: boolean = false;
    protected settingsForm: FormGroup = new FormGroup([]);
    protected isBusy: boolean = false;
    protected isDirty: boolean = false;
    protected isResetNeeded: boolean = false;
    protected tabSelected: number = 1;
  
    protected outputSettingsSubscription: Subscription = new Subscription();
  
    protected itemsSettings: IAvSettingsItemConfig[] = [
      {
        id: 'videoBitRate',
        type: AvProdSettingsType.selectComboNumber,
        name: 'producerSettings.videoBitRate',
        min: 0,
        max: 0,
        step: 1,
        options: AV_PROD_OPTIONS_OUTPUT_VIDEO_BIT_RATE,
        placeholder: ''
      },
      {
        id: 'audioBitRate',
        type: AvProdSettingsType.selectComboNumber,
        name: 'producerSettings.audioBitRate',
        min: 0,
        max: 0,
        step: 1,
        options: AV_PROD_OPTIONS_OUTPUT_AUDIO_BIT_RATE,
        placeholder: ''
      },
      {
        id: 'audioSampleRate',
        type: AvProdSettingsType.selectComboNumber,
        name: 'producerSettings.audioSampleRate',
        min: 0,
        max: 0,
        step: 1,
        options: AV_PROD_OPTIONS_OUTPUT_AUDIO_SAMPLE_RATE,
        placeholder: ''
      }
    ]
  
    constructor(protected avProd: AvProducerService) { }
  
    protected init(): void {
      this.itemsSettings.forEach(element => {
        this.settingsForm.addControl(element.id, new FormControl());
      });
  
      this.readServerSettings();
      this.outputSettingsSubscription = this.avProd.onNewOutputSettings$.subscribe(settings => this.onNewServerSettings());
    }
  
    protected destroy(): void {
      if (this.outputSettingsSubscription !== undefined) this.outputSettingsSubscription.unsubscribe();
    }
  
    protected emitDirty(value: boolean){
      // must override
    }
    protected displayToast(config: IToastConfig){
      // must override
    }
  
    protected readServerSettings() {
      if (this.isDirty === false){
        // Global settings
        //this.settingsForm.get('onAir')?.setValue(this.avProd.outputSettings.onAir);
        //this.settingsForm.get('videoResolution')?.setValue(this.avProd.outputSettings.video?.resolution);
        this.settingsForm.get('videoBitRate')?.setValue(this.avProd.outputSettings.video?.bitRate);
        this.settingsForm.get('audioBitRate')?.setValue(this.avProd.outputSettings.audio?.bitRate);
        this.settingsForm.get('audioSampleRate')?.setValue(this.avProd.outputSettings.audio?.sampleRate);
        if (this.avProd.outputSettings.onAir !== undefined) {
          if (this.avProd.outputSettings.onAir === 1) {
            this.settingsForm.get('videoBitRate')?.disable();
            this.settingsForm.get('audioBitRate')?.disable();
            this.settingsForm.get('audioSampleRate')?.disable();
          }
          else {
            this.settingsForm.get('videoBitRate')?.enable();
            this.settingsForm.get('audioBitRate')?.enable();
            this.settingsForm.get('audioSampleRate')?.enable();
          }
        }
  
        this.settingsForm.markAsPristine();
        this.isResetNeeded = false;
        this.isDirty = false;
        this.emitDirty(this.isDirty);
      }
      else {
        this.isResetNeeded = true;
      }
    }
  
    protected onSettingChanged(event: any) {
      this.refreshDirtyFlags();
      if ((this.useSaveButton === false)&&(this.isDirty)){
        this.onSave();
      }
    }
  
    protected refreshDirtyFlags() {
      let dirty: boolean = this.settingsForm.dirty;
      this.isDirty = dirty;
      if (this.isDirty === true) {
        this.isResetNeeded = true;
      }
      this.emitDirty(this.isDirty);
    }
  
    protected onNewServerSettings() {
      if (this.isDirty) {
        this.isResetNeeded = true;
      }
      else {
        this.readServerSettings();
      }
    }
  
    protected onSave() {
      let settings: IAvProdOutputSettings = {};
      this.isBusy = true;
  
      // Output settings
      //settings.onAir = this.settingsForm.get('onAir')?.value;
      settings.audio = {};
      settings.audio.bitRate = this.settingsForm.get('audioBitRate')?.value;
      settings.audio.sampleRate = this.settingsForm.get('audioSampleRate')?.value;
      settings.video = {};
      settings.video.bitRate = this.settingsForm.get('videoBitRate')?.value;
      //settings.video.resolution = this.settingsForm.get('videoResolution')?.value;
      this.settingsForm.markAsPristine();
  
      this.refreshDirtyFlags();
  
      this.avProd.azzChangeOutputSettings(1, settings);
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.success,
          title: 'general.done',
          text: 'producer.settingsSavedSuccess',
          alignText: 'text-center',
          buttons: []
        },
      });
  
      this.isBusy = false;
    }
  
    protected onReset() {
      this.isDirty = false;
      this.readServerSettings();
      this.emitDirty(this.isDirty);
    }
  
    protected onChangeDestinations(dests: IAvProdOutputSettingsDestination[]){
      let settings: IAvProdOutputSettings = {};
      this.isBusy = true;
  
      // Output destination settings
      settings.destinations = dests;
      this.avProd.azzChangeOutputSettings(1, settings);
      // this.displayToast({
      //   options: {
      //     autohide: true,
      //     placement: ToastPlacement.bottomRight
      //   },
      //   data: {
      //     closeButtonHeader: true,
      //     status: ToastStatus.success,
      //     title: 'general.done',
      //     text: 'producer.settingsSavedSuccess',
      //     alignText: 'text-center',
      //     buttons: []
      //   },
      // });
      this.isBusy = false;
    }
}
