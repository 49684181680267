
import { Subscription, timer } from 'rxjs';

import { IMediaDevice, IVideoResolution, PublishStreamService } from '@azz-life-streamer/shared';


export class BroadcasterVideoSelectClass {

  protected nameTimerSubscription: Subscription | undefined;
  protected refreshTimerSubscription: Subscription | undefined;

  protected _device: IMediaDevice | undefined;
  protected _showCameraName: boolean = false;
  private _enabled: boolean = true;
  private _resolution: IVideoResolution | undefined;
  private _mediaStream: MediaStream | undefined;
  private _initialized: boolean = false;
  private _classClosed: boolean = false;
  private _zoom: number = 1;

  private _videoElement: HTMLVideoElement | undefined;

  constructor(protected publishService: PublishStreamService) { }

  protected setEnabled(enable: boolean): void {
    if (enable !== this._enabled) {
      this._enabled = enable;
      if (this._initialized && (this._device !== undefined) && (this._resolution !== undefined)) {
        this.refreshVideoMedia();
      }
    }
  }

  protected setDevice(dev: IMediaDevice | undefined): void {
    this._device = dev;
    this.nameTimerSubscription?.unsubscribe();
    this._showCameraName = true;
    this.nameTimerSubscription = timer(5000).subscribe(() => this._showCameraName = false);
    if (this._initialized && (this._device !== undefined) && (this._resolution !== undefined)) {
      this.refreshVideoMedia();
    }
  }

  protected setResolution(res: IVideoResolution): void {
    this._resolution = res;
    if (this._initialized && (this._device !== undefined) && (this._resolution !== undefined)) {
      this.refreshVideoMedia();
    }
  }

  protected setZoom(zoom: number): void {
    this._zoom = zoom;
    if (this._initialized && (this._device !== undefined) && (this._resolution !== undefined)) {
      this.refreshVideoMedia();
    }
  }

  protected init(video: HTMLVideoElement | undefined): void {
    this._classClosed = false;
    if (video !== undefined) {
      this._videoElement = video;
      this.refreshTimerSubscription?.unsubscribe();
      this.refreshTimerSubscription = timer(1000).subscribe(() => this.refreshVideoMedia());
      this._initialized = true;
    }
  }

  protected destroy(): void {
    this.closeMediaVideo();
    this._classClosed = true;
    this.nameTimerSubscription?.unsubscribe();
    this.refreshTimerSubscription?.unsubscribe();
  }

  protected toggleCamera(): void {
    if (this._device?.deviceId) {
      const CUR_VIDEO_DEVICES: IMediaDevice[] = this.publishService.mediaInputDevices.getValue().videoInputs;
      const CUR_INDEX: number = CUR_VIDEO_DEVICES.map((videoInput: IMediaDevice) => videoInput.deviceId).indexOf(this._device?.deviceId);
      const NEXT_INDEX: number = (CUR_INDEX + 1) % CUR_VIDEO_DEVICES.length;
      const NEW_DEVICE: IMediaDevice = CUR_VIDEO_DEVICES[NEXT_INDEX];
      //this.setDevice(NEW_DEVICE);
      this.emitCameraToggled(NEW_DEVICE);
    }
  }

  protected emitCameraToggled(device: IMediaDevice): void {
    // must be overridden
  }

  private async openMediaVideo(): Promise<boolean> {
    let ret: boolean = false;
    let tries: number = 6;
    if (this._enabled &&
      (this._device !== undefined)&&
      (this._device !== null) &&
      (this._resolution !== undefined) &&
      (this._videoElement !== undefined)) {

      this.closeMediaVideo();

      while (tries > 0) {
        try {
          console.log('[BroadcastVideoSelectClass] : openMediaVideo device:' + this._device);
          console.log('[BroadcastVideoSelectClass] : openMediaVideo ' + this._device.deviceId + '  Try ' + tries);
          tries--;
          const CONSTRAINTS: any = {
            audio: false,
            video: {
              deviceId: { exact: this._device.deviceId },
              width: { exact: this._resolution.width },
              height: { exact: this._resolution.height },
              frameRate: { ideal: 25 },
              zoom: this._zoom
            }
          }
          let tmpMediaStream: MediaStream | undefined = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
          if (this._mediaStream !== undefined) {
            this.closeMediaVideo();
          }
          this._mediaStream = tmpMediaStream;
          tries = 0;
        } catch (error) {
          this.closeMediaVideo();
          console.log('[BroadcastVideoSelectClass] : openMediaVideo Error 1 ' + error);
        }
      }

      //this.closeMediaVideo();
      try {
        if (this._mediaStream === undefined) {
          const CONSTRAINTS: any = {
            audio: false,
            video: {
              deviceId: { exact: this._device.deviceId },
              width: { ideal: this._resolution.width },
              height: { ideal: this._resolution.height },
              frameRate: { ideal: 25 },
              zoom: this._zoom
            }
          }
          console.log('[BroadcastVideoSelectClass] : openMediaVideo ' + JSON.stringify(CONSTRAINTS));
          let tmpMediaStream: MediaStream | undefined = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
          if (this._mediaStream !== undefined) {
            this.closeMediaVideo();
          }
          this._mediaStream = tmpMediaStream;
        }

        if (this._mediaStream != undefined) {
          this._videoElement.srcObject = this._mediaStream;
          const TRACKS: MediaStreamTrack[] = this._mediaStream.getTracks();
          if ((TRACKS.length > 0) && (TRACKS[0].kind === 'video')) {
            const TMP_SETTINGS: MediaTrackSettings = TRACKS[0].getSettings();
            console.log('[BroadcastVideoSelectClass] : openMediaVideo Settings ' + JSON.stringify(TMP_SETTINGS));
            this.publishService.setDeviceVideoCapabilities(this._device.deviceId, TRACKS[0].getCapabilities());
          }
          ret = true;
        }
      } catch (error) {
        console.log('[BroadcastVideoSelectClass] : openMediaVideo Error ' + error);
      }
    }

    if (this._classClosed) {
      this.closeMediaVideo();
    }
    return ret;
  }

  private closeMediaVideo(): void {
    if (this._mediaStream !== undefined) {
      try {
        let list: MediaStreamTrack[] = this._mediaStream.getTracks();
        for (let i: number = 0; i<list.length; i++) {
          list[i].stop();
          this._mediaStream.removeTrack(list[i]);
        }
      } catch (e) {
        console.log('[BroadcastVideoSelectClass] : closeMedia Error ' + e);
      }
      this._mediaStream = undefined;
      console.log('[BroadcastVideoSelectClass] : closeMedia');
    }
  }

  private refreshVideoMedia(): void {
    //console.log('[BroadcastVideoSelectClass] : refreshVideoMedia opened: ',this.pEnabled, this.pDevice, this.pResolution);
    if (this._enabled && (this._device !== undefined) &&
      (this._resolution !== undefined)) {
      this.openMediaVideo()
        .catch(console.error);
       /* .then((ret) => {
          console.log('[BroadcastVideoSelectClass] : refreshVideoMedia openMediaVideo result ' + ret);
        });*/
    } else {
      this.closeMediaVideo();
    }
  }

}
