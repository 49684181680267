import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { PasswordClass } from './password.class';
import { IActivateUser, ISignUpUser } from '../../interfaces/user/user.interface';
import { UserService } from '../../services/user/user.service';


export class SignUpClass extends PasswordClass {

  protected sendButtonDisabled: boolean = false;
  protected resendButtonDisabled: boolean = false;
  protected step: string = 'signUpForm';
  protected error: boolean = false;
  protected errorTitle: string = '';
  protected errorMessage: string = '';
  protected activationInfo: boolean = false;

  protected signUpUser: ISignUpUser = {
    name: '',
    email: '',
    password: '',
    legalTerms: false
  };

  protected activateUser: IActivateUser = {
    code: undefined,
    email: '',
  };

  constructor(protected userService: UserService ) {
    super(1);
  }

  protected signUp(fSignUp: NgForm): void {
    this.error = false;
    this.errorMessage = '';
    if (fSignUp.valid) {
      this.sendButtonDisabled = true;
      this.userService.signUp(this.signUpUser)
        .subscribe({
          next: this.handleSignUpResponse.bind(this),
          error: this.handleErrorResponse.bind(this)
        });
    }
  }

  protected activateAccount(fCode: NgForm): void {
    this.error = false;
    this.errorMessage = '';
    if (fCode.valid) {
      this.sendButtonDisabled = true;
      this.userService.activateAccount(this.activateUser)
        .subscribe({
          next: this.handleSendCodeResponse.bind(this),
          error: this.handleErrorResponse.bind(this),
        });
    }
  }

  protected sendActivationCode(): void {
    this.resendButtonDisabled = true;
    this.error = false;
    this.errorMessage = '';
    this.activationInfo = false;
    this.userService.sendActivation(this.activateUser)
      .subscribe({
        next: this.handleSendActivationResponse.bind(this),
        error: this.handleErrorResponse.bind(this)
      });
  }

  protected changeState(event: any): void {
    this.signUpUser.legalTerms = event.target.checked;
  }

  protected modalClose(): void {
    // must be overridden
  }

  protected resetVars(): void {
    this.sendButtonDisabled = true;
    this.resendButtonDisabled = true;
    this.activationInfo = false;
    this.error = false;
    this.errorTitle = '';
    this.errorMessage = '';
    this.step = 'signUpForm';
    this.signUpUser = {
      name: '',
      email: '',
      password: '',
      legalTerms: false
    };
    this.activateUser = {
      code: undefined,
      email: '',
    };
  }

  protected showErrorMessage(): void {
    // must be overridden
  }

  private handleSignUpResponse(response: number): void {
    this.sendButtonDisabled = false;
    if (response === 0) {
      this.step = 'codeForm';
    } else {
      this.error = true;
      switch (response) {
        case 602:
          this.errorTitle = 'users.userExistsTitle';
          this.errorMessage = 'users.userExists';
          break;
      }
      this.showErrorMessage();
    }
  }

  private handleSendCodeResponse(response: boolean): void {
    if (response) {
      this.activationInfo = true;
      this.errorTitle = '';
      this.errorMessage = 'users.accountActivated';
      setTimeout(() => {
        this.resetVars();
        this.modalClose();
      }, 3000);
    } else {
      this.error = true;
      this.errorTitle = 'users.invalidCode';
      this.errorMessage = 'users.invalidCodeInfo';
    }
    this.showErrorMessage();
  }

  private handleErrorResponse(err: HttpErrorResponse): void {
    this.sendButtonDisabled = false;
    this.activationInfo = true;
    this.error = true;
    switch (err.error.code) {
      case 608:
        this.errorTitle = 'users.invalidCode';
        this.errorMessage = 'users.errorCodeInfo';
        break;
    }
    this.showErrorMessage();
  }

  private handleSendActivationResponse(): void {
    this.resendButtonDisabled = false;
  }
}
