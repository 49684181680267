import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Device, DeviceId } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { DeviceType } from '../../enums/common.enum';
import { IDevice } from '../../interfaces/device/device.interface';
import { IApi, IHttpResponse } from '../../interfaces/utils/utils.interface';
import { API } from '../../const/api.const';
import { COMMON } from '../../const/common.const';


@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private _device: IDevice | undefined;
  private _source: string | undefined;

  constructor(private platform: Platform, private http: HttpClient) {
    this.setDevice();
  }

  public get device(): IDevice | undefined {
    return this._device;
  }

  private set device(device: IDevice | undefined) {
    this._device = device;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get source(): string | undefined {
    return this._source;
  }

  private set source(source: string | undefined) {
    this._source = source;
  }

  /**
   * Maps API device params into app formar (cameCase)
   *
   * @param device data returned from the API
   * @returns device formatted in camelCase
   */
  static formatDeviceInput(device: IApi<IDevice>): IDevice {
    return {
      id: device.id,
      identifier: device.uuid,
      pushId: device.push_id,
      pushIdTimestamp: device.push_id_timestamp,
      pushIdError: device.push_id_error,
      pushIdErrorTimestamp: device.push_id_error_timestamp,
      name: device.name,
      platform: device.platform,
      model: device.model,
      manufacturer: device.manufacturer,
      operatingSystem: device.operating_system,
      osVersion: device.os_version,
      isVirtual: device.is_virtual,
      memoryUsed: device.memory_used,
      webviewVersion: device.webview_version,
      type: device.type
    };
  }

  /**
   * Gets device from API and returns Device formatted or undefined on error
   *
   * @param response
   * @returns Device from API formatted or undefined if error is received
   */
  static handleDeviceResponse(response: IHttpResponse): IDevice | undefined {
    if (!response.error) {
      const DEVICE: IDevice = DeviceService.formatDeviceInput(response.data);
      return DEVICE ?? undefined;
    } else {
      return undefined;
    }
  }

  /**
   * Initialize device and source param for API from localstorage or from capacitor plugins
   *
   * @param appKey a: on mobile app | w: on web app
   */
  initDevice(appKey: string): Observable<boolean> {

    if(!this.device) {
      this.device = COMMON.defaultDeviceParams;
    }
    return forkJoin([
      Device.getInfo(),
      this.getCurrentDeviceType(),
    ]).pipe(
      map(async ([info, type]: any) => {
        if(this.device?.identifier === '') {
          const DEV: DeviceId = await Device.getId();
          this.device.identifier = DEV.identifier;
        }
        this.device = Object.assign(info, type, {identifier: this.device?.identifier});
        if (this.device?.manufacturer === '') {
          this.device.manufacturer = navigator.userAgent.slice(0, 15) + '...';
        }
        this.source = appKey + '#' + this.device?.identifier;
        this.updateDeviceLocalStorage();
        return true;
      }),
      map(() => true)
    );
  }

  /**
   * Sends device information to API to save or update the record
   *
   * @returns Observable with device from API formatted or undefined on error
   */
  registerDevice(): Observable<IDevice | undefined> {
    const DEVICE: IApi<IDevice> | undefined = this.formatDeviceOutput();
    return this.http
      .post<IHttpResponse>(
        `${API.urls.server}/${API.urls.actions.device.register}`,
        DEVICE
      )
      .pipe(
        filter((response: IHttpResponse) => !!response),
        map((response: IHttpResponse) =>
          DeviceService.handleDeviceResponse(response)
        ),
        tap((device: IDevice | undefined) => {
          if (device !== undefined) {
            this.device = device;
          } else {
            throw new Error('Device undefined');
          }
        }),
        catchError(() => of(this.device))
      );
  }

  /**
   * Maps app device params into API requirements (snakeCase)
   *
   * @returns device formatted into API style
   */
  public formatDeviceOutput(): IApi<IDevice> | undefined {
    /* eslint-disable @typescript-eslint/naming-convention */
    if (this.device !== undefined) {
      return {
        id: this.device.id ?? undefined,
        uuid: this.device.identifier,
        push_id: this.device.pushId ?? this.device.identifier,
        push_id_timestamp:
          this.device.pushIdTimestamp ??
          Math.floor(new Date().getTime() / 1000),
        push_id_error: this.device.pushIdError ?? undefined,
        push_id_error_timestamp: this.device.pushIdErrorTimestamp ?? undefined,
        name: this.device.name ?? '-',
        platform: this.device.platform,
        model: this.device.model ?? '-',
        manufacturer: this.device.manufacturer ?? '-',
        operating_system: this.device.operatingSystem,
        os_version: this.device.osVersion ?? '-',
        is_virtual: this.device.isVirtual ? 1 : 0,
        memory_used: this.device.memoryUsed ?? 0,
        webview_version: this.device.webviewVersion ?? '-',
        type: this.device.type
        /* eslint-enable @typescript-eslint/naming-convention */
      };
    } else {
      return undefined;
    }
  }

  /**
   * Return device type for publisher and producer according platform
   *
   * @returns string
   */
  public getCurrentDeviceType(): Promise<{ type: number }> {
    return new Promise((resolve) => {
      let type: DeviceType = DeviceType.none;

      if (this.platform.is('ipad')) {
        type = DeviceType.ipad;
      }

      if (this.platform.is('tablet')) {
        type = DeviceType.tablet;
      }

      if (this.platform.is('iphone')) {
        type = DeviceType.iphone;
      }

      if (this.platform.is('mobile')) {
        type = DeviceType.phone;
      }

      if (this.platform.is('desktop') || this.platform.is('pwa')) {
        type = DeviceType.web;
      }

      if (this.platform.is('mobileweb')) {
        type = DeviceType.webPhone;
      }

      resolve({ type });
    });
  }

  /**
   * Change pushIdError
   *
   * @param error
   */
  public changePushIdError(error: string) {
    if (this.device !== undefined) {
      this.device.pushIdError = error;
      this.device.pushIdErrorTimestamp = Math.floor(
        new Date().getTime() / 1000
      );
      this.updateDeviceLocalStorage();
      this.registerDevice().subscribe();
    }
  }

  /**
   * Gets device from local storage
   * If it exists, checks if mandatory attributes are set
   * if mandatory field are defined set device with localStorage data
   * and some data will be updated later in initDevice
   * if not, sets device to UNDEFINED to init it later
   *
   */
  private setDevice(): void {
    const DEVICE: string | null = localStorage.getItem(COMMON.storageKeys.device);
    if (DEVICE !== null) {
      const TMP_DEVICE: IDevice = JSON.parse(DEVICE);
      if (TMP_DEVICE) {
        if (this.checkDeviceObject(TMP_DEVICE)) {
          this.device = TMP_DEVICE;
        } else {
          this.device = undefined;
        }
      }
    }
  }

  /**
   * Update Device localStorage data
   *
   * @private
   */
  private updateDeviceLocalStorage(): void {
    localStorage.setItem(
      COMMON.storageKeys.device,
      JSON.stringify(this.device)
    );
  }

  /**
   * Checks if al device's mandatory fields are set
   * @param device
   * @private
   */
  private checkDeviceObject(device: IDevice): boolean {
    // @ts-ignore
    return !Object.keys(COMMON.defaultDeviceParams).some((key: string) => device[key] === undefined);
  }
}
