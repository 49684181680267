import { Subscription } from 'rxjs';

import { AvProdDeviceType, AvProdInputPlayingState, AvProdInputSourceType, AvProdInputType, AvProdInputTypeNumber, AvProducerService, IPublisherSettings, ModalDialogId, ModalManagerService, PublishStreamService } from '@azz-life-streamer/shared';


export class InputListClass {

  private broadcastSubscription: Subscription | undefined;
  private inputListSubscription: Subscription | undefined;
  private inputStatusSubscription: Subscription | undefined;
  private inputSettingsSubscription: Subscription | undefined;
  protected listInputs: number[] = [];  // List of active live input ids
  //protected broadcastName: string = '';
  //protected _broadcastSlotIds: string[] = [];
  protected _broadcastInputIds: number[] = [];

  constructor(
        protected avProd: AvProducerService,
        protected publishService: PublishStreamService,
        protected modalService: ModalManagerService){
    // do nothing
  }

  protected initialize(): void {
    this.updateInputs();
    if (this.broadcastSubscription !== undefined) this.broadcastSubscription.unsubscribe();
    if (this.inputListSubscription !== undefined) this.inputListSubscription.unsubscribe();
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.inputSettingsSubscription !== undefined) this.inputSettingsSubscription.unsubscribe();
    this.broadcastSubscription = this.publishService.slotChangeSource.subscribe(() => this.updateBroadcastSlots());
    this.inputListSubscription = this.avProd.components$.subscribe( () => this.updateInputs());
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateInputs());
    this.inputSettingsSubscription = this.avProd.onNewInputSettings$.subscribe(() => this.updateInputs());
  }

  protected destroy(): void {
    if (this.inputListSubscription !== undefined) this.inputListSubscription.unsubscribe();
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.inputSettingsSubscription !== undefined) this.inputSettingsSubscription.unsubscribe();
  }

  private updateInputs() {
    const LIST_LIVE: number[] = [];
    const LIST_CLIP: number[] = [];
    const LIST_IMG: number[] = [];
    const LIST_HL: number[] = [];
    const LIST_DOC: number[] = [];

    this.updateBroadcastSlots();

    this.avProd.inputs.forEach(element => {
      if (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream){
        if ((element.settings?.liveSourceType === AvProdInputSourceType.pull)||
            (element.settings?.liveSourceType === AvProdInputSourceType.push)){
            LIST_LIVE.push(element.info.id);
        }
        else if (element.status?.playingState === AvProdInputPlayingState.playing){
          if (this._broadcastInputIds.includes(element.info.id) === false){
            LIST_LIVE.push(element.info.id);
          }
        }
      }
      else if (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip){
        if (element.info.favorite === true){
          LIST_CLIP.push(element.info.id);
        }
      }
      else if (element.info.inputTypeNumber === AvProdInputTypeNumber.imageTile){
        if (element.info.favorite === true){
          LIST_IMG.push(element.info.id);
        }
      }
      else if (element.info.inputTypeNumber === AvProdInputTypeNumber.highlight){
        if (element.info.favorite === true){
          LIST_HL.push(element.info.id);
        }
      }
      else if (element.info.inputTypeNumber === AvProdInputTypeNumber.document){
        if (element.info.favorite === true){
          LIST_DOC.push(element.info.id);
        }
      }
    });

    this.listInputs = LIST_LIVE.concat(LIST_DOC, LIST_CLIP, LIST_IMG, LIST_HL);
    console.log('INPUT LIST CLASS - List total count: ' + this.listInputs.length);
    this.emitInputListCount(this.listInputs.length);
  }

//  protected setBroadcastSlotIds(value: string[]){
//    this._broadcastSlotIds = value;
//  }

  protected updateBroadcastSlots(){
    //let newList: string[] = [];
    let newListIds: number[] = [];
    for (let i:number=0; i<this.publishService.streamSlots.length; i++) {
      if (this.publishService.streamSlots[i].active === true) {
        //newList.push(this.publishService.streamSlots[i].id);
        if (this.publishService.streamSlots[i].streamId !== undefined) {
          newListIds.push(this.publishService.streamSlots[i].streamId - 1);
        }
      }
    }
    this._broadcastInputIds = newListIds;
    //this._broadcastSlotIds = newList;
  }

  protected onInputSelect(id: number) {
    this.emitInputSelect(id);
  }

  protected onInputAdd() {
    this.modalService.show({id: ModalDialogId.producerInputAdd, title:'inputAdd.selectInputType'}, false);
  }

  protected onDragStart(event: any, inputId: number){
    event.dataTransfer.setData('itemId', inputId);
    event.dataTransfer.setData('itemType', AvProdDeviceType.input);
  }

  protected emitInputSelect(id: number){
  }
  protected emitInputListCount(id: number){
  }
  protected emitInputAdd(value: boolean){
  }
}
