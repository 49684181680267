import { Subscription } from 'rxjs';

import { AvProdPublishSourceType } from '../../const/publish-stream';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { PublishStreamService } from '../../services/publish-stream/publish-stream.service';

export class InputAddLocalClass {

  protected slotChangeSubscription: Subscription | undefined;

  constructor(protected publishService: PublishStreamService) {
  }

  protected _freeCameraSlots: number = 0;
  protected _usedCameraSlots: number = 0;
  protected _selectedSlot: string = '';

  //protected _activeSlotExists = false;

  protected init(): void {
    this.checkSelectedSlot();
    this.receivePublishSlotChange();
    if (this.slotChangeSubscription !== undefined) this.slotChangeSubscription.unsubscribe();
    this.slotChangeSubscription = this.publishService.slotChangeSource.subscribe(() => this.receivePublishSlotChange())
  }

  protected destroy(): void {
    if (this.slotChangeSubscription !== undefined) this.slotChangeSubscription.unsubscribe();
  }

  protected emitSelectedSlot(value: string): void {
    // to be overridden
  }
  protected closeModal() {
    // Must override
  }

  protected setSelectedSlot(value: string): void {
    this._selectedSlot = value;
    this.checkSelectedSlot();
  }

  protected receivePublishSlotChange(): void {
    // Event called when there is a change in Publish service slots
    let freeSlots: number = 0;
    let usedSlots: number = 0;
    for (let i: number = 0; i < this.publishService.streamSlots.length; i++) {
      if (this.publishService.streamSlots[i].sourceType === AvProdPublishSourceType.device) {
        if (this.publishService.streamSlots[i].active) {
          usedSlots++;
        } else {
          freeSlots++;
        }
      }
    }
    this._freeCameraSlots = freeSlots;
    this._usedCameraSlots = usedSlots;
    this.checkSelectedSlot();
  }

  protected checkSelectedSlot(): void {
    let found: boolean = false;
    const INDEX: number = this.publishService.getSlotIndex(this._selectedSlot);
    if ((this.publishService.streamSlots[INDEX] !== undefined) && this.publishService.streamSlots[INDEX].active) {
      found = true;
    }
    if (!found) {
      for (let i: number = 0; i < this.publishService.streamSlots.length; i++) {
        if (this.publishService.streamSlots[i].active) {
          this._selectedSlot = this.publishService.streamSlots[i].id;
          this.emitSelectedSlot(this._selectedSlot);
          //this._activeSlotExists = true;
          found = true;
          break;
        }
      }
    }
    if (!found) {
      this._selectedSlot = '';
      this.emitSelectedSlot(this._selectedSlot);
    }
    //this._activeSlotExists = found;
  }

  protected onStartBroadcast(id: string): void {
    this.publishService.applyStreamSlotSettings(id);
  }

  protected onAddMediaDevice(closeModal: boolean) {
    if (this.publishService.avServerLiveStreamsFree === 0) {
      this.displayToast({
        options: {
          placement: ToastPlacement.middleCenter,
          autohide: true
        },
        data: {
          status: ToastStatus.error,
          text: 'inputAdd.errorLiveStreamServerLimit',
          closeButtonBody: true,
        },
      });
    } else if (this._freeCameraSlots === 0) {
      this.displayToast({
        options: {
          placement: ToastPlacement.middleCenter,
          autohide: true
        },
        data: {
          status: ToastStatus.error,
          text: 'inputAdd.errorLiveStreamLocalLimit',
          closeButtonBody: true,

        },
      });
    } else {

      /* No need for an extra confirmation
      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'inputAdd.confirmAddLocalCameraInput',
        closeButton: true,
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true
        },
          {
            text: 'general.confirm',
            color: 'primary',
            closeButton: true,
            handler: (): void => {
              const NEW_SLOT: string = this.publishService.enableSlotAvailable(AvProdPublishSourceType.device);
              if (NEW_SLOT !== '') {
                this._selectedSlot = NEW_SLOT;
                if (closeModal === true){
                  this.closeModal();
                }
              }
            }
          }]
      });
      */
      const NEW_SLOT: string = this.publishService.enableSlotAvailable(AvProdPublishSourceType.device);
      if (NEW_SLOT !== '') {
        this._selectedSlot = NEW_SLOT;
        if (closeModal === true){
          this.closeModal();
        }
      }
    }
  }

  protected onRemoveMediaDevice(id: string): void {
    this.displayAlert({
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'inputAdd.confirmDeleteLocalCameraInput',
      closeButton: true,
      buttons: [{
        text: 'general.cancel',
        color: 'primary',
        fill: 'outline',
        closeButton: true
      },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            this.publishService.setSlotActive(id, false);
          }
        }]
    });
  }

  protected onAccordionSelected(id: string, i: number): void {
    this._selectedSlot = this.publishService.streamSlots[i].id;
    this.emitSelectedSlot(this._selectedSlot);
    console.log('active tab: ' + this._selectedSlot);
  }

  protected onClickSkip(): void {
    this.closeModal();
  }

  protected displayToast(config: IToastConfig): void {
    // to be overridden
  }

  protected displayAlert(data: IAlertData): void {
    // to be overridden
  }
}
