import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EventsService } from '../../services/events/events.service';
import { IEventTokenCheckResponse } from '../../interfaces/events/event.interface';
import { catchError, map } from 'rxjs/operators';

export class AuthTokenGuardSuper {
  constructor(protected eventService: EventsService) { }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    return this.eventService.checkToken(route.params['tokenParam'])
      .pipe(
        map((response: IEventTokenCheckResponse) => {
            return !!(response.tokenType && response.eventId);
        }),
        catchError(() => {
          this.redirectLogin();
          return of(false);
        })
      );
  }

  protected redirectLogin() {
    // must be overridden
  }
}
