import { Subscription, timer } from 'rxjs';
import { AlertStatus, ToastPlacement, ToastStatus } from '../enums/common.enum';
import { ModalDialogId } from '../enums/utils.enum';
import { IEventDestination, IEventUserLastChanges } from '../interfaces/events/event.interface';
import { IAlertData, IModalDialogData, IToastConfig } from '../interfaces/utils/utils.interface';
import { EventsService } from '../services/events/events.service';
import { ModalManagerService } from '../services/modal/modal-manager.service';
import { IAvProdOutputSettingsDestination } from '../interfaces/av-producer/output-settings.interface';
import { UserService } from '../services/user/user.service';
import { IAvProdServerSettings } from '../interfaces/av-producer/server-settings.interface';
import { AvProducerService } from '../services/av-producer/av-producer.service';

export class DestinationsListClass {
  protected _context: 'manager' | 'producer' = 'manager';
  protected _eventDestinations: IAvProdOutputSettingsDestination[] = [];
  protected _serverSettings: IAvProdServerSettings | undefined;

  protected isBusy: boolean = false;
  protected destinations: IEventDestination[] = [];
//  protected customRtmpName: string = '';
//  protected customRtmpUrl: string = '';
  protected destinationsReceived: boolean | undefined = undefined;
  protected allowManageDestination: boolean = false;
  protected allowMaxDestinations: number = 1;
  protected allowRtmp: number = 0;
  protected allowSrt: number = 0;

  protected userChangesSubscription: Subscription | undefined;

  protected modalDialogId = ModalDialogId;
  protected modalDataCurrent: IModalDialogData = {id: ModalDialogId.none, title: ''};

  constructor(protected events: EventsService,
              protected modalService: ModalManagerService,
              protected userService: UserService,
              protected avProd: AvProducerService){
  }

  protected init(): void {
    this.userChangesSubscription = this.events.userChanges$.subscribe(data => this.checkUserChanges(data));
    this.updateDestinationList();
    this.events.addPollingClient('DestinationsListClass');
    this.updatePermissions();
  }
  protected destroy(){
    this.userChangesSubscription?.unsubscribe();
    this.events.removePollingClient('DestinationsListClass');
  }

  protected closeModalCurrent() {
    this.modalService.closeCurrent();
  }

  protected setEventDestinations(value: IAvProdOutputSettingsDestination[] | undefined){
    if (value !== undefined){
      console.log('[DestinationsListClass] setEventDestinations ' + JSON.stringify(value));
      this._eventDestinations = value;
      this.updatePermissions();
    }
  }
  protected setContext(value: 'manager' | 'producer'){
    this._context = value;
    this.updatePermissions();
  }
  protected setServerSettings(value: IAvProdServerSettings | undefined){
    this._serverSettings = value;
    if (this._serverSettings !== undefined){
      this.allowMaxDestinations = this._serverSettings.output.maxDestinations;
      this.allowRtmp = this._serverSettings.output.customRTMP;
      this.allowSrt = this._serverSettings.output.customSRT;
    }
    else{
      this.allowMaxDestinations = 1;
      this.allowRtmp = 0;
      this.allowSrt = 0;
    }
  }

  protected emitChangeEventDestinations(value: IAvProdOutputSettingsDestination[]){
    // Must override
  }
  protected displayAlert(data: IAlertData){
    // Must override
  }
  protected displayToast(config: IToastConfig){
    // Must override
  }

  protected updateDestinationList(){
    this.destinationsReceived = false;
    if (this._context === 'manager'){
      this.events.getUserDestinationList()
      .pipe()
      .subscribe(answer => {
        if (answer !== null){
          this.destinations = answer;
          this.checkDestinationReconnectNeeded();
          this.destinationsReceived = true;
        }
        console.log('[DestinationsListClass] ' + JSON.stringify(answer));
      });
    }
    else if ((this._context === 'producer') && (this.events.currentEvent?.producerToken !== undefined)){
      this.events.getEventDestinationList(this.events.currentEvent?.producerToken)
        .pipe()
        .subscribe(answer => {
          if (answer !== null){
            this.destinations = answer;
            this.checkDestinationReconnectNeeded();
            this.destinationsReceived = true;
            if (this._context === 'producer'){
              this.saveEventDestinations();
            }
          }
          console.log('[DestinationsListClass] ' + JSON.stringify(answer));
      });
    }
  }

  protected checkUserChanges(changes: IEventUserLastChanges){
    if (changes.destinations === true){
      this.updateDestinationList();
    }
  }

  protected hideModal(): void {
    //must be overridden
  }

  protected showModal(): void {
    //must be overridden
  }
  
  protected updatePermissions() {
    if (this._context === 'manager'){
      this.allowManageDestination = true;
    }
    else if ((this.events.currentEvent?.userId !== undefined)&&
        (this.events.currentEvent.userId === this.userService.user.id)){
      console.log('[DestinationsListClass] updatePermissions Allowed');
      // D not allow destination management in Studio
      //this.allowManageDestination = true;
      this.allowManageDestination = false;
    }
    else {
      this.allowManageDestination = false;
    }
  }

  protected onModalNewData(data: IModalDialogData) {
    this.modalDataCurrent = data;
    switch (data.id) {
      case ModalDialogId.none:
        this.hideModal();
        break;
      default:
        this.showModal();
        break;
    }
  }

  protected onAdd(platform: string){
    if (this.allowManageDestination === true){
      console.log('[DestinationsListClass] onAdd: ' + platform);
      if (platform === 'rtmp'){
        if ((this.userService.user.plan?.customRtmp === undefined)||(this.userService.user.plan.customRtmp === 0)){
          this.displayToast({
            options: {
              placement: ToastPlacement.topCenter,
            },
            data: {
              status: ToastStatus.error,
              text: 'destinations.errorCustomRtmpNone',
              closeButtonBody: true,
              alignText: 'text-center'
            },
          });
        }
        else{
          this.modalService.show({id: ModalDialogId.destinationCreate, info: {platform: platform}, title: 'destinations.addNewCustomRtmp'}, false);
        }
      }
      else if (platform === 'srt'){
          if ((this.userService.user.plan?.customSrt === undefined)||(this.userService.user.plan.customSrt === 0)){
            this.displayToast({
              options: {
                placement: ToastPlacement.topCenter,
              },
              data: {
                status: ToastStatus.error,
                text: 'destinations.errorCustomSrtNone',
                closeButtonBody: true,
                alignText: 'text-center',
              },
            });
          }
          else{
            this.modalService.show({id: ModalDialogId.destinationCreate, info: {platform: platform}, title: 'destinations.addNewCustomSrt'}, false);
          }
      }
      else{
        this.isBusy = true;
        try {
          this.events.createUserDestination(platform, undefined, true, undefined)
            .pipe()
            .subscribe(answer => {
              console.log('[DestinationsListClass] Add: ' + JSON.stringify(answer));
              if (answer.status_code === 200){
                if ((platform === 'twitch')||
                    (platform === 'youtube'))
                  if (answer.data.url !== undefined){
                    window.open(answer.data.url, '_self');
                  }
              }
              this.isBusy = false;
            });
        }
        catch(error){
          this.isBusy = false;
        }
      }
    }
    else{
      console.log('[DestinationsListClass] Add not allowed');
    }
  }

  protected onEdit(id: number){
    if (this.allowManageDestination === true){
      this.modalService.show({id: ModalDialogId.destinationEdit, subId: id, title: 'destinations.configuration'}, false);
    }
  }

  protected onClickAddNew(){
    //to be overriden
  }

  protected onReconnect(id: number){
    if (this.allowManageDestination === true){
      this.isBusy = true;
      try {
        this.events.reconnectUserDestination(id)
          .pipe()
          .subscribe(answer => {
            console.log('[DestinationsListClass] Reconnect: ' + JSON.stringify(answer));
            if (answer.status_code === 200){
              if (answer.data.url !== undefined){
                window.open(answer.data.url, '_self');
              }
            }
            this.isBusy = false;
          });
      }
      catch(error){
        this.isBusy = false;
      }
    }
  }

  protected onDelete(id: number){
    if (this.allowManageDestination === true){
      this.displayAlert({
        closeButton: true,
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'destinations.deleteConfirmation',
        buttons: [{
            text: 'general.cancel',
            color: 'primary',
            fill: 'outline',
            closeButton: true
          },
          {
            text: 'general.confirm',
            color: 'danger',
            closeButton: true,
            handler: (): void => {
              this.isBusy = true;
              this.events.deleteUserDestination(id)
                .pipe()
                .subscribe(answer => {
                  this.isBusy = false;
                  if (answer === true){
                    this.updateDestinationList();
                  }
                  else{
                    this.displayToast({
                      options: {
                        placement: ToastPlacement.topCenter,
                      },
                      data: {
                        status: ToastStatus.error,
                        text: 'destinations.errorDelete',
                        closeButtonBody: true,
                        alignText: 'text-center',
                      },
                    });      
                  }
                  timer(1000).subscribe(() => this.events.forcePollingChanges());
                });
            }
          }]
      });
    }
  }

  protected onActiveChange(dest: IEventDestination){
    if (this._context === 'manager'){
      this.isBusy = true;
      console.log('[DestinationsListClass] onActiveChange: ' + JSON.stringify(dest));
      dest.active = this.checkActiveDestinationPermission(dest);
      try {
        this.events.updateUserDestination(dest.id, undefined, dest.active, dest.settings)
          .pipe()
          .subscribe(answer => {
            console.log('[DestinationsListClass] Edit: ' + JSON.stringify(answer));
            if ((answer !== null)&&(answer.status_code === 200)){
              console.log('[DestinationSettingsClass] Edit OK');
              this.updateDestinationList();
            }
            else{
              this.displayError('destinations.errorSavingSettings');
              this.updateDestinationList();
            }
            this.isBusy = false;
          });
      }
      catch(error){
        this.displayError('destinations.errorSavingSettings');
        this.updateDestinationList();
        this.isBusy = false;
      }
    }
    else if (this._context === 'producer'){
      const DEST_EVENT: IAvProdOutputSettingsDestination | undefined = this._eventDestinations.find(element => element.id === dest.id);
      const DEST_CLOUD: IEventDestination | undefined = this.destinations.find(element => element.id === dest.id);
      if (DEST_CLOUD !== undefined){
        if (DEST_EVENT !== undefined){
          DEST_EVENT.active = !DEST_EVENT.active;
          DEST_EVENT.active = this.checkActiveDestinationPermission(DEST_EVENT);
          if (DEST_CLOUD !== undefined){
            DEST_CLOUD.active = DEST_EVENT.active;
          }
        }
        else if (DEST_CLOUD !== undefined){
          const DEST: IAvProdOutputSettingsDestination = {
            id: DEST_CLOUD.id,
            active: !DEST_CLOUD.active,
            name: DEST_CLOUD.name,
            platform: DEST_CLOUD.platform,
            avatarUrl: DEST_CLOUD.avatarUrl
          }
          DEST.active = this.checkActiveDestinationPermission(DEST);
          DEST_CLOUD.active = DEST.active;
          this._eventDestinations.push(DEST);
        }
      }
      //this.saveEventDestinations();
      this.updateDestinationList();
    }
  }

  protected checkActiveDestinationPermission(dest: IAvProdOutputSettingsDestination): boolean{
    let countDests: number = 0;
    let countRtmp: number = 0;
    let countSrt: number = 0;

    if (this._context === 'producer'){
      countDests = this._eventDestinations.filter(element=> element.active === true).length;
      countRtmp = this._eventDestinations.filter(element=> element.active === true && element.platform === 'rtmp').length;
      countSrt = this._eventDestinations.filter(element=> element.active === true && element.platform === 'srt').length;
    }
    else{
      if (this.userService.user?.plan?.maxDestinations !== undefined){
        this.allowMaxDestinations = this.userService.user?.plan?.maxDestinations;
        if (this.userService.user?.plan?.customRtmp !== undefined){
          this.allowRtmp = this.userService.user?.plan?.customRtmp;
        }
        if (this.userService.user?.plan?.customSrt !== undefined){
          this.allowSrt = this.userService.user?.plan?.customSrt;
        }
      }
      countDests = this.destinations.filter(element=> element.active === true).length;
      countRtmp = this.destinations.filter(element=> element.active === true && element.platform === 'rtmp').length;
      countSrt = this.destinations.filter(element=> element.active === true && element.platform === 'srt').length;
    }

    console.log('[DestinationsListClass] checkActiveDestinationPermission');
    if (dest.active === true){
      let errorMsg: string = '';
      if (countDests > this.allowMaxDestinations){
        dest.active = false;
        errorMsg = 'destinations.errorLimit';
      }
      else if ((dest.platform ==='rtmp')&&(this.allowRtmp === 0)){
        dest.active = false;
        errorMsg = 'destinations.errorLimitRtmp';
      }
      else if ((dest.platform ==='srt')&&(this.allowSrt === 0)){
        dest.active = false;
        errorMsg = 'destinations.errorLimitSrt';
      }
      if (errorMsg !== ''){
        this.displayToast({
          options: {
            autohide: true,
            placement: ToastPlacement.middleCenter
          },
          data: {
            closeButtonHeader: true,
            status: ToastStatus.info,
            title: 'general.information',
            text: errorMsg,
            alignText: 'text-center',
            buttons: []
          },
        });  
      }
    }
    return dest.active;
  }

  protected saveEventDestinations(){
    if (this._context === 'producer'){
      const DESTS: IAvProdOutputSettingsDestination[] = [];
      for (let i:number = 0; i<this.destinations.length; i++){
        const DEST_EVENT: IAvProdOutputSettingsDestination | undefined = this._eventDestinations.find(element => element.id === this.destinations[i].id);
        if (DEST_EVENT !== undefined){
          if (DEST_EVENT.active === undefined){
            DEST_EVENT.active = false;
          }
          DEST_EVENT.name = this.destinations[i].name;
          DEST_EVENT.platform = this.destinations[i].platform;
          DEST_EVENT.avatarUrl = this.destinations[i].avatarUrl;
          DESTS.push(DEST_EVENT);
        }
        else{
          DESTS.push({
            id: this.destinations[i].id,
            active: this.destinations[i].active,
            name: this.destinations[i].name,
            platform: this.destinations[i].platform,
            avatarUrl: this.destinations[i].avatarUrl
          })
        }
      }
      console.log('[DestinationsListClass] saveEventDestinations: ' + JSON.stringify(DESTS));
      this.emitChangeEventDestinations(DESTS);
    }
  }

  protected getEventDestinationEnabled(id: number): boolean{
    let ret: boolean = false;
    const DEST_EVENT: IAvProdOutputSettingsDestination | undefined = this._eventDestinations.find(element => element.id === id);
    const DEST_CLOUD: IEventDestination | undefined = this.destinations.find(element => element.id === id);
    if ((DEST_CLOUD !== undefined)&&(DEST_EVENT !== undefined)&&(DEST_EVENT.active !== undefined)){
      ret = DEST_EVENT.active;
    }
    else if (DEST_CLOUD !== undefined){
      ret = DEST_CLOUD.active;
    }
    return ret;
  }

  protected displayError(error: string){
    this.displayToast({
      options: {
        placement: ToastPlacement.middleCenter,
        autohide: true
      },
      data: {
        status: ToastStatus.error,
        text: error,
        closeButtonBody: true,
        alignText: 'text-center',
      },
    });
  }

  protected checkDestinationReconnectNeeded(){
    let needReconnection: boolean = false;
    for (let i:number = 0; i<this.destinations.length; i++){
      if (this.destinations[i].status === 'reconnect'){
        needReconnection = true;
        break;
      }
    }

    if (needReconnection === true){
      this.displayToast({
        options: {
          autohide: false,
          placement: ToastPlacement.middleCenter
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.info,
          title: 'general.information',
          text: 'destinations.infoReconnectionNeeded',
          alignText: 'text-center',
          buttons: []
        },
      });
    }
  }
}
