import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class CryptService {

  md5: Md5;
  secretKey = 'CDTICCCXXV*M2021';
  key: any;
  md52: Md5;
  secretIv = 'C4rl0syAb0gad0';
  iv: any;

  constructor() {
    this.md5 = new Md5();
    this.md52 = new Md5();
    const tmpKey = this.md5.appendStr(this.secretKey).end() as string;
    const tmpIv = this.md52.appendStr(this.secretIv).end() as string;

    this.key = CryptoJS.enc.Hex.parse(tmpKey);
    this.iv = CryptoJS.enc.Hex.parse(tmpIv);
  }

  encrypt(formData: string, authKey?: string) {
    let encrypted;
    const ivTmp = this.iv;
    if (authKey != null && authKey !== 'undefined') {
      const md5new = new Md5();
      const tmpNewKey = md5new.appendStr(authKey).end() as string;
      const key = CryptoJS.enc.Hex.parse(tmpNewKey);
      encrypted = CryptoJS.AES.encrypt(formData, key, {iv: ivTmp});
    }
    else{
      const keyTmp = this.key;
      encrypted = CryptoJS.AES.encrypt(formData, keyTmp, {iv: ivTmp});
    }

    encrypted = encodeURIComponent(encrypted.ciphertext.toString(CryptoJS.enc.Base64));
    return encrypted;
  }
}
