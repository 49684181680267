import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHoursTime'
})
@Injectable({
  providedIn: 'root'
})
export class FormatHoursTimePipe implements PipeTransform {

  // Format seconds to HH:mm:ss for hls js
  transform(calcHours: any, offset: number): string {
    let text = '';
    let hours;
    let minutes;
    let seconds;
    if (calcHours > 0) {
      hours = Math.floor(calcHours / 3600);
      minutes = Math.floor((calcHours - (hours * 3600)) / 60); // get minutes
      seconds = calcHours - (hours * 3600) - (minutes * 60); //  get seconds
    } else {
      minutes = Math.floor(offset / 60); //1m
      seconds = offset - (minutes * 60); //30m
    }
    if (hours && hours > 0) {
      text += hours + ':';
    }
    if (minutes < 10) {
      text += '0' + minutes;
    } else {
      text += minutes;
    }
    text += ':';
    if (seconds < 10) {
      text += '0' + seconds;
    } else {
      text += seconds;
    }
    return text;
  }

}
