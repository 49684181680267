import { Injectable } from "@angular/core";
import { GetLanguageCodeResult } from '@capacitor/device/dist/esm/definitions';
import { Device } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { IDeviceLanguage, ILanguage } from '../../interfaces/language/language.interface';
import { COMMON } from '../../const/common.const';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  /**
   * includes:
   *  - default device/browser language
   *  - preferred language by anonymous or logged-in user
   */
  deviceLang: IDeviceLanguage = {
    preferred: COMMON.defaultLanguage,
    default: COMMON.defaultLanguage,
  };

  curLanguage$: Observable<ILanguage>;
  private _curLanguage: Subject<ILanguage> = new Subject<ILanguage>();

  constructor(private translate: TranslateService) {
    translate.addLangs(LanguageService.getLanguageNames());
    this.curLanguage$ = this._curLanguage.asObservable();
  }

  /**
   * Returns an array with language name in app languages constants
   *
   * @return languagesName array
   */
  static getLanguageNames(): string[] {
    return COMMON.languages.map((lang: ILanguage) => lang.name);
  }

  /**
   * Returns device default language, if this language is not one of app languages,
   * then returns 'en' (APP defaultLanguageIso)
   *
   * @return default device language in iso format
   */
  static async getDeviceLanguage(): Promise<IDeviceLanguage> {
    const DEV_LANG_CODE: GetLanguageCodeResult = await Device.getLanguageCode(); // returns navigator Two character lang code
    const APP_LANG: ILanguage | undefined = COMMON.languages.find((curLanguage: ILanguage) => curLanguage.iso === DEV_LANG_CODE.value);
    const LANG: ILanguage = APP_LANG ?? COMMON.defaultLanguage
    return { preferred: LANG, default: LANG };
  }

  /**
   * Sets device language (default & preferred) with:
   * - previous localStorage language set
   * - or data retrieved from Capacitor device language
   *
   * @public
   */
  public async initLanguages(): Promise<void> {
    const STORED_LANG: string | null = localStorage.getItem(COMMON.storageKeys.language);
    if (STORED_LANG === null) {
      this.deviceLang = await LanguageService.getDeviceLanguage();
      this.setStorageLanguage();
    } else {
      this.deviceLang = JSON.parse(STORED_LANG);
    }
  }

  /**
   * Selects preferred language for translations. If user is logged in, uses his language, it not,
   * selects device language (default or preferred, if default has been changed)
   *
   */
  public async initTranslations(): Promise<void> {
    this.updateCurrentLanguage(this.getPriorityLanguage());
  }

  /**
   * Triggered when top bar, user panel or user account language selector changes
   * Updates current used language
   *
   * @param lang
   */
  public changeLanguage(lang: string): Promise<boolean> {
    return new Promise((resolve) => {
      const LANG: ILanguage | undefined = this.getLanguage(lang);
      if(LANG && this.deviceLang.preferred.iso !== lang) {
        this.updateCurrentLanguage(LANG);
        this.setStorageLanguage();
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Returns all languages defined in app constants
   *
   * @return languages array
   */
  public getLanguages(): ILanguage[] {
    return COMMON.languages;
  }

  /**
   * Returns complete language data from APP languages array by ISO
   *
   * @return languages array
   */
  public getLanguage(langISO: string | undefined): ILanguage | undefined {
    if (langISO !== undefined) {
      return COMMON.languages.find(language => language.iso === langISO);
    } else {
      return undefined;
    }
  }

  /**
   * Returns an ILanguage according this preference order:
   * 1. Anonymous user preferred language
   * 2. Default device language (only if it is one of Azzulei available languages)
   * 3. Default Azzulei language [COMMON.defaultLanguage]
   *
   * @private
   * @return ILanguage Object
   */
  public getPriorityLanguage(): ILanguage {
    return this.deviceLang?.preferred ?
      this.deviceLang?.preferred :
      this.deviceLang?.default?
        this.deviceLang?.default : COMMON.defaultLanguage;
  }

  /**
   * Sets device default & user preferred language in localStorage
   *
   * @private
   */
  private setStorageLanguage(): void {
    localStorage.setItem(COMMON.storageKeys.language, JSON.stringify(this.deviceLang));
  }

  /**
   * Sets new curLangUsed to informs to observers, change translate service language and sets new html body tag language
   *
   * @param lang
   * @private
   */
  private updateCurrentLanguage(lang: ILanguage): void {
    this._curLanguage.next(lang);
    this.deviceLang.preferred = lang;
    this.translate.use(lang.iso);
    document.documentElement.lang = lang.iso;
  }
}
