import { AvProdSettingsType } from '../../const/av-producer.const';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { IEvent } from '../../interfaces/events/event.interface';

export class EventTutorialClass {

    protected _layoutMode: number = 0; //0: create modal, 1: tutorial modal

    protected _event: IEvent = {
        name: 'Name',
        userName: '',
        description: '',
        categoryId: 1,
        protected: false,
        private: false
      }
    protected itemsSettingsEventTutorial: IAvSettingsItemConfig[] = [
        {
          id: 'enabled',
          type: AvProdSettingsType.switchBoolean,
          name: 'producer.doNotShowWindowAgain',
          min: 0,
          max: 0,
          step: 1,
          options: [],
          placeholder: '',
          value: false
        }
    ]

    protected setEvent(event: IEvent): void{
        this._event = event;
    }

    protected onClickStart(): void {
        this.emitEventStart(this._event);
    }

    protected emitEventStart(value: IEvent): void {
        //must be overridden
    }

    protected onSettingChanged(event: IAvSettingsItemConfig): void {
        //TO BE CREATED
      }


}
