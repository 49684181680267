<div class="osd-container">
    <div [class]="scoreActive? 'osd-score zoomIn':'osd-score zoomOut'">
        <div class="team">
            <div class="team-name"><span>{{ scoreName1 }}</span></div>
            <div class="team-score"
                [style.background-color]="styleColorMain"
                [style.color]="styleColorSecondary">
                <span>{{ scoreValue1 }}</span>
            </div>
        </div>
        <div class="team">
            <div class="team-name"><span>{{ scoreName2 }}</span></div>
            <div class="team-score"
                [style.background-color]="styleColorMain"
                [style.color]="styleColorSecondary">
                <span>{{ scoreValue2 }}</span>
            </div>
        </div>
    </div>
    <div class="osd-bottom">
        <div [class]="!tickerActive && timeActive? 'osd-time growXIn':'osd-time growXOut'">
            <span class="time-text">{{ timeText }}</span>
        </div>
        <div [class]="bannerActive? 'osd-banner growYIn':'osd-banner growYOut'"
            [style.background-color]="styleColorMain">
            <span
                [style.color]="styleColorSecondary">{{ bannerText }}
            </span>
        </div>
        <div [class]="tickerActive? 'osd-ticker growYIn':'osd-ticker growYOut'">
            <span [class]="timeActive? 'ticker-time growXIn':'ticker-time growXOut'">{{ timeText }}</span>
            <span class="ticker-text">{{ tickerText }}</span>
        </div>
    </div>
</div>