import { ITabItemExtended } from '../../interfaces/utils/utils.interface';


export class ToolboxContainerClass {

  protected tabItems: ITabItemExtended[] = [
    {
      id: 1,
      label: 'producer.graphics',
      title: 'producer.graphics',
      icon: 'bi bi-palette',
      iconApp: 'palette',
      visible: true
    },
    {
      id: 2,
      label: 'producer.sounds',
      title: 'producer.sounds',
      icon: 'bi bi-music-note-list',
      iconApp: 'music-note-list',
      visible: true
    },
    {
      id: 3,
      label: 'producer.presets',
      title: 'producer.presets',
      icon: 'bi bi-list-stars',
      iconApp: 'list-stars',
      visible: true
    },
    {
      id: 4,
      label: 'producer.highlights',
      title: 'producer.highlights',
      icon: 'azz-ico-replay i-custom',
      iconApp: 'replay',
      visible: true
    },
    {
      id: 5,
      label: 'producer.score',
      title: 'producer.score',
      icon: 'azz-ico-score i-custom',
      iconApp: 'score',
      visible: true
    },
    {
      id: 6,
      label: 'producer.chat',
      title: 'producer.chat',
      icon: 'bi bi-chat-left-dots',
      iconApp: 'chat-left-dots',
      visible: true
    },
    {
      id: 7,
      label: 'producer.resources',
      title: 'producer.resources',
      icon: 'bi bi-box-seam',
      iconApp: 'box-seam',
      visible: true
    },
    {
      id: 8,
      label: 'general.audio',
      title: 'general.audio',
      icon: 'bi bi-volume-up',
      iconApp: 'volume-up',
      visible: true
    },
    {
      id: 9,
      label: 'producer.liveInputs',
      title: 'producer.liveInputs',
      icon: 'bi bi-box-arrow-in-right',
      iconApp: 'box-arrow-in-right',
      visible: true
    }
  ]

  protected tabItemEmpty: ITabItemExtended = {
    id: -1,
    label: '',
    title: '',
    icon: '',
    iconApp: '',
    visible: false
  }

  protected tabSelectedItem: ITabItemExtended = this.tabItems[0];

  protected emitInputSelect(value: number): void {
    // Must override
  }
  protected emitOpenInputSettings(value: number): void {
    // Must override
  }
  protected emitToolboxOpen(value: boolean): void {
    // Must override
  }

  protected setTabVisibility(visible: boolean[]): void {
    let firstVisible: ITabItemExtended | undefined;
    // Apply to tab list elements
    for (let i=0; i<this.tabItems.length; i++){
      if (visible[i] !== undefined){
        this.tabItems[i].visible = visible[i];
      }
      if ((firstVisible === undefined) && this.tabItems[i].visible){
        firstVisible = this.tabItems[i];
      }
    }

    if ((firstVisible !== undefined)&&(this.tabSelectedItem.visible !== true)){
      this.tabSelectedItem = firstVisible;
    }
  }

  protected onTabClick(item: ITabItemExtended) {
    this.tabSelectedItem = item;
    this.emitToolboxOpen(true);
  }

  protected onInputSelect(id: number) {
    this.emitInputSelect(id);
  }
}
