import { Subscription } from 'rxjs';
import { EventsService } from '../services/events/events.service';
import { UserService } from '../services/user/user.service';
import { IEventAsset, IEventUserLastChanges } from '../interfaces/events/event.interface';
import { AvProdInputType } from '../const/av-producer.const';

export class StorageOverviewClass {

    protected currentStorage: number =  0;
    protected totalStorage: number = 0;
    protected assets: IEventAsset[] = [];

    protected userChangesSubscription: Subscription | undefined;

    protected assetTypes: any[] = [
        {
            key: 'images',
            name: 'manager.storage.images',
            icon: 'bi bi-image',
            count: 0
        },
        {
            key: 'overlayImages',
            name: 'manager.storage.overlayImages',
            icon: 'bi bi-image-alt',
            count: 0
        },
        {
            key: 'videos',
            name: 'manager.storage.videos',
            icon: 'bi bi-play-btn',
            count: 0
        },
        {
            key: 'sounds',
            name: 'manager.storage.sounds',
            icon: 'bi bi-music-note-list',
            count: 0
        },
        {
            key: 'documents',
            name: 'manager.storage.documents',
            icon: 'bi bi-filetype-pdf',
            count: 0
        }
    ];

    constructor(protected events: EventsService,
                protected user: UserService){
        //Do nothing
        console.log('[StorageOverviewClass] constructor');
    }

    protected init(): void {
        this.userChangesSubscription = this.events.userChanges$.subscribe(data => this.checkUserChanges(data));
        this.updateAssetList();
        this.events.addPollingClient('StorageOverviewClass');

    }

    protected destroy(){
        this.userChangesSubscription?.unsubscribe();
        this.events.removePollingClient('StorageOverviewClass');
    }

    protected checkUserChanges(changes: IEventUserLastChanges){
        if ((changes.storage === true)||
            (changes.account === true)){
          this.updateAssetList();
        }
    }
    
    protected updateAssetList(){
        this.user.getUserData()
            .pipe()
            .subscribe(() => {
                if (this.user.user.storageUsed !== undefined){
                    this.currentStorage = this.user.user.storageUsed;
                }
                if (this.user.user.storageTotal !== undefined){
                    this.totalStorage = this.user.user.storageTotal;
                    this.emitStoragePlan(this.user.user.storageTotal);
                }
        });
        this.events.getUserAssetList()
            .pipe()
            .subscribe(answer => {
                if (answer !== null){
                this.assets = answer;
                this.updateAssetCount();
                }
        });
    }

    protected updateAssetCount(){
        let countImages: number = 0;
        let countOverlayImages: number = 0;
        let countVideos: number = 0;
        let countDocuments: number = 0;
        let countSounds: number = 0;
        for (let i: number = 0; i<this.assets.length; i++){
            switch(this.assets[i].type){
                case AvProdInputType.audioClip:
                    countSounds++;
                    break;
                case AvProdInputType.videoAudioClip:
                case AvProdInputType.videoClip:
                    countVideos++;
                    break;
                case AvProdInputType.imageTile:
                    countImages++;
                    break;
                case AvProdInputType.imageOverlay:
                    countOverlayImages++;
                    break;
                case AvProdInputType.document:
                    countDocuments++;
                    break;
            }
        }
        this.setAssetKeyCount('images', countImages);
        this.setAssetKeyCount('overlayImages', countOverlayImages);
        this.setAssetKeyCount('videos', countVideos);
        this.setAssetKeyCount('documents', countDocuments);
        this.setAssetKeyCount('sounds', countSounds);
    }

    protected setAssetKeyCount(key: string, count: number){
        const ITEM: any = this.assetTypes.find(element => element.key === key);
        if (ITEM !== undefined){
            ITEM.count = count;
        }
    }

    protected formatSizeKB(size: number): string {
      let scaledSize: number = size;
      let units: string[] = [' B',' KB',' MB',' GB'];
      let iterations: number = 1; // Input is KBytes

      if (scaledSize < 0){
        scaledSize = 0;
      }

      while ((scaledSize > 1024)&&(iterations < units.length-1))
      {
        scaledSize = scaledSize / 1024;
        iterations++;
      }
  
      return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
    }

    protected emitStoragePlan(item: number){
        //MUST BE OVERRIDEN
    }
  }