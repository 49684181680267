import { Subscription, timer } from 'rxjs';

import { ModalDialogId } from '../../enums/utils.enum';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { AudioService } from '../../services/audio/audio.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { IAvProdInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdInputPlayingState, AvProdInputTypeNumber } from '../../const/av-producer.const';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';

export class ToolbarControlClass {

  protected scoreHomeName: string = 'HOME';
  protected scoreHomeNumber: number = 0;
  protected scoreAwayName: string = 'AWAY';
  protected scoreAwayNumber: number = 0;

  protected listLiveActiveInputs: IAvProdInput[] = [];
  protected highlightBusy: boolean = false;

  protected audioSubscription: Subscription | undefined;
  protected composerSubscription: Subscription | undefined;
  protected inputListSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;
  protected highlightBusySubscription: Subscription | undefined;

  protected audioIcon: string = 'bi bi-volume-mute';

  constructor(protected avProd: AvProducerService,
              protected audioService: AudioService,
              protected modalService: ModalManagerService) {
    // do nothing
  }

  init(): void {
    this.updateAudioInfo();
    this.updateComposerSettings();
    if (this.audioSubscription !== undefined) {
      this.audioSubscription.unsubscribe();
    }
    this.audioSubscription = this.audioService.enabledChange$.subscribe(() => this.updateAudioInfo());
    if (this.composerSubscription !== undefined) {
      this.composerSubscription.unsubscribe();
    }
    this.composerSubscription = this.avProd.onNewComposerSettings$.subscribe(() => this.updateComposerSettings());
    this.inputListSubscription?.unsubscribe();
    this.inputListSubscription = this.avProd.components$.subscribe(() => this.updateList());
    this.inputStatusSubscription?.unsubscribe();
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateList());
    this.updateList();
  }

  close(): void {
    if (this.audioSubscription !== undefined) {
      this.audioSubscription.unsubscribe();
    }
    if (this.composerSubscription !== undefined) {
      this.composerSubscription.unsubscribe();
    }
    this.inputListSubscription?.unsubscribe();
    this.inputStatusSubscription?.unsubscribe();
    this.highlightBusySubscription?.unsubscribe();
  }

  protected updateAudioInfo(): void {
    // to be overridden
  }
  protected displayToast(config: IToastConfig): void {
    // to be overridden
  }

  protected showNoInputsLiveMessage(): void {
    const TOAST: IToastConfig = {
      options: {
        placement: ToastPlacement.middleCenter,
        delay: 5000,
      },
      data: {
        status: ToastStatus.info,
        text: 'toolboxHighlights.highlightsUnavailable',
        alignText: 'center',
      }
    }

    this.displayToast(TOAST);
  }

  protected onAudioToggle(): void {
    const ENABLED: boolean = !this.audioService.getEnabled();
    this.audioService.changeEnabled(ENABLED);
    this.updateAudioInfo();
  }

  protected updateComposerSettings(): void {
    if (this.avProd.composerSettings.overlayScore?.teams !== undefined) {
      if (this.avProd.composerSettings.overlayScore?.teams[0].nameShort !== undefined) {
        this.scoreHomeName = this.avProd.composerSettings.overlayScore?.teams[0].nameShort;
      }
      if (this.avProd.composerSettings.overlayScore?.teams[1].nameShort !== undefined) {
        this.scoreAwayName = this.avProd.composerSettings.overlayScore?.teams[1].nameShort;
      }
    }
    if (this.avProd.composerSettings.overlayScore?.values !== undefined) {
      if (this.avProd.composerSettings.overlayScore?.values[0] !== undefined) {
        this.scoreHomeNumber = this.avProd.composerSettings.overlayScore?.values[0];
      }
      if (this.avProd.composerSettings.overlayScore?.values[1] !== undefined) {
        this.scoreAwayNumber = this.avProd.composerSettings.overlayScore?.values[1];
      }
    }
  }

  protected onReplayCreate(): void {
    if (this.highlightBusy === false) {
      const MAX_TAGS: number | undefined = this.avProd.serverSettings?.production.maxHighlights;
      const CURRENT_TAGS: number | undefined = this.avProd.composerSettings.timeTags?.length;
      if ((CURRENT_TAGS !== undefined)&&(MAX_TAGS !== undefined)&&
          (CURRENT_TAGS >= MAX_TAGS)){

        this.displayToast({
          options: {
            placement: ToastPlacement.topCenter,
          },
          data: {
            status: ToastStatus.error,
            text: 'toolboxHighlights.errorMaxHighlights',
            closeButtonBody: true,
          },
        });
      }
      else if (this.listLiveActiveInputs.length > 0) {
        this.avProd.azzCmdComposerTimeTagCreateCurrent();
        this.highlightBusy = true;
        this.highlightBusySubscription?.unsubscribe();
        this.highlightBusySubscription = timer(5000).subscribe(() => this.highlightBusy = false);
        const TOAST: IToastConfig = {
          options: {
            placement: ToastPlacement.middleCenter,
          },
          data: {
            status: ToastStatus.success,
            text: 'toolboxHighlights.highlightCreateSuccess',
            closeButtonBody: true,          }
        }
        this.displayToast(TOAST);  
      } else {
        this.showNoInputsLiveMessage();
      }
    }
  }

  protected onConfig(): void {
    this.modalService.show({id: ModalDialogId.producerSettings, title: 'producer.producerSettings'}, false);
  }

  protected onScoreHome(): void {
    this.avProd.azzCmdComposerScoreIncrement(0, 1);
  }

  protected onScoreAway(): void {
    this.avProd.azzCmdComposerScoreIncrement(1, 1);
  }

  protected updateList(): void {
    //console.log('[ToolbarControlClass] update input list');
    this.listLiveActiveInputs = this.avProd.inputs.filter(
      ((input: IAvProdInput) => (input.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) &&
        (input.status?.playingState === AvProdInputPlayingState.playing))
    );
  }

}
