import { Subscription, interval } from 'rxjs';

import { AvProducerService, IAvProdVideoLayout } from '@azz-life-streamer/shared';


export class LayoutContentSelectionClass {
  protected pLayoutId: number = -1;
  protected layout: IAvProdVideoLayout | undefined;
  protected imgUrl: string = '';
  protected selectCountdown: number = 0;

  protected timerSubscription: Subscription | undefined;

  constructor(
    protected avProd: AvProducerService){
    // do nothing
  }

  protected destroy(): void {
      if (this.timerSubscription !== undefined){
        this.timerSubscription.unsubscribe();
      }
  }

  protected initialize() {
    console.log('[LayoutContentSelectionClass] initialize');
    const NEW_LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => (this.pLayoutId === element.id));
    this.layout = NEW_LAYOUT;
    const HOST: string = this.avProd.getHostUrl();
    this.imgUrl = 'https://' + HOST + '/';
  }

  protected setShow(value: boolean){
    console.log('[LayoutContentSelectionClass] Show ' + value);
    if (value === true){
      this.selectCountdown = 10;
      this.timerSubscription = interval(1000).subscribe(() => {
        this.tickCountdownCheck()
      });
    }
    this.initialize();
  }

  protected setLayoutId(id: number){
    console.log('[LayoutContentSelectionClass] layoutId ' + id);
    this.pLayoutId = id;
    this.initialize();
  }

  protected tickCountdownCheck(){
    this.selectCountdown--;
    if ((this.selectCountdown <= 0)&&
        (this.timerSubscription !== undefined)){

      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
      this.onCancel();
    }
  }

  protected onCancel() {
    this.emitCancel();
  }

  protected emitCancel() {

  }
}
