
import { IMediaDevice } from '@azz-life-streamer/shared';
import { timer } from 'rxjs';


export class BroadcasterAudioSelectClass {
  private pEnabled: boolean = true;
  private pDevice: IMediaDevice | undefined;
  private pClassClosed: boolean = false;
  protected pMediaStream: MediaStream | undefined;

  protected setEnabled(enable: boolean) {
    if (enable !== this.pEnabled) {
      this.pEnabled = enable;
      this.refreshAudioMedia();
    }
  }

  protected setDevice(dev: IMediaDevice | undefined) {
    this.pDevice = dev;
    this.refreshAudioMedia();
  }

  protected init(): void {
    this.pClassClosed = false;
    this.refreshAudioMedia();
  }

  protected destroy(){
    this.closeMediaAudio();
    this.pClassClosed = true;
  }

  private async openMediaAudio(): Promise<boolean> {
    let ret: boolean = false;
    if (this.pEnabled && this.pDevice !== undefined) {

      try {
        const CONSTRAINTS: any = {
          video: false,
          audio: {
            deviceId: this.pDevice.deviceId,
            //echoCancellation: this.,
            //autoGainControl: this.,
            //noiseSuppression: this.,
            volume: 1.0
            /* Other parameters: latency, channelCount, volume, googAutoGainControl */
          }
        }
        console.log('[BroadcastAudioSelect] : openMediaAudio Before - ' + this.pMediaStream);
        let tmpMediaStream: MediaStream | undefined = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
        if (this.pMediaStream !== undefined){
          this.closeMediaAudio();
        }
        this.pMediaStream = tmpMediaStream;
        console.log('[BroadcastAudioSelect] : openMediaAudio After - ' + this.pMediaStream);
        if (this.pMediaStream !== undefined) {
          if (this.pMediaStream.getAudioTracks().length > 0) {
            this.pMediaStream.getAudioTracks()[0].enabled = true;
          }
          /*this.pMediaStream.getTracks().forEach(function (track) {
            const SETTINGS: MediaTrackSettings = track.getSettings();
            console.log('[BroadcastAudioSelect] (openMediaAudio) Stream Settings: ' + SETTINGS.deviceId + ' ' + track.kind + ' ' + JSON.stringify(SETTINGS));
          })*/
          ret = true;
        }
      } catch (error) {
        console.log('[BroadcastAudioSelect] : openMedia Audio Error ' + error);
        ret = false;
      }
    }

    if (this.pClassClosed === true){
      this.closeMediaAudio();
    }
    return ret;
  }

  private closeMediaAudio() {
    console.log('[BroadcastAudioSelect] : closeMediaAudio Before - ' + this.pMediaStream);
    if (this.pMediaStream !== undefined) {
      try {
        let list: MediaStreamTrack[] = this.pMediaStream.getTracks();
        for (let i: number = 0; i<list.length; i++){
          list[i].stop();
          this.pMediaStream.removeTrack(list[i]);
        }
      } catch (e) {
        console.log('[BroadcastAudioSelect] : closeMedia Error ' + e);
      }
      this.pMediaStream = undefined;
      console.log('[BroadcastAudioSelect] : closeMedia');
    }
  }

  private refreshAudioMedia() {
    if (this.pEnabled && this.pDevice !== undefined) {
      this.openMediaAudio()
        .catch(console.error)
        .then((ret) => {
          console.log('[BroadcastAudioSelect] : refreshAudioMedia openMediaAudio result ' + ret);
        });
    } else {
      this.closeMediaAudio();
    }
  }
}
