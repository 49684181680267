import { Component } from '@angular/core';
import { Subscription, interval } from 'rxjs';

import { AvProducerLiteService, IAvProdComposerSettings, IAvProdTextGroup, IEvent } from '@azz-life-streamer/shared';

@Component({
  selector: 'azz-life-streamer-display-page',
  templateUrl: './display-page.component.html',
  styleUrls: ['./display-page.component.scss'],
})
export class DisplayPageComponent {

  protected bannerText: string = 'Banner';
  protected bannerActive: boolean = true;
  protected tickerText: string = 'Ticker moving while the user is writing his homework for the next day in a different page, and I am hungry right now';
  protected tickerActive: boolean = true;
  protected timeText: string = '';
  protected timeActive: boolean = false;

  protected scoreActive: boolean = true;
  protected scoreName1: string = 'Home';
  protected scoreValue1: number = 0;
  protected scoreName2: string = 'Away';
  protected scoreValue2: number = 0;

  protected styleColorMain: string = '';
  protected styleColorSecondary: string = '';

  protected avComposerSettingsSubscription: Subscription | undefined;
  protected timerSubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerLiteService){
  }

  ngOnInit(): void {
    this.init();
  }
  ngOnDestroy(): void {
    this.destroy();
  }

  protected init(){
    this.avProd.init();
    this.avProd.openComms();
    this.avComposerSettingsSubscription?.unsubscribe();
    this.avComposerSettingsSubscription = this.avProd.onNewComposerSettings$.subscribe(data => {
      this.receiveComposerSettings(data)
    });
  }

  protected destroy(){
    this.avComposerSettingsSubscription?.unsubscribe();
    this.timerSubscription?.unsubscribe();
  }

  protected receiveComposerSettings(settings: IAvProdComposerSettings){
    console.log('[DisplayPageComponent] receiveComposerSettings ' + JSON.stringify(settings));
    if (this.avProd.composerSettings.overlayBanner?.enabled !== undefined){
      this.bannerActive = this.avProd.composerSettings.overlayBanner.enabled;
    }
    if (this.avProd.composerSettings.overlayBanner?.textSelected !== undefined){
      let textGroup: IAvProdTextGroup | undefined = this.avProd.composerSettings.overlayBanner.texts?.find(element => element.id === this.avProd.composerSettings.overlayBanner?.textSelected);
      if (textGroup?.title !== undefined){
        this.bannerText = textGroup.title;
      }
    }

    if (this.avProd.composerSettings.overlayTicker?.enabled !== undefined){
      this.tickerActive = this.avProd.composerSettings.overlayTicker.enabled;
    }
    if (this.avProd.composerSettings.overlayTicker?.textSelected !== undefined){
      let textGroup: IAvProdTextGroup | undefined = this.avProd.composerSettings.overlayTicker.texts?.find(element => element.id === this.avProd.composerSettings.overlayTicker?.textSelected);
      if (textGroup?.text !== undefined){
        this.tickerText = textGroup.text;
      }
    }

    if (this.avProd.composerSettings.overlayStyle?.colorMain !== undefined){
      this.styleColorMain = this.colorText2Code(this.avProd.composerSettings.overlayStyle?.colorMain);
    }
    if (this.avProd.composerSettings.overlayStyle?.colorSecondary !== undefined){
      this.styleColorSecondary = this.colorText2Code(this.avProd.composerSettings.overlayStyle?.colorSecondary);
    }

    if (this.avProd.composerSettings.overlayTime?.enabled === true){
      if (this.timerSubscription === undefined){
        this.tickTimer();
        this.timerSubscription = interval(1000).subscribe(() => this.tickTimer());
      }
      this.timeActive = true;
    }
    else{
      this.timerSubscription?.unsubscribe();
      this.timerSubscription = undefined;
      this.timeActive = false;
    }

    if (this.avProd.composerSettings.overlayScore?.enabled === true){
      this.scoreActive = true;
    }
    else {
      this.scoreActive = false;
    }

    if (this.avProd.composerSettings.overlayScore?.teams !== undefined)
    {
      if (this.avProd.composerSettings.overlayScore?.teams[0] !== undefined){
        this.scoreName1 = this.avProd.composerSettings.overlayScore?.teams[0].nameShort?? 'Home';
      }
      if (this.avProd.composerSettings.overlayScore?.teams[1] !== undefined){
        this.scoreName2 = this.avProd.composerSettings.overlayScore?.teams[1].nameShort?? 'Away';
      }
    }
    if (this.avProd.composerSettings.overlayScore?.values !== undefined)
    {
      this.scoreValue1 = this.avProd.composerSettings.overlayScore?.values[0]?? 0;
      this.scoreValue2 = this.avProd.composerSettings.overlayScore?.values[1]?? 0;
    }
  }

  protected colorText2Code(color: string): string {
    let ret: string = color;
    switch (color){
      case 'white':
        ret = '#ffffff';
        break;
      case 'black':
        ret = '#000000';
        break;
      case 'red':
        ret = '#ff0000';
        break;
      case 'blue':
        ret = '#1d71b8';
        break;
      case 'darkblue':
        ret = '#172039';
        break;
      case 'lightblue':
        ret = '#009fe3';
        break;
      case 'yellow':
        ret = '#ffdc00';
        break;
      case 'green':
        ret = '#00dc1e';
        break;
      case 'orange':
        ret = '#f3920b';
        break;
      case 'gray':
        ret = '#c0c0c0';
        break;
      case 'darkgray':
        ret = '#646464';
        break;
    }
    return ret;
  }

  protected tickTimer(){
    //const NOW: number = Math.round((new Date()).getTime() / 1000);
    const NOW: Date = new Date(Date.now());
    this.timeText = NOW.getHours().toString().padStart(2, '0') + ':' +
                    NOW.getMinutes().toString().padStart(2, '0') + ':' +
                    NOW.getSeconds().toString().padStart(2, '0');
  }
}
