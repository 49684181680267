
export const RECONNECT_INTERVAL = 3000;

export const WS = {
      protocol: 'wss://',
      path: '/ws'
};

export enum WSStatus {
  closed = 'closed',
  connecting = 'connecting',
  closedByServer = 'closedByServer',
  closedByUser = 'closedByUser',
  closing = 'closing',
  open = 'open'
}

export enum WSRequestActions {
  hello = 'hello',
  helloSync = 'helloSync',
  bye = 'bye',
  ping = 'ping',
  get = 'get',
  set = 'set',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  command = 'command',
  publish = 'publish'
}
