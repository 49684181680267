import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAvProdOutputSettings } from '../../interfaces/av-producer/output-settings.interface';


export class ProducerSettingsDestinationsClass {

  protected destinationsFormArray: FormGroup[] = [];
  protected destinationsNames: string[] = [];
  protected destinationsInfo: string[] = [];
  protected destinationsPlaceholders: string[] = [];
  protected numberOfDestinations: number = 4;
  protected isBusy: boolean = false;
  protected isDirty: boolean = false;
  protected isResetNeeded: boolean = false;

  protected outputSettingsSubscription: Subscription = new Subscription();

  protected itemsDestinations: IAvSettingsItemConfig[] = [
    {
      id: 'destinationEnabled',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: ''
    },
    {
      id: 'destinationKey',
      type: AvProdSettingsType.text,
      name: 'producerSettings.destinationKey',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: ''
    }
  ]

  constructor(protected avProd: AvProducerService) { }

  protected init(): void {
    this.destinationsFormArray = [];
    this.destinationsNames = [];
    this.destinationsInfo = [];
    this.destinationsPlaceholders = [];
    for (let i = 0; i < this.numberOfDestinations; i++) {
      const FORM_GROUP: FormGroup = new FormGroup([]);
      this.itemsDestinations.forEach(element => {
        FORM_GROUP.addControl(element.id, new FormControl());
      });
      this.destinationsFormArray.push(FORM_GROUP);
      this.destinationsNames.push('Dest ' + i);
      this.destinationsPlaceholders.push('outputSettings.destinationKey');
      this.destinationsInfo.push('');
    }
    this.readServerSettings();
    this.outputSettingsSubscription = this.avProd.onNewOutputSettings$.subscribe((settings: IAvProdOutputSettings) => this.onNewServerSettings());

  }

  protected destroy(): void {
    this.outputSettingsSubscription?.unsubscribe();
  }

  protected emitDirty(value: boolean): void {
    // must override
  }

  protected displayToast(config: IToastConfig): void {
    // must override
  }

  protected readServerSettings(): void {
    if (!this.isDirty) {
      // Destinations settings
      if (this.avProd.outputSettings.destinations !== undefined) {
        this.numberOfDestinations = this.avProd.outputSettings.destinations.length;
      }
      this.isResetNeeded = false;
      this.isDirty = false;
      this.emitDirty(this.isDirty);
    } else {
      this.isResetNeeded = true;
    }
  }

  protected onSettingChanged(event: any): void {
    this.refreshDirtyFlags();
  }

  protected refreshDirtyFlags(): void {
    let dirty: boolean = false;
    if (!dirty) {
      this.destinationsFormArray.forEach(element => {
        if (element.dirty) {
          dirty = true;
          this.isResetNeeded = true;
        }
      });
    }
    this.isDirty = dirty;
    this.emitDirty(this.isDirty);
  }

  protected onNewServerSettings(): void {
    if (this.isDirty) {
      this.isResetNeeded = true;
    } else {
      this.readServerSettings();
    }
  }

  protected onSave(): void {
  }

  protected onReset(): void {
    this.isDirty = false;
    this.readServerSettings();
    this.emitDirty(this.isDirty);
  }
}
