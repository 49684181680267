import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';

import { AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES, AuthService, AvProdInputTypeNumber, AvProdSettingsType, EventsService, IAvSettingsItemConfig, IToastConfig, ToastPlacement, ToastStatus, UserService } from '@azz-life-streamer/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { ENV_COMMON } from '@azz-life-streamer/environments';
import { timer } from 'rxjs';


export class InputAddResourceUploadClass {
    protected _resourceType: AvProdInputTypeNumber = AvProdInputTypeNumber.none;
    protected _baseUrl: string = '';
    protected _tokenProducer: string = '';
    protected _target: 'cloud' | 'event' = 'event';
    protected _allowTypeChange: boolean = false;
    protected onlyForThisEvent: boolean = false;
    protected preload: boolean = true;
    protected fileAccept: string = '';

    protected uploader: FileUploader = new FileUploader({
      url: this._baseUrl
    });
    protected settingsResource: FormGroup = new FormGroup([]);
    protected itemResourceType: IAvSettingsItemConfig =
    {
        id: 'resourceType',
        type: AvProdSettingsType.selectComboNumber,
        name: 'inputAdd.resourceType',
        min: 0,
        max: 0,
        step: 1,
        options: AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES,
        placeholder: 'inputAdd.selectResourceType',
        value: undefined
    } 

    protected itemOnlyForThisEvent: IAvSettingsItemConfig =
    {
        id: 'onlyForThisEvent',
        type: AvProdSettingsType.switchBoolean,
        name: 'inputAdd.onlyForThisEvent',
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: '',
        info: 'inputAdd.onlyForThisEventInfo',
        value: this.onlyForThisEvent
    }

    protected itemPreload: IAvSettingsItemConfig =
    {
        id: 'preload',
        type: AvProdSettingsType.switchBoolean,
        name: 'inputAdd.preload',
        min: 0,
        max: 0,
        step: 1,
        options: [],
        placeholder: '',
        info: 'inputAdd.preloadInfo',
        value: this.preload
    }

    constructor(protected userService: UserService,
                protected authService: AuthService,
                protected events: EventsService){
      this.settingsResource.addControl(this.itemResourceType.id, new FormControl());
      this.settingsResource.addControl(this.itemOnlyForThisEvent.id, new FormControl());
      this.settingsResource.addControl(this.itemPreload.id, new FormControl());
      this.settingsResource.controls['onlyForThisEvent']?.setValue(this.onlyForThisEvent);
      this.settingsResource.controls['preload']?.setValue(this.preload);

      this.uploader.onWhenAddingFileFailed = ((item, filter, options) => this.onFileError(item, filter, options));
      this.uploader.onCompleteItem = ((item, response, status, headers) => this.onUploadCompleted(item, response, status, headers));
    }
  
    protected setResourceType(value: AvProdInputTypeNumber){
      console.log('[InputAddResource] setResourceType: ' + value);
      if (value !== undefined){
        this._resourceType = value;
        this.settingsResource.controls['resourceType']?.setValue(this._resourceType);
        if ((this._resourceType === AvProdInputTypeNumber.imageTile)||
            (this._resourceType === AvProdInputTypeNumber.imageOverlay)){
          this.uploader.setOptions({
              url: this._baseUrl,
              allowedFileType: ['image'] });
        }
        this.setUploaderOptions();
        this.updateTypeChangeEnable();
      }
    }
    protected setAllowTypeChange(value: boolean): void {
      this._allowTypeChange = value;
      this.updateTypeChangeEnable();
    }
  
    protected updateTypeChangeEnable(){
      if (this._allowTypeChange === false){
        this.settingsResource.controls['resourceType']?.disable();
      }
      else {
        this.settingsResource.controls['resourceType']?.enable();
      }
    }

    protected setBaseUrl(value: string){
      console.log('[InputAddResource] setBaseUrl: ' + value);
      if (value !== undefined){
        this._baseUrl = value;
        this.setUploaderOptions();
      }
    }
    protected setTokenProducer(value: string){
      if (value !== this._tokenProducer){
        this._tokenProducer = value;
        this.setUploaderOptions();
      }
    }
    protected setTarget(value: 'cloud'|'event'){
      if (value !== this._target){
        this._target = value;
        if (this._target === 'cloud'){
          this._baseUrl = ENV_COMMON.uploadUrl;
        }
        this.setUploaderOptions();
      }
    }

    protected init(): void {
      //this.setResourceType(this._resourceType);
      this.uploader.onAfterAddingFile = (item => {
        item.withCredentials = false;
      });
    }
    protected destroy(): void {
    }

    protected displayToast(config: IToastConfig): void {
      // To be overridden
    }

    protected formatSize(size: number): string {
      let scaledSize: number = size;
      let units: string[] = [' B',' KB',' MB',' GB'];
      let iterations: number = 0;

      if (scaledSize < 0){
        scaledSize = 0;
      }

      while ((scaledSize > 1024)&&(iterations < units.length-1))
      {
        scaledSize = scaledSize / 1024;
        iterations++;
      }

      return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
    }

    protected setUploaderOptions(){
      let options: FileUploaderOptions = {
        url: this._baseUrl
      }

      if (this._target === 'cloud'){
        options = {
          url: this._baseUrl,
          disableMultipart : false,
          autoUpload: true,
          method: 'post',
          itemAlias: 'file',
          additionalParameter: {
            user: this.userService.user.id,
            token: this.authService.accessToken,
            resourceType: this._resourceType,
            preload: this.preload
          }
        }
      }
      else {
        options = {
          url: this._baseUrl,
          disableMultipart : false,
          autoUpload: true,
          method: 'post',
          itemAlias: 'fileToUpload',
          additionalParameter: {
            token: this._tokenProducer,
            resourceType: this._resourceType,
            moveToArchive: this.onlyForThisEvent !== true
          }
        }
      }

      if ((this._resourceType === AvProdInputTypeNumber.imageTile)||
          (this._resourceType === AvProdInputTypeNumber.imageOverlay)){
        options.allowedFileType = ['image'];
        options.maxFileSize = 10 * 1024 * 1024; // 10 MB
        this.fileAccept = 'image/*';
      }
      else if (this._resourceType === AvProdInputTypeNumber.document){
        options.allowedFileType = ['pdf'];
        options.maxFileSize = 20 * 1024 * 1024; // 20 MB
        this.fileAccept = 'application/pdf';
      }
      else if (this._resourceType === AvProdInputTypeNumber.videoAudioClip){
        options.allowedFileType = ['video'];
        options.maxFileSize = 50 * 1024 * 1024; // 50 MB
        this.fileAccept = 'video/*';
      }
      else if (this._resourceType === AvProdInputTypeNumber.audioClip){
        options.allowedFileType = ['audio'];
        options.maxFileSize = 10 * 1024 * 1024; // 10 MB
        this.fileAccept = 'audio/*';
      }
      this.uploader.setOptions(options);
    }

    protected onSettingChanged(setting: IAvSettingsItemConfig){
      if (setting.id === 'resourceType'){
        this.setResourceType(this.settingsResource.controls['resourceType']?.value);
        this.uploader.clearQueue();
      }
      if (setting.id === 'onlyForThisEvent'){
        this.onlyForThisEvent = this.settingsResource.controls['onlyForThisEvent']?.value;
        this.setUploaderOptions();
      }
      if (setting.id === 'preload'){
        this.preload = this.settingsResource.controls['preload']?.value;
        this.setUploaderOptions();
      }
    }

    protected onSelectFiles(event: any){
      console.log('[InputAddResourceUpload] onSelectFiles: ' + JSON.stringify(event));
    }

    protected onFileError(item: any, filter: any, options: any){
      let reasonText: string = 'inputAdd.errorFileGeneric';
      if (filter?.name === 'fileType'){
        reasonText = 'inputAdd.errorFileType';
      }
      else if (filter?.name === 'fileSize'){
        reasonText = 'inputAdd.errorFileSize';
      }
      this.displayToast({
        options: {
          placement: ToastPlacement.middleCenter,
          autohide: true
        },
        data: {
          status: ToastStatus.error,
          text: reasonText,
          closeButtonBody: true,

        },
      });
      console.log('[InputAddResourceUpload] onUploadError: ' + JSON.stringify(filter));
    }

    protected onUploadCompleted(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders){
      console.log('[InputAddResourceUpload] onUploadCompleted');
      timer(1000).subscribe(() => this.events.forcePollingChanges());
    }
}
