
import { ElementRef } from '@angular/core';
import { AvProducerService, IAvProdVideoLayout } from '@azz-life-streamer/shared';


export class TileSelectionClass {
  private _layoutId: number = -1;
  private _tileContainerElement: ElementRef<HTMLDivElement> | undefined;
  protected layout: IAvProdVideoLayout | undefined;
  protected imgUrl: string = '';

  constructor(
    protected avProd: AvProducerService){
    // do nothing
  }

  protected setShow(value: boolean) {
    console.log('[TileSelectionClass] Show ' + value);
    this.update();
  }

  protected setLayoutId(id: number) {
    console.log('[TileSelectionClass] layoutId ' + id);
    this._layoutId = id;
    this.update();
  }

  protected emitTileSelect(id: number){

  }
  protected emitCancel(){

  }

  protected initialize(tileContainerElement: ElementRef<HTMLDivElement> | undefined) {
    if (tileContainerElement !== undefined){
      this._tileContainerElement = tileContainerElement;
    }
    this.update();
  }

  private update(){
    console.log('[TileSelectionClass] initialize');
    const NEW_LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => (this._layoutId === element.id));
    this.layout = NEW_LAYOUT;
    const HOST: string = this.avProd.getHostUrl();
    this.imgUrl = 'https://' + HOST + '/';
    /*
    if ((this.tileContainerElement !== undefined)&&
        (this.layout !== undefined)){
        let count = 1;
        this.tileContainerElement.nativeElement.innerHTML = '';
        this.layout.tiles.forEach(tile => {
          let div: HTMLDivElement = <HTMLDivElement>(document.createElement('div'));
          div.classList.add('tile-item');
          div.style.position = 'absolute';
          div.style.cursor = 'pointer';
          div.style.backgroundColor = 'rgba(0,0,0,0.7)';
          div.style.borderStyle = 'solid';
          div.style.borderWidth = '2px';
          div.style.borderColor = 'cyan';
          div.style.left = tile.x + '%';
          div.style.top = tile.y + '%';
          div.style.width = tile.width + '%';
          div.style.height = tile.height + '%';
          div.style.zIndex = count.toFixed();
          count++;
          if (this.tileContainerElement !== undefined){
            this.tileContainerElement.nativeElement.appendChild(div)
          }
        });
    }
    */
  }

  protected onTileClick(id: number) {
    console.log('[TileSelectionClass] onTileClick:' + id);
    this.emitTileSelect(id);
  }

  protected onCancel() {
    this.emitCancel();
  }
}
