import { AlertStatus, ToastPlacement, ToastStatus } from '../enums/common.enum';
import { IEventDestination } from '../interfaces/events/event.interface';
import { IAlertData, IToastConfig } from '../interfaces/utils/utils.interface';
import { EventsService } from '../services/events/events.service';

export class DestinationsPageClass {
  protected isBusy: boolean = false;
  protected destinations: IEventDestination[] = [];
//  protected customRtmpName: string = '';
//  protected customRtmpUrl: string = '';


  constructor(protected events: EventsService){
  }

  protected init(error: string | undefined): void {
    console.log('[DestinationsPage] init error:' + error);
    if ((error !== undefined)&&(error === '11')){
      this.displayToast({
        options: {
          placement: ToastPlacement.topCenter,
        },
        data: {
          status: ToastStatus.info,
          text: 'destinations.infoCancel',
          closeButtonBody: true,
          alignText: 'text-center'
        },
      });      
    }
    else if ((error !== undefined)&&(error !== '0')){
      let msg: string = 'destinations.errorManage';

      if (error === '7'){
        msg = 'destinations.errorManageDuplicated';
      }
      else if ((error === '2')||
               (error === '8')){
        msg = 'destinations.errorManageExternal';
      }
      else if ((error === '12')){
        msg = 'destinations.errorManageReconnectUserNotMatch';
      }
      this.displayToast({
        options: {
          placement: ToastPlacement.topCenter,
        },
        data: {
          status: ToastStatus.error,
          text: msg,
          closeButtonBody: true,
          alignText: 'text-center'
        },
      });      
    }
  }

  protected displayAlert(data: IAlertData){
    // Must override
  }
  protected displayToast(config: IToastConfig){
    // Must override
  }
}
