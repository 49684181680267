import { Router } from '@angular/router';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { COMMON } from "../../const/common.const";
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';


export class ManagerAccountClass {
  protected isBusy: boolean = false;
  protected isProduction: boolean = false;
  protected productKey: string | undefined;
  protected productCustomer: string = '';
  protected productLevel: string = '';
  protected productBilling: string = '';

  constructor(
    protected userService: UserService,
    protected events: EventsService,
    protected router: Router) {
      if (ENV_COMMON.production) {
        this.isProduction = true;
      }
      this.updateCurrentPlan();
  }

  protected displayToast(config: IToastConfig): void {
    // Must override
  }

  protected logout(): void {
    this.showConfirm('logout');
  }

  protected deleteAccount(): void {
    this.showConfirm('deleteAccount');
  }

  protected gotoSelectSubscription(): void {
    this.router.navigate([COMMON.urls.prices])
      .catch(console.error);
  }

  protected gotoStripeCustomerPortal(): void {
    this.isBusy = true;
    try{
      this.events.getStripeCustomerPortal()
        .pipe()
        .subscribe(answer => {
          console.log('[PayCheckout] Return CheckSession: ' + JSON.stringify(answer));
          if ((answer.status_code === 200) && (answer.data.url !== undefined)) {
            window.open(answer.data.url, '_self');
          }
          this.isBusy = false;
        });
    }
    catch(error){
      this.isBusy = false;
    }
  }

  private showConfirm(mode: string): void {

    this.displayToast({
      options: {
        autohide: false,
        placement: ToastPlacement.middleCenter
      },
      data: {
        status: ToastStatus.info,
        text: (mode === 'logout') ? 'users.confirmLogout' : 'users.confirmDeleteAccount',
        alignText: 'text-center',
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          closeButton: true,
        },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            if (mode === 'logout') {
              this.userService.logout();
              this.router.navigateByUrl(COMMON.urls.home)
                .catch(console.error);
            }
            if (mode === 'deleteAccount') {
              this.userService.deleteAccount()
                .subscribe({
                  next: this.handleDeleteAccountConfirmResponse.bind(this),
                  error: this.handleDeleteAccountConfirmErrorResponse.bind(this)
                });
            }
          }
        }],
      },
    })
  }

  private handleDeleteAccountConfirmResponse(result: boolean): void {
    if (result) {
      console.log('account deleted');
    } else {
      console.log('unable to delete account');
    }
  }

  private handleDeleteAccountConfirmErrorResponse(): void {
    console.log('unable to delete account');
  }

  private updateCurrentPlan(): void{
    this.productKey = this.userService.user.productKey;

    if (this.productKey !== undefined) {
      if (this.productKey[0] === 'i') {
        this.productCustomer = '';
      } else if (this.productKey[0] === 'o') {
        this.productCustomer = 'manager.customerTypeNameOrganization';
      }

      if (this.productKey[2] === 'm') {
        this.productBilling = 'manager.billingModeNameMonthly';
      } else if (this.productKey[2] === 'y') {
        this.productBilling = 'manager.billingModeNameYearly';
      }

      if (this.productKey[1] === 'b') {
        this.productLevel = 'manager.subscriptionLevelNameBronze';
      } else if (this.productKey[1] === 's') {
        this.productLevel = 'manager.subscriptionLevelNameSilver';
      } else if (this.productKey[1] === 'g') {
        this.productLevel = 'manager.subscriptionLevelNameGold';
      } else {
        this.productLevel = 'manager.subscriptionLevelNameFree';
        this.productBilling = '';
      }
    } else {
      this.productLevel = 'manager.subscriptionLevelNameFree';
      this.productBilling = '';
      this.productCustomer = 'manager.customerTypeNameIndividual';
    }
  }
}
