// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const ENV_WEB = {
  production: false,
  gSsoClientId: '492270698470-dnuhqj9d1lbs1tisirdlniuh81f99i82.apps.googleusercontent.com',
  gaMeasurementId: 'G-P21T9PRMS0',
  googleTagManagerId: '',
  facebookPixelId: '907750327103938',
  adSenseId: 'ca-pub-3365784766440075',
  vastKey: '3373c22d53e310da65178a6f9e8991e5',
  banners: {
    watch: '240ceb79e20945c8a237d12087d7a5de'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
