import { NgForm } from '@angular/forms';

import { IActivateUser } from '../../interfaces/user/user.interface';
import { UserService } from '../../services/user/user.service';


export class ActivateAccountClass {
  protected sendButtonDisabled: boolean = false;
  protected resendButtonDisabled: boolean = false;
  protected activationInfo: boolean = false;
  protected error: boolean = false;
  protected errorTitle: string = '';
  protected errorMessage: string = '';

  protected activateUser: IActivateUser = {
    code: undefined,
    email: '',
  };

  constructor(protected userService: UserService ) { }

  protected activateAccount(fCodeForm: NgForm): void {
    this.activationInfo = false;
    this.error = false;
    if ( fCodeForm.valid ) {
      this.sendButtonDisabled = true;
      this.userService.activateAccount(this.activateUser)
        .subscribe({
          next: this.handleSendCodeResponse.bind(this),
          error: this.handleSendCodeErrorResponse.bind(this),
        });
    }
  }

  protected modalClose(): void {
    // must be overridden
  }

  protected sendActivationCode(): void {
    this.resendButtonDisabled = true;
    this.activationInfo = false;
    this.error = false;
    this.userService.sendActivation(this.activateUser)
      .subscribe({
        next: this.handleSendActivationResponse.bind(this),
        error: this.handleSendActivationErrorResponse.bind(this)
      });
  }

  protected resetVars(): void {
    this.activateUser.code = undefined;
    this.activationInfo = false;
    this.error = false;
    this.errorTitle = '';
    this.errorMessage = '';
    this.sendButtonDisabled = false
    this.resendButtonDisabled = false
    this.activateUser = {
      code: undefined,
      email: '',
    };
  }
  protected showErrorMessage(): void {
    //must be overridden
  }

  private handleSendCodeResponse(): void {
    this.activationInfo = true;
    this.errorTitle = 'users.accountActivated';
    this.errorMessage = '';
    setTimeout(() => {
      this.resetVars();
      this.modalClose();
    }, 3000);
    this.showErrorMessage();
  }

  private handleSendCodeErrorResponse(): void {
    this.error = true;
    this.sendButtonDisabled = false
    this.errorTitle = 'users.invalidCode';
    this.errorMessage = 'users.errorCodeInfo';
    this.showErrorMessage();
  }

  private handleSendActivationResponse(): void {
    this.activationInfo = true;
    this.resendButtonDisabled = false
    this.errorTitle = 'users.newCodeSent';
    this.errorMessage = 'users.codeSentAgainCheckEmail';
    this.showErrorMessage();
  }

  private handleSendActivationErrorResponse(): void {
    this.error = true;
    this.resendButtonDisabled = false
    this.errorTitle = 'general.error';
    this.errorMessage = 'users.codeSendError';
    this.showErrorMessage();
  }

}
