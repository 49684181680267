import { filter, Observable, of, switchMap, tap } from 'rxjs';

import { COMMON } from '../../const/common.const';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { DeviceService } from '../../services/device/device.service';
import { LanguageService } from '../../services/language/language.service';


export class AuthUserGuardSuper {

  constructor(protected userService: UserService,
              protected authService: AuthService,
              protected deviceService: DeviceService,
              protected languageService: LanguageService,
              protected type: string) {
   // console.log('[AuthUserGuard] constructor Parent');
  }

  public canActivate(): Observable<boolean> {
    //console.log('[AuthUserGuard] canActivate');
    if (this.userService.user) {
      //console.log('[AuthUserGuard] canActivate - user: ', this.userService.user);
      localStorage.removeItem(COMMON.storageKeys.redirectTo);
      return of(!!this.userService.user);
    } else {
      //console.log('[AuthUserGuard] canActivate - user undefined - tries to authenticate');
      return this.deviceService.initDevice(this.type)
        .pipe(
          switchMap(() => this.authService.autoAuthAvailable()),
          tap((authResult: boolean) => {
            if (!authResult) {
              this.userService.initUserAnonymous();
              this.redirectLogin();
            }
          }),
          filter((authResult: boolean) => authResult),
          switchMap(() => this.userService.initUser()),
          tap((loginResult: boolean) => {
            this.appHookAction()
            if(!loginResult) {
              this.redirectLogin();
            } else {
              localStorage.removeItem(COMMON.storageKeys.redirectTo);
            }
          })
        );
    }
  }

  protected redirectLogin(): void {
    // must be overridden
  }

  protected appHookAction(): void {
    // must be overridden if needed
  }

}
