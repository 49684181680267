
import { AvProdTileSelectionState, AvProducerService, IAvLayoutTileSelectionProgress, IAvProdVideoLayout } from '@azz-life-streamer/shared';
import { Subscription } from 'rxjs';


export class LayoutConfigurationClass {

  protected imgUrl: string = '';
  protected layoutFavNames: string[] = [];
  protected maxFavLayouts: number = 5;

  protected layoutSubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerService) {
    // do nothing
  }

  protected init() {
    this.imgUrl = 'https://' + this.avProd.getHostUrl() + '/';
    this.updateLayoutInfo();
    this.layoutSubscription = this.avProd.onNewLayoutManagerSettings$.subscribe(() => this.updateLayoutInfo());
  }

  protected close() {
    this.layoutSubscription?.unsubscribe();
  }

  protected updateLayoutInfo(){
    const NAMES: string[] = [];
    for (let i: number=0; i<this.avProd.layoutManager.favorites.length; i++){
      const LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => element.id === this.avProd.layoutManager.favorites[i]);
      if (LAYOUT?.name !== undefined){
        NAMES.push(LAYOUT?.name);
      }
      else{
        NAMES.push('');
      }
    }
    this.layoutFavNames = NAMES;
    console.log('[LayoutConfigurationClass] updateLayoutInfo ' + JSON.stringify(this.layoutFavNames));
  }
  protected moveUp(layoutFavoriteIndex: number){
    this.avProd.azzCmdLayoutFavorietMoveUp(layoutFavoriteIndex);
  }
  protected moveDown(layoutFavoriteIndex: number){
    this.avProd.azzCmdLayoutFavorietMoveDown(layoutFavoriteIndex);
  }
  protected moveTop(layoutFavoriteIndex: number){
    this.avProd.azzCmdLayoutFavorietMoveTop(layoutFavoriteIndex);
  }
}
