import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IAlertData } from '../../interfaces/utils/utils.interface';
import { AlertStatus } from '../../enums/common.enum';


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertEvents$: Observable<IAlertData>
  private _alertEvents: Subject<IAlertData> = new Subject<IAlertData>();

  constructor() {
    this.alertEvents$ = this._alertEvents.asObservable();
  }

  showAlert(data: IAlertData): void {
    this._alertEvents.next(data);
  }

  close(): void {
    this._alertEvents.next({show: false, status: AlertStatus.success, closeButton: true})
  }
}
