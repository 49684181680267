import { Route } from '@angular/router';
import { DisplayPageComponent } from './display/pages/display-page/display-page.component';

export const appRoutes: Route[] = [
    {
        path: '',
        loadChildren: () => import('./display/display.module').then(m => m.DisplayModule),
        component: DisplayPageComponent,
    }
];
