import { Subscription, interval } from 'rxjs';

import {
  AudioService,
  AvProdDeviceType,
  AvProdWebRtcConnectionType,
  AvProducerService,
  IAvProdInputDropInfo,
  IAvProdInputTileSwapInfo,
  IAvProdWebRtcConnection,
  IAvProdWebRtcViewerData
} from '@azz-life-streamer/shared';


export class ViewerWebRtcClass {
  protected _deviceType: AvProdDeviceType = AvProdDeviceType.none;
  protected _deviceId: number = -1;
  protected _webRtcConnection: IAvProdWebRtcConnection | undefined;
  protected _webRtcViewer: IAvProdWebRtcViewerData | undefined;
  protected _ctx: OffscreenCanvasRenderingContext2D | null | undefined;
  protected _frames: VideoFrame[] = [];
  protected _webRtcAudioEnabled: boolean = false;
  protected _vumeterDisplay: string = 'outside'; //'inside', 'outside'

  protected mouseMoveStartX: number = 0;
  protected mouseMoveStartY: number = 0;
  protected mouseMoveEndX: number = 0;
  protected mouseMoveEndY: number = 0;
  protected mouseDragging: boolean = false;

  protected timerSubscription: Subscription | undefined;
  protected timerDrawSubscription: Subscription | undefined;
  protected newFrameSubscription: Subscription | undefined;
  protected newAudioSubscription: Subscription | undefined;
  protected enableAudioSubscription: Subscription | undefined;

  protected canvasElement: HTMLCanvasElement | undefined;
  protected divElement: HTMLDivElement | undefined;

  protected loading: boolean = true;
  protected audioIcon: string = 'bi-volume-mute-fill';

  protected _showControls: boolean = false;

  constructor(protected avProd: AvProducerService,
              protected audio: AudioService) {
  }

  init(canvas: HTMLCanvasElement | undefined, div: HTMLDivElement | undefined): void {
    console.log('[ViewerWebRtcClass] INIT (' + this._deviceType + '/' + this._deviceId + ')');
    this.canvasElement = canvas;
    this.divElement = div;
    if (this.timerSubscription !== undefined) this.timerSubscription.unsubscribe();
    this.timerSubscription = interval(1000).subscribe(() => {
      this.tickConnectionCheck()
    });
    if (this.timerDrawSubscription !== undefined) this.timerDrawSubscription.unsubscribe();
    this.timerDrawSubscription = interval(35).subscribe(() => {
      this.tickDrawFrame()
    });
    if (this.enableAudioSubscription !== undefined){
      this.enableAudioSubscription.unsubscribe();
    }
    this.enableAudioSubscription = this.audio.enabledChange$.subscribe(() => this.updateAudioInfo());
    this.openRemoteVideo();
  }

  close(): void {
    console.log('[ViewerWebRtcClass] CLOSE (' + this._deviceType + '/' + this._deviceId + ')');
    if (this.timerSubscription !== undefined) this.timerSubscription.unsubscribe();
    if (this.timerDrawSubscription !== undefined) this.timerDrawSubscription.unsubscribe();
    if (this.newFrameSubscription !== undefined) this.newFrameSubscription.unsubscribe();
    if (this.newAudioSubscription !== undefined) this.newAudioSubscription.unsubscribe();
    if (this.enableAudioSubscription !== undefined) this.enableAudioSubscription.unsubscribe();

    this.avProd.webRtcConnectionDelete(AvProdWebRtcConnectionType.viewer, this._deviceType, this._deviceId)
      .catch(console.error);
  }

  protected setDeviceId(id: number) {
    if (id !== this._deviceId) {
      this._deviceId = id;
    }
  }

  protected setDeviceType(type: AvProdDeviceType) {
    if (type !== this._deviceType) {
      this._deviceType = type;
    }
  }

  protected setAudioEnabled(enabled: boolean) {
    if (enabled !== this._webRtcAudioEnabled) {
      this._webRtcAudioEnabled = enabled;
    }
  }

  protected setVumeterDisplay(display: string){
    if (display !== this._vumeterDisplay) {
      this._vumeterDisplay = display;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected emitDropItemInput(info: IAvProdInputDropInfo) {
    //must be overridden
  }

  protected tickConnectionCheck() {
    this.openRemoteVideo();
  }

  protected tickDrawFrame() {
    this.deleteOldFrames(5);
    const FRAME: VideoFrame | undefined = this._frames.shift();
    if (FRAME !== undefined) {
      this.drawNewFrame(FRAME);
      FRAME?.close();
    }
  }

  protected receiveNewAudio(data: Uint8Array) {
    this.audio.receiveAudioFrame(data);
  }

  protected receiveNewFrame(frame: VideoFrame) {
    if (frame.timestamp === 0) {
      this.loading = false;
    }
    this._frames.push(frame);
    this.deleteOldFrames(5);
  }

  protected deleteOldFrames(count: number){
    while (this._frames.length > count) {
      const FRAME: VideoFrame | undefined = this._frames.shift();
      if (FRAME !== undefined) {
        FRAME.close();
      }
      console.log('[ViewerWebRtcClass] deleteOldFrames - Discard frame. Count:' + this._frames.length);
    }
  }

  protected requestVideoKeyFrame(){
    this.avProd.webRtcConnectionForceKeyFrame(this._deviceType, this._deviceId);
  }

  protected drawNewFrame(frame: VideoFrame) {
    const TIME_NOW: number = Date.now();

    if (this.canvasElement !== undefined) {
      if ((this.canvasElement.width !== frame.displayWidth) ||
          (this.canvasElement.height !== frame.displayHeight)) {
        this.canvasElement.remove();
        this.canvasElement = undefined;
        this._ctx = null;
      }
    }

    if ((this.divElement !== undefined) && (this.canvasElement === undefined)) {
      console.log('[ViewerWebRtcClass] receiveNewFrame Create new canvas');
      this.divElement.innerHTML = '';
      this.canvasElement = <HTMLCanvasElement>(document.createElement('canvas'));
      this.canvasElement.width = frame.displayWidth;
      this.canvasElement.height = frame.displayHeight;
      this.divElement.appendChild(this.canvasElement);
      this.canvasElement.classList.add('viewer-canvas');
      //this.canvasElement.style.aspectRatio = (this.canvasElement.width / this.canvasElement.height).toString();
      this._ctx = this.canvasElement.transferControlToOffscreen().getContext('2d', {alpha: false});
      //if (this._ctx !== null){
      //  this._ctx.imageSmoothingEnabled = true;
      //}
    }

    if ((this._ctx !== undefined) && (this._ctx !== null) && (this.canvasElement !== undefined)) {
      this._ctx.drawImage(frame, 0, 0, this.canvasElement.width, this.canvasElement.height);
      if (typeof (this._ctx.commit) === 'function') {
        this._ctx.commit();
      }
      const TIME_DIFF = Date.now() - TIME_NOW;
      if (TIME_DIFF > 2) {
        //console.log('[ViewerWebRtcClass] receiveNewFrame (Long draw):' + TIME_DIFF + ' ' + this._deviceType + '/' + this._deviceId);
      }
    }
  }

  protected openRemoteVideo() {
    if (((this._deviceType === AvProdDeviceType.input) || (this._deviceType === AvProdDeviceType.output)) &&
      (this._deviceId !== -1)) {

      if ((this._ctx === undefined) || (this._ctx === null)) {
        this._ctx = this.canvasElement?.transferControlToOffscreen().getContext('2d', {alpha: false});
        // if ((this._ctx !== undefined) && (this._ctx !== null)){
        //   this._ctx.imageSmoothingEnabled = true;
        // }
      }

      if (this.avProd.commsStatus.ok) {

        this.avProd.webRtcConnectionPing(
          AvProdWebRtcConnectionType.viewer,
          this._deviceType,
          this._deviceId,
          undefined,
          this._webRtcAudioEnabled
        )
        //console.log('[ViewerWebRtcComponent] Web RTC Viewer Ping ' + this._deviceType + '/' + this._deviceId + ' ' + Date.now());

        if (this._deviceType === AvProdDeviceType.input) {
          // Video
          if (this.newFrameSubscription === undefined) {
            console.log('[ViewerWebRtcComponent] Subscribe to video frame ' + JSON.stringify(this.newFrameSubscription));
            if (this.avProd.webRtcInputs[this._deviceId].viewerManager !== undefined) {
              if (this.avProd.webRtcInputs[this._deviceId].viewerManager.video.frame$ !== undefined) {
                this.newFrameSubscription = this.avProd.webRtcInputs[this._deviceId].viewerManager?.video.frame$?.subscribe(($event) => {
                  this.receiveNewFrame($event)
                });
              }
            }
          }
          // Audio
          if (this._webRtcAudioEnabled) {
            if (this.newAudioSubscription === undefined) {
              console.log('[ViewerWebRtcComponent] Subscribe to audio frame ' + JSON.stringify(this.newAudioSubscription));
              if (this.avProd.webRtcInputs[this._deviceId].viewerManager !== undefined) {
                if (this.avProd.webRtcInputs[this._deviceId].viewerManager.audio.data$ !== undefined) {
                  this.newAudioSubscription = this.avProd.webRtcInputs[this._deviceId].viewerManager?.audio.data$?.subscribe(($event) => {
                    this.receiveNewAudio($event)
                  });
                }
              }
            }
            this.avProd.webRtcConnectionAudioCancel(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.output, this._deviceId, this.avProd.mediaStreamId);
          } else {
            if (this.newAudioSubscription !== undefined) {
              this.newAudioSubscription.unsubscribe();
              this.newAudioSubscription = undefined;
            }
          }
        } else if (this._deviceType === AvProdDeviceType.output) {
          // Video
          if (this.newFrameSubscription === undefined) {
            console.log('[ViewerWebRtcComponent] Subscribe to video frame ' + JSON.stringify(this.newFrameSubscription));
            if (this.avProd.webRtcOutputs[this._deviceId].viewerManager !== undefined) {
              if (this.avProd.webRtcOutputs[this._deviceId].viewerManager.video.frame$ !== undefined) {
                this.newFrameSubscription = this.avProd.webRtcOutputs[this._deviceId].viewerManager?.video.frame$?.subscribe(($event) => {
                  this.receiveNewFrame($event)
                });
              }
            }
          }
          // Audio
          if (this._webRtcAudioEnabled) {
            if (this.newAudioSubscription === undefined) {
              console.log('[ViewerWebRtcComponent] Subscribe to audio frame ' + JSON.stringify(this.newAudioSubscription));
              if (this.avProd.webRtcOutputs[this._deviceId].viewerManager !== undefined) {
                if (this.avProd.webRtcOutputs[this._deviceId].viewerManager.audio.data$ !== undefined) {
                  this.newAudioSubscription = this.avProd.webRtcOutputs[this._deviceId].viewerManager?.audio.data$?.subscribe(($event) => {
                    this.receiveNewAudio($event)
                  });
                }
              }
            }
            this.avProd.webRtcConnectionAudioCancel(AvProdWebRtcConnectionType.viewer, AvProdDeviceType.output, this._deviceId, this.avProd.mediaStreamId);
          } else {
            if (this.newAudioSubscription !== undefined) {
              this.newAudioSubscription.unsubscribe();
              this.newAudioSubscription = undefined;
            }
          }
        }
      }
    }
  }

  protected onDrop(event: DragEvent) {
    if (event.dataTransfer !== null) {
      const ITEM_TYPE: string = event.dataTransfer.getData('itemType');
      const ITEM_ID: number = parseInt(event.dataTransfer.getData('itemId'));
      console.log('[ViewerWebRtcComponent] onDrop ' + ITEM_TYPE + '/' + ITEM_ID);
      if (ITEM_TYPE === 'input'){
        if ((event.target === this.canvasElement)&&
            (this.canvasElement !== undefined)) {
          const X: number = 100.0 * (event.offsetX) / this.canvasElement.clientWidth;
          const Y: number = 100.0 * (event.offsetY) / this.canvasElement.clientHeight;
          const INFO: IAvProdInputDropInfo = {
            inputId: ITEM_ID,
            x: X,
            y: Y
          }
          this.emitDropItemInput(INFO);
        }
      }
    }
  }

  protected allowDrop(event: DragEvent) {
    // Only allow drop for output viewer
    if (this._deviceType === AvProdDeviceType.output) {
      event.preventDefault();
    }
  }

  protected onMouseDown(event: MouseEvent) {
    if ((this._deviceType === AvProdDeviceType.output) &&
      ((this.canvasElement !== undefined))) {
      this.mouseMoveStartX = 100 * event.offsetX / this.canvasElement.clientWidth;
      this.mouseMoveStartY = 100 * event.offsetY / this.canvasElement.clientHeight;
      this.mouseDragging = true;
    }
  }

  protected onTouchStart(event: TouchEvent) {
    if ((this._deviceType === AvProdDeviceType.output) &&
      (event.touches.length === 1) &&
      (this.canvasElement !== undefined)) {
      this.mouseMoveStartX = 100 * event.touches[0].clientX / this.canvasElement.clientWidth;
      this.mouseMoveStartY = 100 * event.touches[0].clientY / this.canvasElement.clientHeight;
      this.mouseDragging = true;
    }
  }

  protected onMouseUp(event: MouseEvent) {
    if ((this._deviceType === AvProdDeviceType.output) &&
      ((this.canvasElement !== undefined))) {
      this.mouseMoveEndX = 100 * event.offsetX / this.canvasElement.clientWidth;
      this.mouseMoveEndY = 100 * event.offsetY / this.canvasElement.clientHeight;
      const INFO: IAvProdInputTileSwapInfo = {
        x1: this.mouseMoveStartX,
        y1: this.mouseMoveStartY,
        x2: this.mouseMoveEndX,
        y2: this.mouseMoveEndY
      }
      this.mouseDragging = false;
      this.avProd.azzChangeVideoLayoutTileSwap(INFO);
    }
  }

  protected onTouchEnd(event: TouchEvent) {
    if ((this._deviceType === AvProdDeviceType.output) &&
      (event.touches.length === 1) &&
      (this.canvasElement !== undefined)) {
      this.mouseMoveEndX = 100 * event.touches[0].clientX / this.canvasElement.clientWidth;
      this.mouseMoveEndY = 100 * event.touches[0].clientY / this.canvasElement.clientHeight;
      const INFO: IAvProdInputTileSwapInfo = {
        x1: this.mouseMoveStartX,
        y1: this.mouseMoveStartY,
        x2: this.mouseMoveEndX,
        y2: this.mouseMoveEndY
      }
      this.mouseDragging = false;
      this.avProd.azzChangeVideoLayoutTileSwap(INFO);
    }
  }

  protected setShowControls(controls : boolean){
    if (this._showControls !== controls) this._showControls = controls;
  }

  protected updateAudioInfo(){
    if (this.audio.getEnabled() === true){
      this.audioIcon = 'bi-volume-up';
    }
    else {
      this.audioIcon = 'bi-volume-mute';
    }
  }

  protected onClickAudioToggle(){
    let enabled: boolean = !this.audio.getEnabled();
    this.audio.changeEnabled(enabled);
    this.updateAudioInfo();
  }
}
