// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const ENV_COMMON = {
  production: false,
  scheme: 'https://',
  domain: 'dev.azzulei.tv',
  api: 'https://dev-api.azzulei.com/v3',
  web: 'https://dev.azzulei.tv/',
  downloadUrl: 'https://dev-download.azzulei.tv/',
  uploadUrl: 'https://dev-upload.azzulei.tv/',
  playlist: 'dev-play.azzulei.tv',
  googleSsoClientId: {
    web: '492270698470-dnuhqj9d1lbs1tisirdlniuh81f99i82.apps.googleusercontent.com',
    android: [
      '492270698470-accom7ma27e2d6fucnse0cst9oo1ln63.apps.googleusercontent.com',
      '492270698470-fj1ofb9qmannojrqcgivthrpo41doucj.apps.googleusercontent.com'
    ],
    ios: '492270698470-oars8ig19310te095muq947pvrm9ti71.apps.googleusercontent.com',
    iosURLScheme: 'com.googleusercontent.apps.492270698470-oars8ig19310te095muq947pvrm9ti71'
  },
  appleSsoClientId: {
    bundle: 'com.azzulei.tv'
  },
  enableHotjarTracking: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
