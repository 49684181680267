import { Subscription, timer } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { ModalDialogId } from '../../enums/utils.enum';
import { IAlertData, IModalDialogData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvProdInterfaceClientDeviceStatus } from '../../interfaces/input/input-item.interface';
import { IAvProdVideoLayout } from '../../interfaces/av-producer/layoutmanager-settings.interface';
import { IAvProdCommsStatus, IAvProdInputDropInfo } from '../../interfaces/av-producer/event-av-producer.interface';
import { IEvent } from '../../interfaces/events/event.interface';
import { AvProdPublishSourceType } from '../../const/publish-stream';
import { EVENTS } from '../../const/events.const';
import {
  AvProdClientType,
  AvProdConnectionStatus,
  AvProdDeviceType,
  AvProdInputPlayingState,
  AvProdInputTypeNumber
} from '../../const/av-producer.const';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { PublishStreamService } from '../../services/publish-stream/publish-stream.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';


export class ProducerPageClass {
  protected typeOutput: AvProdDeviceType = AvProdDeviceType.output;
  protected typeInput: AvProdDeviceType = AvProdDeviceType.input;
  protected showPublisherInit: boolean = false;
  protected useCameraBroadcaster: boolean = true;
  protected addInputResourceType: AvProdInputTypeNumber = AvProdInputTypeNumber.imageTile;

  protected event: IEvent | undefined;
  protected userInitializedRetries: number = 0;
  protected avProdCommsOk: boolean = false;
  protected connectionStatus: AvProdConnectionStatus = AvProdConnectionStatus.none;
  protected connectionStatusText: string = '';
  protected avProdConnectionStatus = AvProdConnectionStatus;

  protected clientStatusInfo: IAvProdInterfaceClientDeviceStatus | undefined;

//  protected broadcastSlotIdCam: string = 'ProducerBroadcastCam1';
//  protected broadcastSlotIds: string[] = [];
  protected broadcastCameraCount: number = 1;
  protected broadcastScreenAllowed: boolean = false;

  protected localMediaSelectedSlot: string = '';
  protected reminderTimerInterval: number = 180000;
  protected reminderInputCancel: boolean = false;
  protected reminderOnAirCancel: boolean = false;

  protected avCommsStatusSubscription: Subscription | undefined;
  protected avCommsTooManyClientsSubscription: Subscription | undefined;
  protected userCheckTimerSubscription: Subscription | undefined;
  protected mediaEnabledSubscription: Subscription | undefined;
  protected modalServiceSubscription: Subscription | undefined;
  protected reminderTimerSubscription: Subscription | undefined;

  protected toolboxConfigLeft: boolean[] = [true, true, false, true, false, false, true, false, false];
  protected toolboxConfigRight: boolean[] = [false, false, true, false, true, false, false, true, true];
  protected toolboxConfigMobile: boolean[] = [true, true, true, true, true, false, true, true, true];
  protected isToolboxOpen: boolean = true;

  protected toolbarConfigTop: boolean[] = [true, false, true, false, true, false];
  protected toolbarConfigBottom: boolean[] = [false, true, false, true, false, true];
  protected toolbarConfigFull: boolean[] = [true, true, true, true, true, true];

  protected tileSelectionShow: boolean = false;
  protected tileSelectionInputId: number = 0;

  protected layoutSelectionShow: boolean = false;
  protected layoutSelectionLayoutId: number = 1;
  protected layoutSelectionTileId: number = 1;
  protected layoutSelectionContent: number[] = [];

  protected modalDialogId = ModalDialogId;
  protected modalDataCurrent: IModalDialogData = {id: ModalDialogId.none, title: ''};
//  protected modalIndex: number = -1;
//  protected modalTitle: string = '';
//  protected modalInputSettingsId: number = -1;

  protected inputCount: number = 0;

  private _token: string = '';

  constructor(protected activatedRoute: ActivatedRoute,
              protected eventsService: EventsService,
              protected userService: UserService,
              protected avProd: AvProducerService,
              protected router: Router,
              protected publishService: PublishStreamService,
              protected modalService: ModalManagerService) {
    // do nothing
  }

  set token(token: string) {
    this._token = token;
  }

  get token(): string {
    return this._token;
  }

  protected initialize(token: string, screenShare: boolean, localCameraSlots: number): void {
    this.token = token;
    if (this.token !== null) {
      this.checkToken();
    }

    if (this.modalServiceSubscription !== undefined) this.modalServiceSubscription.unsubscribe();
    this.modalServiceSubscription = this.modalService.modalManagerEvent$.subscribe(value => {
      this.onModalNewData(value);
    });

    if (this.mediaEnabledSubscription !== undefined) this.mediaEnabledSubscription.unsubscribe();
    this.mediaEnabledSubscription = this.publishService.userMediaEnabled.subscribe(value => {
      this.onMediaEnabled(value)
    });

    this.broadcastCameraCount = localCameraSlots;
    this.broadcastScreenAllowed = screenShare;

    // Start a timer to check reminders (live inputs and onAir)
    this.reminderTimerSubscription = timer(30000).subscribe(() => this.tickCheckReminder());

    // Open local camera modal when entering Producer section (to be decided)
    //this.onInputAdd(1);
  }

  protected destroy(): void {
    this.reminderTimerSubscription?.unsubscribe();
    if (this.userCheckTimerSubscription !== undefined) this.userCheckTimerSubscription.unsubscribe();
    if (this.avCommsStatusSubscription !== undefined) this.avCommsStatusSubscription.unsubscribe();
    if (this.avCommsTooManyClientsSubscription !== undefined) this.avCommsTooManyClientsSubscription.unsubscribe();
    if (this.mediaEnabledSubscription !== undefined) this.mediaEnabledSubscription.unsubscribe();
    if (this.modalServiceSubscription !== undefined) this.modalServiceSubscription.unsubscribe();

    // Close and delete publish broadcast slots
    this.publishService.deleteStreamSlot('Camera1');
    this.publishService.deleteStreamSlot('Camera2');
    this.publishService.deleteStreamSlot('Camera3');
    this.publishService.deleteStreamSlot('Screen1');
    this.avProd.destroy();
    this.publishService.destroy();
  }

  protected hideModal(): void {
    //must be overridden
  }

  protected showModal(): void {
    //must be overridden
  }

  protected exitPage(): void {
    //must be overridden
  }

  protected onOpenConfig(): void {
    //must be overridden
  }

  protected showLogin(): void {
    // must be overridden
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected displayAlert(data: IAlertData): void {
    // must be overridden
  }

  protected setToolboxOpen(value: boolean): void {
    this.isToolboxOpen = value;
  }

  protected setInputCount(value: number): void {
    this.inputCount = value;
  }

  protected onModalNewData(data: IModalDialogData) {
    this.modalDataCurrent = data;
    if ((data.id === this.modalDialogId.producerInputAddResource) ||
      (data.id === this.modalDialogId.producerInputAddResourceUpload)) {
      if (this.modalDataCurrent.subId !== undefined) {
        this.addInputResourceType = this.modalDataCurrent.subId;
      }
    }
    switch (data.id) {
      case ModalDialogId.none:
        this.hideModal();
        break;
      default:
        this.showModal();
        break;
    }
  }

  protected closeModalCurrent(): void {
    this.modalService.closeCurrent();
  }

  protected closeModalAll(): void {
    this.modalService.closeAll();
  }

  protected checkToken(): void {

    let needToLogInRedirect: boolean = false;
    let tokenError: boolean = false;

    if (this.userService.isInitialized) {
      console.log('[ProducerPageClass] checkToken');

      if (!this.userService.anonymousUser) {
        try {
          this.eventsService.getEventInfo('producer', this.token)
            .pipe()
            .subscribe(event => {
              if (event !== null) {
                let eventReady: boolean = false;

                this.event = event;
                //this.event.producerToken = this.token;
                // Update active event in EventsService
                this.eventsService.currentEvent = this.event;
                console.log('[ProducerPage] Init Event info ' + JSON.stringify(this.event));

                // Check event status
                if ((this.event?.status === EVENTS.status.creating)||
                    (this.event?.status?.includes(EVENTS.status.installing) === true)||
                    (this.event?.status === EVENTS.status.pending)||
                    (this.event?.status === EVENTS.status.scheduled)) {
                  this.connectionStatus = this.avProdConnectionStatus.eventNotReady;
                  this.connectionStatusText = 'producer.msgOverEventNotReady';
                }
                else if ((this.event?.status !== undefined)&&
                         (this.event?.status !== EVENTS.status.running)) {
                  this.connectionStatus = this.avProdConnectionStatus.eventFinished;
                  this.connectionStatusText = 'producer.msgOverEventFinished';
                }
                else if (this.event?.status !== undefined) {
                  eventReady = true;
                }

                if (eventReady){
                  this.avProd.init();
                  // Initialize available client information
                  this.avProd.clientInfo.token = this.token;
                  this.avProd.clientInfo.clientType = AvProdClientType.producer;
                  // open comms with avProducer
                  this.avProd.openComms(this.event);
                  this.avProd.changeAudioLevelActive(true);
                  //this.avProd.addVideoActive(AV_PROD_FRAME_ID_OUTPUT_OFFSET);
                  //for (let i:number = 0; i<4; i++) {
                  //  this.avProd.addVideoActive(i);
                  //}
                  this.avCommsStatusSubscription?.unsubscribe();
                  this.avCommsStatusSubscription = this.avProd.onCommsStatusChange$.subscribe(data => {
                    this.receiveCommsStatusChange(data)
                  });
                  this.avCommsTooManyClientsSubscription?.unsubscribe();
                  this.avCommsTooManyClientsSubscription = this.avProd.onCommsTooManyClientsChange$.subscribe(data => {
                    this.receiveCommsTooManyClientsChange(data)
                  });

                  this.publishService.init(this.event?.viewerToken)
                  .then(() =>
                  {
                    // Initialize publish broadcast slots
                    for (let i:number=0; i<this.broadcastCameraCount; i++){
                      this.publishService.createStreamSlot('Camera' + (i+1), AvProdPublishSourceType.device);
                    }

                    if (this.broadcastScreenAllowed) {
                      this.publishService.createStreamSlot('Screen1', AvProdPublishSourceType.screen);
                    }
                  })
                  .catch(console.error);
                }
                else {
                  timer(3000).subscribe(() => this.checkToken());
                }
              } else {
                // Display error
                this.showError('general.errorTokenNotValid');
                this.exitPage();
              }
            });
        } catch (error) {
          console.log('[ProducerPageClass] checkToken Catch error: ' + JSON.stringify(error));
          tokenError = true;
        }
      } else {
        needToLogInRedirect = true;
      }
    } else {

      // Users service not initialized yet
      if (this.userInitializedRetries < 30) {
        // Wait for initialization
        this.userInitializedRetries++;
        console.log('[ProducerPage] User service not initialized. Wait and try');
        this.userCheckTimerSubscription = timer(500).subscribe(() => {
          this.checkToken();
        });

      } else {
        needToLogInRedirect = true;
      }
    }

    if (needToLogInRedirect || tokenError) {
      let msgDetail: string;
      if (this.userService.anonymousUser) {
        msgDetail = 'general.errorTokenNotValidAnonymous';
      } else {
        msgDetail = 'general.errorTokenNotValid';
      }
      console.error('[ProducerPageClass] showErrorToken: ' + msgDetail);
      this.showError(msgDetail);
      console.log('[ProducerPageClass] checkToken Need Login');
      this.showLogin();
      this.exitPage();
    }
  }

  private showError(detail: string) {
    this.displayToast({
      options: {
        placement: ToastPlacement.topCenter,
      },
      data: {
        status: ToastStatus.error,
        text: detail,
        closeButtonBody: true,
      },
    });
  }

  private onMediaEnabled(enabled: boolean): void {
    if (enabled) {
      //this.createBroadcasters();
    }
  }

  // private updateBroadcasters(): void {
  //   if (this.useCameraBroadcaster) {
  //     this.broadcastSlotIds = [];
  //     this.broadcastSlotIds.push(this.broadcastSlotIdCam);
  //     this.publishService.createStreamSlot(this.broadcastSlotIdCam, AvProdPublishSourceType.device);
  //   } else {
  //     this.broadcastSlotIds.forEach(element => {
  //       this.publishService.deleteStreamSlot(element);
  //     });
  //     this.broadcastSlotIds = [];
  //   }
  // }

  private receiveCommsTooManyClientsChange(data: boolean): void {
    console.log('[ProducerPageClass] receiveCommsTooManyClientsChange ' + JSON.stringify(this.avProd.commsStatus));
    if (data) {
      this.connectionStatus = this.avProdConnectionStatus.tooManyClients;
      this.connectionStatusText = 'producer.msgOverTooManyClientsStudio';
      /*
      this.displayToast({
        options: {
          autohide: false,
          placement: ToastPlacement.middleCenter
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.error,
          title: 'producer.tooManyClientsTitle',
          text: 'producer.tooManyClientsDetail',
          alignText: 'text-center',
          buttons: []
        },
      });
      */
      console.log('[ProducerPageClass] receiveCommsTooManyClientsChange Error ' + data);
    }
  }

  private receiveCommsStatusChange(data: IAvProdCommsStatus): void {
    console.log('[ProducerPage] receiveCommsStatusChange ' + JSON.stringify(data));
    if (this.avProdCommsOk !== this.avProd.commsStatus.ok){
      this.avProdCommsOk = this.avProd.commsStatus.ok;
      if (!this.avProdCommsOk) {
        if ((this.connectionStatus === this.avProdConnectionStatus.ok)||
            (this.connectionStatus === this.avProdConnectionStatus.none)){
          this.connectionStatus = this.avProdConnectionStatus.commsError;
          this.connectionStatusText = 'producer.msgOverCommsError';
        }
        /*
        this.displayToast({
          options: {
            autohide: true,
            placement: ToastPlacement.middleCenter
          },
          data: {
            closeButtonHeader: true,
            status: ToastStatus.error,
            title: 'general.error',
            text: 'general.errorCommsServerWs',
            alignText: 'text-center',
            buttons: []
          },
        });
        */
        this.requestEventInfo();
      }
      else {
        if (this.event?.status === EVENTS.status.running){
          if (this.connectionStatus !== this.avProdConnectionStatus.tooManyClients){
            this.connectionStatus = this.avProdConnectionStatus.ok;
            this.connectionStatusText = '';
          }
        }
      }
    }
  }

  private requestEventInfo(): void {
    // Do not request info from API. retrieve from avProducer WS status
    if (this.avProd.commsStatus.ok && (this.event !== undefined)) {
      this.event.producerToken = this.avProd.serverStatus.event.producerToken;
      this.event.publisherToken = this.avProd.serverStatus.event.publisherToken;
      this.event.viewerToken = this.avProd.serverStatus.event.viewerToken;
      this.event.status = this.avProd.serverStatus.event.status.toLowerCase();
      this.event.started = this.avProd.serverStatus.event.streamStarted;
      this.event.currentViewers = this.avProd.serverStatus.viewers;
      this.event.totalViewers = this.avProd.serverStatus.viewersTotal;
      //this.requestEventInfo();
    } else {
      this.eventsService.getEventInfo('producer', this.token)
        .pipe()
        .subscribe(event => {
          if (event) {
            this.event = event;
            this.event.producerToken = this.token;
            // Update active event in EventsService
            this.eventsService.currentEvent = this.event;
            console.log('[ProducerPageClass] Event info ' + JSON.stringify(this.event));
            this.checkEventStatus();
          }
        });
    }

    // Check event status
    if ((this.event?.status === EVENTS.status.creating)||
        (this.event?.status === EVENTS.status.installing)||
        (this.event?.status === EVENTS.status.pending)||
        (this.event?.status === EVENTS.status.scheduled)) {
      this.connectionStatus = this.avProdConnectionStatus.eventNotReady;
      this.connectionStatusText = 'producer.msgOverEventNotReady';
      timer(3000).subscribe(() => this.requestEventInfo);
    }
    else if (this.event?.status !== EVENTS.status.running) {
      this.connectionStatus = this.avProdConnectionStatus.eventFinished;
      this.connectionStatusText = 'producer.msgOverEventFinished';
    }
    else if ((this.event?.status !== undefined)&&
              (this.avProdCommsOk)) {
      this.connectionStatus = this.avProdConnectionStatus.ok;
      this.connectionStatusText = '';
    }
  }

  protected checkEventStatus() {
    if (this.event !== undefined) {
      if ((this.event.status === EVENTS.status.finishing) ||
        (this.event.status === EVENTS.status.finished) ||
        (this.event.status === EVENTS.status.archived)) {

        // The event has finished. Show message and navigate to Home
        this.displayToast({
          options: {
            autohide: true,
            placement: ToastPlacement.middleCenter
          },
          data: {
            closeButtonHeader: true,
            status: ToastStatus.info,
            title: 'producer.eventFinishedInformationTitle',
            text: 'producer.eventFinishedInformation',
            alignText: 'text-center',
            buttons: []
          },
        });

        // Exit producer page
        this.exitPage();
      }
    }
  }

  protected onInputSelect(id: number): void {
    console.log('[ProducerPageClass] onInputSelect ' + id);
    if (this.layoutSelectionShow) {
      const LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => (element.id === this.layoutSelectionLayoutId));
      if (LAYOUT !== undefined) {
        this.layoutSelectionContent.push(id);
        if (this.layoutSelectionContent.length >= LAYOUT.tiles.length) {
          if (this.avProd.composerSettings.videoSelection !== undefined) {
            this.avProd.azzChangeVideoLayoutSettings(this.layoutSelectionLayoutId, this.layoutSelectionContent);
          }
          this.layoutSelectionShow = false;
          this.layoutSelectionContent = [];
        } else {
          this.layoutSelectionTileId++;
        }
      } else {
        this.layoutSelectionShow = false;
      }
    } else {
      const LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => (element.id === this.avProd.composerSettings.layoutId));
      if ((LAYOUT !== undefined) &&
        (LAYOUT.tiles.length === 1)) {
        this.layoutSelectionContent = [];
        this.layoutSelectionContent.push(id);
        this.avProd.azzChangeVideoLayoutSettings(LAYOUT.id, this.layoutSelectionContent);
      } else {
        this.tileSelectionInputId = id;
        this.tileSelectionShow = true;
      }
    }
  }

  protected onInputTileCancel(): void {
    this.tileSelectionShow = false;
  }

  protected onInputTileSelect(id: number): void {
    //console.log('[ProducerPageClass] onInputTileSelect: ' + id);
    this.tileSelectionShow = false;
    const LAYOUT_ID: number | undefined = this.avProd.composerSettings.layoutId;
    const NEW_SELECTION: number[] | undefined = this.avProd.composerSettings.videoSelection;
    if ((LAYOUT_ID !== undefined) && (NEW_SELECTION !== undefined)) {
      NEW_SELECTION[id - 1] = this.tileSelectionInputId;
      this.avProd.azzChangeVideoLayoutSettings(LAYOUT_ID, NEW_SELECTION);
    }
  }

  protected selectLayout(id: number): void {
    //console.log('[ProducerPageClass] selectLayout: ' + JSON.stringify(event));
    if (!this.layoutSelectionShow && !this.tileSelectionShow) {
      this.layoutSelectionLayoutId = id;
      this.layoutSelectionTileId = 1;
      this.layoutSelectionContent = [];
      this.layoutSelectionShow = true;
    }
  }

  protected onLayoutSelectCancel(): void {
    this.layoutSelectionShow = false;
    this.layoutSelectionContent = [];
  }

  protected onInputDrop(event: IAvProdInputDropInfo): void {
    this.avProd.azzChangeVideoLayoutDrop(event);
  }

  // protected onStartBroadcast(): void {
  //   this.showPublisherInit = false;
  //   this.modalService.closeAll();
  //   //this.modalIndex = 0;
  //   if (this.useCameraBroadcaster) {
  //     this.updateBroadcasters();
  //   } else {
  //     this.publishService.deleteStreamSlot(this.broadcastSlotIdCam);
  //   }
  // }

  // protected onChangeUseCameraBroadcaster(): void {
  //   console.log('[ProducerPageClass] onChangeUseCameraBroadcaster: ' + this.useCameraBroadcaster);
  //   this.updateBroadcasters();
  // }

  protected onInputSettings(inputId: number): void {
    console.log('[ProducerPageClass] onInputSettings: ' + inputId);
    this.modalService.show(
      {
        id: ModalDialogId.producerInputSettings,
        subId: inputId,
        title: 'producer.inputSettings'
      },
      true
    )
    //this.modalInputSettingsId = inputId;
    //this.modalSelect(4, 'producer.inputSettings');
  }

  protected onInputAdd(value: number) {
    console.log('[ProducerPageClass] onInputAdd: ' + value);
    switch (value) {
      case -1:
        // No modal (close all)
        this.modalService.closeAll();
        break;
      case 0:
        // ModalDialogId.producerInputAdd
        this.modalService.show({
          id: ModalDialogId.producerInputAdd,
          subId: 0,
          title: 'inputAdd.selectInputType'
        }, true);
        //this.modalSelect(0, 'inputAdd.selectInputType');
        break;
      case 1:
        // ModalDialogId.producerInputAddLocal Input add local
        //this.showPublisherInit = true;
        //this.modalService.closeAll();
        this.modalService.show({
          id: ModalDialogId.producerInputAddLocal,
          subId: 0,
          size: 'lg',
          title: 'inputAdd.localLiveInput'
        }, true);
        break;
      case 2:
        // ModalDialogId.producerInputAddLive
        this.modalService.show({
          id: ModalDialogId.producerInputAddLive,
          subId: 0,
          title: 'inputAdd.remoteLiveInput'
        }, true);
        //this.modalSelect(2, 'inputAdd.remoteLiveInput');
        break;
      case 3:
        // ModalDialogId.producerInputAddScreen Input add screen
        this.modalService.show({
          id: ModalDialogId.producerInputAddScreen,
          subId: 0,
          size: 'lg',
          title: 'inputAdd.localScreenShare'
        }, true);
        break;
      case 4:
        // ModalDialogId.producerInputAddResource VideoClip
        this.addInputResourceType = AvProdInputTypeNumber.videoAudioClip;
        this.modalService.show({
          id: ModalDialogId.producerInputAddResource,
          subId: AvProdInputTypeNumber.videoAudioClip,
          title: 'inputAdd.resourceManagement'
        }, true);
        //this.modalSelect(3, 'inputAdd.resourceManagement');
        break;
      case 5:
        // ModalDialogId.producerInputAddResource Image
        this.addInputResourceType = AvProdInputTypeNumber.imageTile;
        this.modalService.show({
          id: ModalDialogId.producerInputAddResource,
          subId: AvProdInputTypeNumber.imageTile,
          title: 'inputAdd.resourceManagement'
        }, true);
        //this.modalSelect(3, 'inputAdd.resourceManagement');
        break;
      case 6:
        // ModalDialogId.producerInputAddResource Document
        this.addInputResourceType = AvProdInputTypeNumber.document;
        this.modalService.show({
          id: ModalDialogId.producerInputAddResource,
          subId: AvProdInputTypeNumber.document,
          title: 'inputAdd.resourceManagement'
        }, true);
        //this.modalSelect(3, 'inputAdd.resourceManagement');
        break;
    }
  }

  protected openUpload(type: AvProdInputTypeNumber): void {
    this.addInputResourceType = type;
    if ((this.addInputResourceType === AvProdInputTypeNumber.videoAudioClip) ||
      (this.addInputResourceType === AvProdInputTypeNumber.imageTile) ||
      (this.addInputResourceType === AvProdInputTypeNumber.imageOverlay) ||
      (this.addInputResourceType === AvProdInputTypeNumber.document) ||
      (this.addInputResourceType === AvProdInputTypeNumber.audioClip)) {
      this.modalService.show({
        id: ModalDialogId.producerInputAddResourceUpload,
        title: 'inputAdd.resourceUpload'
      }, true);
    }
  }

  protected tickCheckReminder() {
    let keepChecking: boolean = true;
    console.log('[ProducerPageClass] tickCheckReminder');

    if (this.modalService.getModalCount() > 0 || this.connectionStatus !== this.avProdConnectionStatus.ok){
      this.reminderTimerSubscription?.unsubscribe();
      this.reminderTimerSubscription = timer(2000).subscribe(() => this.tickCheckReminder());
    }
    else{
      if (this.reminderInputCancel) {
        let liveInputs: boolean = false;
        for (let i:number=0; i<this.avProd.inputs.length; i++){
          if ((this.avProd.inputs[i].info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream)||
              (this.avProd.inputs[i].info.inputTypeNumber === AvProdInputTypeNumber.videoStream)||
              (this.avProd.inputs[i].info.inputTypeNumber === AvProdInputTypeNumber.audioStream)) {

            if (this.avProd.inputs[i].status?.playingState === AvProdInputPlayingState.playing) {
              liveInputs = true;
              break;
            }
          }
        }

        if (liveInputs){
          this.displayAlert({
            show: true,
            status: AlertStatus.question,
            title: 'producer.addLiveInput',
            text: 'producer.addLiveInputQuestion',
            closeButton: true,
            buttons: [{
              text: 'producer.addLiveInputLocal',
              color: 'primary',
              fill: 'outline',
              closeButton: true,
              handler: (): void => {
                this.onInputAdd(1);
              }
            },
            {
              text: 'producer.addLiveInputRemote',
              color: 'primary',
              fill: 'outline',
              closeButton: true,
              handler: (): void => {
                this.onInputAdd(2);
              }
            }]
          });
          this.reminderInputCancel = true;
          keepChecking = false;
        }
      }

      if ((keepChecking) && (this.reminderOnAirCancel)){
        if (this.avProd.outputSettings.onAir === 0){

          this.displayAlert({
            show: true,
            status: AlertStatus.warning,
            title: 'producer.goLive',
            text: 'producer.goLiveReminder',
            closeButton: true,
            buttons: [{
              text: 'producer.remindMeLater',
              color: 'secondary',
              closeButton: true
            },
            {
              text: 'producer.remindMeLaterNot',
              fill: 'outline',
              color: 'primary',
              closeButton: true,
              handler: (): void => {
                this.reminderOnAirCancel = true;
              }
            }]
          });
          /*
          this.displayToast({
            options: {
              placement: ToastPlacement.topCenter
            },
            data: {
              status: ToastStatus.warning,
              text: 'producer.goLiveReminder',
              closeButtonBody: true,
              buttons: [
              {
                text: 'producer.remindMeLater',
                color: 'primary',
                closeButton: true
              },
              {
                text: 'producer.remindMeLaterNot',
                color: 'primary',
                closeButton: true,
                handler: (): void => {
                  this.reminderOnAirCancel = true;
                }
              }]
            },
          });
          */
        }
      }

      if (!this.reminderInputCancel || !this.reminderOnAirCancel){
        this.reminderTimerSubscription?.unsubscribe();
        this.reminderTimerSubscription = timer(this.reminderTimerInterval).subscribe(() => this.tickCheckReminder());
      }
    }
  }
}
