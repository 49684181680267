export class PasswordClass {
  protected openedEyeIcon: string = 'bi bi-eye';
  protected closedEyeIcon: string = 'bi bi-eye-slash';
  protected passType: string[] = [];
  protected passIcon: string[] = [];

  constructor(private inputCount: number) {
    for(let i: number = 1; i <= inputCount; i++) {
      this.passType.push('password');
      this.passIcon.push( this.openedEyeIcon );
    }
  }
  protected togglePasswordInputType(inputIndex: number): void {
    this.passType[inputIndex] = this.passType[inputIndex] === 'text' ? 'password' : 'text';
    this.passIcon[inputIndex] = this.passIcon[inputIndex] === this.closedEyeIcon ? this.openedEyeIcon : this.closedEyeIcon;
  }
}
