import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { API, IContactData, IHttpResponse } from '@azz-life-streamer/shared';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {

  constructor(private http: HttpClient) { }

  /**
   * Sends contact form
   */
  public sendContactForm(data: IContactData): Observable<boolean> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.contact.form}`, data)
      .pipe(
        map((response: IHttpResponse) => this.handleContactResponse(response)),
        catchError((e) => throwError(() => e))
      );
  }

  private handleContactResponse(response: IHttpResponse): boolean {
    return response.error === 0;
  }

  /**
   * Sends contact form
   */
  public sendSupportEmail(data: IContactData): Observable<boolean> {
    return this.http.post<IHttpResponse>(`${API.urls.server}/${API.urls.actions.contact.form}`, data)
      .pipe(
        map((response: IHttpResponse) => this.handleContactResponse(response)),
        catchError((e) => throwError(() => e))
      );
  }

}
