import { NgForm } from '@angular/forms';
import { tap } from 'rxjs';

import { IUpdateUser }  from '../../interfaces/user/user.interface';
import { PasswordClass } from './password.class';
import { UserService } from '../../services/user/user.service';


export class EditProfileClass extends PasswordClass {

  protected updNameButtonDisabled: boolean | null = null;
  protected updPassButtonDisabled: boolean | null = null;

  protected error: boolean = false;
  protected errorTitle: string = '';
  protected errorMessage: string = '';
  protected _layoutMode: string = 'panel'; //'full', 'panel'
  protected userData: IUpdateUser = {
    name: '',
    curPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

   constructor(protected userService: UserService) {
     super(3);
   }

  protected updateName(form: NgForm): void {
    this.updNameButtonDisabled = true;
    this.userData.curPassword = '';
    this.userData.newPassword = '';
    this.userData.confirmPassword = '';
    if (form.valid) {
      this.userService.update(this.userData)
        .pipe(tap(() => {

          this.updNameButtonDisabled = false
        }))
        .subscribe({
          next: this.handleUpdNameResponse.bind(this),
          error: this.handleUpdNameErrorResponse.bind(this)
        });
    }
  }

  protected updatePassword(form: NgForm): void {
    this.updPassButtonDisabled = true;
    if (form.valid) {
      this.userService.update(this.userData)
        .pipe(tap(() => this.updPassButtonDisabled = false))
        .subscribe({
          next: this.handleUpdPassResponse.bind(this),
          error: this.handleUpdPassErrorResponse.bind(this)
        });
    }
  }

  protected checkPassword(form: NgForm): void {
    if (form && this.userData.newPassword !== this.userData.confirmPassword) {
      form.control.get('confirmPassword')?.setErrors({'incorrect': true});
    }
  }

  protected showErrorMessage(): void {
    //must be overridden
  }

  protected setLayoutMode(layout : string): void{
      this._layoutMode = layout;
  }

  private handleUpdNameResponse(result: boolean): void {
    if(result) {
      this.error = false;
      this.errorTitle = 'users.dataSuccessfullyUpdatedTitle';
      this.errorMessage = 'users.dataSuccessfullyUpdated';
    } else {
      this.error = true;
      this.errorTitle = '';
      this.errorMessage = 'users.dataErrorUpdated';
    }
    this.showErrorMessage();
  }

  private handleUpdNameErrorResponse(): void {
    this.updNameButtonDisabled = false
    this.error = true;
    this.errorTitle = '';
    this.errorMessage = 'users.dataErrorUpdated';
    this.showErrorMessage();
  }

  private handleUpdPassResponse(result: boolean): void {
    if(result) {
      this.error = false;
      this.errorTitle = 'users.passwordUpdated';
      this.errorMessage = 'users.passwordSuccessfullyUpdated';
    } else {
      this.error = true;
      this.errorTitle = '';
      this.errorMessage = 'users.passwordErrorUpdated';
    }
    this.showErrorMessage();
  }

  private handleUpdPassErrorResponse(errorCode: any): void {
    this.updPassButtonDisabled = false
    let errorText = 'users.passwordErrorUpdated';
    switch (errorCode) {
      case 605:
        errorText = 'users.wrongCredentials';
        break;
    }
    this.error = true;
    this.errorTitle = '';
    this.errorMessage = errorText;
    this.showErrorMessage();
  }
}
