//import { environment } from '../../../environments/environment';

import { ENV_COMMON } from '@azz-life-streamer/environments';

export const PLAYER = {
  protocol: 'https://',
  playerDomain: 'azzulei.com',
  playlistDomain: ENV_COMMON.playlist,
  hlsPath: 'hls',
  hlsExt: 'm3u8',
  playPath: 'play',
  storageSection: 'playerWatch',
  devServer: {
    hlsUrl: 'https://dev-amd.azzulei.com/hls/654321.m3u8',
    playerDomain: 'azzulei.com',
    playerPath: 'live/dev?',
  },
};

