import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { COMMON } from '../../const/common.const';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private pAccessToken: string;
  private pRefreshToken: string;

  constructor() {
    this.pAccessToken = '';
    this.pRefreshToken = '';
  }

  get accessToken(): string {
    return this.pAccessToken;
  }

  set accessToken(token: string) {
    this.pAccessToken = token;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get refreshToken(): string {
    return this.pRefreshToken;
  }

  set refreshToken(token: string) {
    this.pRefreshToken = token;
  }

  /**
   * Returns true if device has stored accessToken or refreshToken
   */
  public autoAuthAvailable(): Observable<boolean> {
    const LOAD_ACCESS_TOKEN: Promise<boolean> = this.loadAccessToken();
    const LOAD_REFRESH_TOKEN: Promise<boolean> =  this.loadRefreshToken();

    return from(LOAD_ACCESS_TOKEN || LOAD_REFRESH_TOKEN);
  }

  public parseJwt() {
    const PAYLOAD: string = this.accessToken.split('.')[1];
    const BASE_64: string = PAYLOAD.replace(/-/g, '+').replace(/_/g, '/');
    const PAYLOAD_JSON: string = decodeURIComponent(window.atob(BASE_64)
      .split('')
      .map((chars: string) => '%' + ('00' + chars.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(PAYLOAD_JSON);
  }

  public authAllowed(accessToken: string, refreshToken: string): Promise<boolean> {
    return new Promise( (resolve) => {
      localStorage.setItem(COMMON.storageKeys.accessToken, accessToken);
      localStorage.setItem(COMMON.storageKeys.refreshToken, refreshToken);
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      resolve(true);
    });
  }

  public authDisallowed(): Promise<boolean> {
    return new Promise( (resolve) => {
      this.accessToken = '';
      this.refreshToken = '';
      localStorage.removeItem(COMMON.storageKeys.accessToken);
      localStorage.removeItem(COMMON.storageKeys.refreshToken);
      resolve(true);
    });
  }

  private loadAccessToken(): Promise<boolean> {
    return new Promise ( resolve => {
      if(localStorage.getItem(COMMON.storageKeys.accessToken)) {
        const TOKEN: string | null = localStorage.getItem(COMMON.storageKeys.accessToken);
        if (TOKEN !== null) {
          this.accessToken = TOKEN;
        }
      }
      if(this.accessToken) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  private loadRefreshToken(): Promise<boolean> {
    return new Promise ( resolve => {
      if(localStorage.getItem(COMMON.storageKeys.refreshToken)) {
        const TOKEN: string | null = localStorage.getItem(COMMON.storageKeys.refreshToken);
        if (TOKEN !== null) {
          this.refreshToken = TOKEN;
        }
      }
      if(this.refreshToken) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

}
