import { AvProdInputType } from '../const/av-producer.const';
import { AlertStatus, ToastPlacement, ToastStatus } from '../enums/common.enum';
import { ModalDialogId } from '../enums/utils.enum';
import { IEventAsset, IEventUserLastChanges } from '../interfaces/events/event.interface';
import { IAlertData, IToastConfig } from '../interfaces/utils/utils.interface';
import { EventsService } from '../services/events/events.service';
import { ModalManagerService } from '../services/modal/modal-manager.service';
import { Subscription } from 'rxjs';

export class ResourcesClass {

    protected assets: IEventAsset[] = [];
    protected assetsFiltered: IEventAsset[] = [];
    protected assetsReceived: boolean | undefined;
    protected tabSelected: number = 0;
    protected filterImage: boolean = true;
    protected filterImageOverlay: boolean = true;
    protected filterVideo: boolean = true;
    protected filterSound: boolean = true;
    protected filterDoc: boolean = true;
    protected allFilters: boolean = true;
  
    protected userChangesSubscription: Subscription | undefined;
    constructor(protected events: EventsService,
        protected modalService: ModalManagerService){
        // Do nothing
    } 

    protected init(): void {
        this.userChangesSubscription = this.events.userChanges$.subscribe(data => this.checkUserChanges(data));
        this.updateAssetList();
        this.events.addPollingClient('StoragePageClass');
      }
    
      protected destroy(){
        this.userChangesSubscription?.unsubscribe();
        this.events.removePollingClient('StoragePageClass');
      }
    
      protected displayAlert(data: IAlertData){
        // Must override
      }
      protected displayToast(config: IToastConfig){
        // Must override
      }
    
      protected checkUserChanges(changes: IEventUserLastChanges){
        if (changes.storage === true){
          this.updateAssetList();
        }
      }
    
      protected updateAssetList(){
        this.assetsReceived = false;
        this.events.getUserAssetList()
        .pipe()
        .subscribe(answer => {
          if (answer !== null){
            this.assets = answer;
            this.updateAssetFilter();
            this.assetsReceived = true;
          }
        });
      }
    
      protected selectAllFilter(){
        this.allFilters = !this.allFilters;
        this.filterImage = this.allFilters;
        this.filterImageOverlay = this.allFilters;
        this.filterVideo = this.allFilters;
        this.filterSound = this.allFilters;
        this.filterDoc = this.allFilters;
    
        this.updateAssetFilter();
      }
    
      protected updateAssetFilter(){
        this.assetsFiltered = this.assets.filter(element => 
            ((this.filterImage)&&(element.type === AvProdInputType.imageTile))||
            ((this.filterImageOverlay)&&(element.type === AvProdInputType.imageOverlay))||
            ((this.filterSound)&&(element.type === AvProdInputType.audioClip))||
            ((this.filterVideo)&&(element.type === AvProdInputType.videoAudioClip))||
            ((this.filterDoc)&&(element.type === AvProdInputType.document))
        );
        if(this.filterImage && this.filterImageOverlay && this.filterVideo && this.filterSound && this.filterDoc){
          this.allFilters = true;
        }
        else{
          this.allFilters = false;
        }
        console.log('[StoragePageClass] updateAssetFilter - Active Filters: ' + this.filterImage + this.filterImageOverlay + this.filterSound + this.filterVideo + this.filterDoc);
        console.log('[StoragePageClass] updateAssetFilter - Return Asset list: ' + JSON.stringify(this.assetsFiltered));
      }
    
      protected onUpload() {
        this.modalService.show(
          {
            id: ModalDialogId.managerResourceUpload,
            title: 'inputAdd.resourceUpload'
          },
          true
        );
      }
    
      protected onAssetSettings(id: number){
        console.log('[ResourcesClass] onAssetSettings: ' + id);
        this.modalService.show(
          {
            id: ModalDialogId.assetEdit,
            subId: id,
            title: 'manager.storage.assetSettings'
          },
          true
        );
      }

      protected onDeleteAsset(id: number){
    
        this.displayAlert({
          closeButton: true,
          show: true,
          status: AlertStatus.question,
          title: 'general.confirmation',
          text: 'storage.assetDeleteConfirmation',
          buttons: [{
              text: 'general.cancel',
              color: 'primary',
              fill: 'outline',
              closeButton: true
            },
            {
              text: 'general.confirm',
              color: 'danger',
              closeButton: true,
              handler: (): void => {
                this.events.deleteUserAsset(id)
                  .pipe()
                  .subscribe(answer => {
                    if (answer === true){
                      this.updateAssetList();
                    }
                    else{
                      this.displayToast({
                        options: {
                          placement: ToastPlacement.topCenter,
                        },
                        data: {
                          status: ToastStatus.error,
                          text: 'storage.errorDelete',
                          closeButtonBody: true,
                        },
                      });      
                    }
                });
              }
            }]
        });
      }
}