import { ElementRef, inject, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  timer
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Hls from 'hls.js';

import { ENV_COMMON, ENV_WEB } from '@azz-life-streamer/environments';
import { JsonSegmentPlayer, LocalStorageTokenList } from '../../interfaces/player/json-segment-player';
import {
  IPlayerAudioCommon,
  IPlayerControls,
  IPlayerData,
  IPlayerHlsClientInfo
} from '../../interfaces/player/player.interface';
import { IPassword } from '../../interfaces/player/password.interface';
import { ISegment, IIntervals } from '../../interfaces/player/segment.interface';
import { PLAYER } from '../../const/player.const';
import { FormatHoursTimePipe } from '../../pipes/format-hours-time/format-hours-time.pipe';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertStatus, PlayerModals } from '../../enums/common.enum';
import { IAlertData } from '../../interfaces/utils/utils.interface';
import { DeviceService } from '../../services/device/device.service';

declare let window: any;
export class PlayerClass {
  protected _containerElement: ElementRef<HTMLDivElement> | undefined;
  protected _videoElement: ElementRef | undefined;
  protected _mode: 'vod' | 'live' = 'vod';

  protected player: HTMLMediaElement | null = null;
  protected videoPlayer: HTMLVideoElement | null = null;
  protected hlsPlayer: Hls | null = new Hls();

  //urlPlayerDomain: string = PLAYER.protocol + PLAYER.playerPath + '/';
  protected urlPlayerDomain: string = PLAYER.protocol + PLAYER.playlistDomain + '/';
  /*protected idPlayer: string | null = null;*/

  protected displayModalPage: PlayerModals = PlayerModals.none;
  protected displayModalTitle: string = '';
  protected passwordMsgText: string = '';
  protected currentTimeFormatted: string = '--:--';
  protected currentTime: number = 0;
  protected progressValue: number = 100;
  protected progressValueSubject: Subject<number> = new Subject<number>();
  protected progressValueSubjectSubscription: Subscription = new Subscription();
  protected playerControlsAudioCommon: IPlayerAudioCommon = {
    isMuted: true,
    volume: 0,
    currentVolume: 100
  }
  protected playerControls: IPlayerControls = {
    isPaused: false,
    isMuted: this.playerControlsAudioCommon.isMuted,
    isLive: true,
    isFullscreen: false,
    overlayTimeout: 2000,
    volume: this.playerControlsAudioCommon.volume,
    currentVolume: this.playerControlsAudioCommon.currentVolume,
    liveFinished: false
  }

  protected isVolumeControl: boolean = false;
  protected isOverlayOn: boolean = false;
  protected isProgressChanging: boolean = false;
  protected isOnOverlayBottom: boolean = false;
  protected mouseMoveTimestamp: number = 0;
  protected offsetCurrent: number = 0;
  protected pauseTimeStamp: number = 0;
  protected hlsInfo: IPlayerHlsClientInfo = {
    valid: false,
    clientId: null,
    url: '',
    segmentDuration: 0,
    segmentFirst: 0,
    start: new Date()
  };
  protected isReady: boolean = false;
  protected timerIntervalSubscription: Subscription = new Subscription();
  protected dataJsonPlayerSubscription: Subscription = new Subscription();
  protected lowLatency: boolean = false;
  protected passwordJson: IPassword = {
    password: ''
  };
  protected showingAd: boolean = false;

  protected _data: IPlayerData = {
    id: 0,
    name: 'X',
    token: 'xxx-xxx-xxx',
    start: new Date(),
    protected: false,
    password: '',
    status: ''
  }

  protected settingsForm: FormGroup = new FormGroup([]);
  protected itemsPasswordSettings: IAvSettingsItemConfig[] = [
    {
      id: 'password',
      type: AvProdSettingsType.password,
      name: 'general.password',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    }
  ];

  protected pipActive: boolean = false;

  protected setReady(ready: boolean) {
    // Data is ready, can start player
    if (ready) {
      // We define if it is vod or live
      console.log('[PlayerComponent] ready: ' + ready + JSON.stringify(this._data));
      if (this._data.status === 'archived') {
        this._mode = 'vod';
      } else {
        this._mode = 'live';
      }
      if (this.timerIntervalSubscription) {
        this.timerIntervalSubscription.unsubscribe();
      }
      this.timerIntervalSubscription = timer(500, 1000).subscribe(() => this.tickInterval());
      if (this._data.protected) {
        this.displayModal(PlayerModals.passwordPrompt, 'player.protectedPasswordTitle');
      } else {
        this.createPlayer();
      }
    }
    this.isReady = ready;
  }

  totalTimeFinished: number = 0;
  timeDifference: number = 0;
  deviceService: DeviceService = inject(DeviceService);

  constructor(
    protected formatTime: FormatHoursTimePipe,
    protected router: Router,
    protected translate: TranslateService,
    protected document: any,
    protected http: HttpClient) {
    this.itemsPasswordSettings.forEach((element: IAvSettingsItemConfig) => {
      this.settingsForm.addControl(element.id, new FormControl(element.value, element.validators ?? []));
    });
  }

  protected displayAlert(config: IAlertData){
    // Must override
  }
  protected emitExitPage(value: boolean){
    // Must override
  }

  /*
    @HostListener('document:keydown', ['$event'])
      handleKeyboardEvent(event: KeyboardEvent) {
      console.log(event.key);
      if (event.key === ('ArrowLeft' || 'ArrowRight') && this.isOnOverlayBottom) {

      }
      if ((event.key === 'ArrowDown' || 'ArrowUp') && this.isVolumeControl) {
        let volume;
        const ev: any = undefined;
        if (event.key === 'ArrowDown'){
          volume = (this.playerControls.volume > 0) ? this.playerControls.volume - 10 : 0;
        }
        else{
          volume = (this.playerControls.volume < 100) ? this.playerControls.volume + 10 : 100;
        }
        this.volumePressed(ev,volume);
      }
    }
  */

  protected init(containerElement: ElementRef, videoElement: ElementRef): void {

    this._containerElement = containerElement;
    this._videoElement = videoElement;

    this.progressValueSubjectSubscription = this.progressValueSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.changeProgressFiltered();
      });
  }

  protected destroy(): void {

    this.playerControlsAudioCommon.isMuted = this.playerControls.isMuted;
    this.playerControlsAudioCommon.volume = this.playerControls.volume;
    this.playerControlsAudioCommon.currentVolume = this.playerControls.currentVolume;

    if (this.progressValueSubjectSubscription) {
      this.progressValueSubjectSubscription.unsubscribe();
    }

    if (this.timerIntervalSubscription) {
      this.timerIntervalSubscription.unsubscribe();
    }

    if (this.dataJsonPlayerSubscription) {
      this.dataJsonPlayerSubscription.unsubscribe();
    }

    if (this.player) {
      this.destroyPlayer();
    }
    if (this.dataJsonPlayerSubscription) {
      this.dataJsonPlayerSubscription.unsubscribe();
    }
  }

  protected hideModal(): void {
    // to be overridden
  }

  protected showModal(): void {
    // to be overridden
  }

  protected displayModal(page: PlayerModals, title: string): void {
    this.displayModalPage = page;
    this.displayModalTitle = title;
    if (this.displayModalPage === PlayerModals.none) {
      this.hideModal();
    } else {
      this.showModal();
    }
  }

  protected checkFullscreen(elem: any): void {
    if ((elem.fullscreenElement === undefined || elem.fullscreenElement === null) &&  // Chrome
      (elem.webkitFullscreenElement === undefined || elem.webkitFullscreenElement === null)) {  // iOS
      this.playerControls.isFullscreen = false;
    }
  }

  protected createPlayer(): void {
    this.hlsInfo.valid = false;
    this.hlsInfo.clientId = null;
    this.hlsInfo.url = this.urlPlayerDomain + this._data.token;

    this.getJsonDataPlayer();

    // Live mode we do calls to .data each 10 seconds
    //if (this._mode === 'live' && !this.playerControls.liveFinished) {
    //  this.dataJsonPlayerSubscription = timer(100, 10000).subscribe(() => this.getJsonDataPlayer());
    //}
  }

  private static getLocalStorage(): LocalStorageTokenList | undefined {
    const PLAYER_INFO = JSON.parse(localStorage.getItem(PLAYER.storageSection) ?? '{}');
    if (Object.prototype.hasOwnProperty.call(PLAYER_INFO, 'id')) {
      return undefined;
    }
    return PLAYER_INFO;
  }

  private static setLocalStorage(value: LocalStorageTokenList): boolean {
    localStorage.setItem(PLAYER.storageSection, JSON.stringify(value));
    return true;
  }

  private static checkTokenList(value: LocalStorageTokenList): LocalStorageTokenList {
    const LENGTH: number = Object.keys(value).length;

    if (LENGTH > 5) {
      let toDelete = [];

      for (const token in value) {
        toDelete.push([token, Date.now() - value[token].ts]);
      }

      toDelete = toDelete.sort((a, b) => (a[1] > b[1] ? -1 : 1)).slice(0, length - 5);

      for (const i in toDelete) {
        delete value[toDelete[i][0]];
      }
    }
    return value;
  }

  /**
   * Sets viewer id of an event
   *
   * @param token
   * @param playerInfo
   */
  private static setEventInfo(token: string, playerInfo: JsonSegmentPlayer | null): void {
    console.log('[PlayerClass] setEventInfo ' + JSON.stringify(playerInfo));
    let tokenList: LocalStorageTokenList = this.getLocalStorage() ?? {};

    if (playerInfo && playerInfo.id) {
      tokenList[token] = {id: playerInfo.id, ts: Date.now()};
    } else if (Object.prototype.hasOwnProperty.call(tokenList, token)) {
      delete tokenList[token];
    }

    tokenList = this.checkTokenList(tokenList);
    this.setLocalStorage(tokenList);
  }

  /**
   * Formats playlist .data call into model
   *
   * @param response
   */
  private static handleJsonDataResponse(response: ISegment): JsonSegmentPlayer {
    const jsonSegment: JsonSegmentPlayer = {
      id: null,
      first: 0,
      duration: 0,
      sequence: 0,
      start: 0,
      end: 0,
      now: 0,
    };
    const intervals: IIntervals[] = response.intervals;
    jsonSegment.first = intervals[intervals.length - 1].first;
    jsonSegment.duration = intervals[intervals.length - 1].duration;
    jsonSegment.sequence = intervals[intervals.length - 1].sequence;
    jsonSegment.id = response.id;
    jsonSegment.now = response.now;
    jsonSegment.start = response.start;
    if (typeof (response.end) !== 'undefined') {
      jsonSegment.end = response.end;
    }
    if (typeof (response.show_ad) !== 'undefined') {
      jsonSegment.show_ad = response.show_ad;
    }
    return jsonSegment;
  }

  protected openPlayer(token: string, passwordJson: IPassword): Promise<any> {
    const viewerId = this.getIdViewer(token);
    const URL_PLAYER_DOMAIN = PLAYER.protocol + PLAYER.playlistDomain + '/';
    let URL_PLAYER = URL_PLAYER_DOMAIN + token;

    if ((viewerId !== null) && (viewerId !== undefined)) {
      URL_PLAYER += '?id=' + viewerId;
    }

    return new Promise((resolve) => {
      this.requestJsonDataPlayer(URL_PLAYER, passwordJson)
        .subscribe(async (json: any) => {
          if (typeof json !== 'number') {
            PlayerClass.setEventInfo(token, json);
          }
          resolve(json);
        });
    });
  }

  protected requestJsonDataPlayer(url: string, passwordJson: IPassword): Observable<JsonSegmentPlayer> {
    console.log('[PlayerClass] requestJsonDataPlayer ' + JSON.stringify(passwordJson));
    return this.http.post(url, passwordJson)
      .pipe(
        map((response: any) => PlayerClass.handleJsonDataResponse(response)),
        catchError((err: HttpErrorResponse, caught: Observable<any>) => {
          return of(err.status);
        })
      );
  }

  protected getIdViewer(token: string): string | null {
    const JSON_ANSWER: LocalStorageTokenList | undefined = PlayerClass.getLocalStorage();
    let id = null;

    if (JSON_ANSWER && Object.prototype.hasOwnProperty.call(JSON_ANSWER, token)) {
      id = JSON_ANSWER[token].id;
    }

    if (id !== null) {
      console.log('[PlayerService] Found viewer ID ' + id + ' of event with token ' + token);
    }
    return id;
  }

  protected getJsonDataPlayer(): void {
    // Load json data so we can manipulate timeline.
    // {'first': 1, 'sequence': 373, 'duration': 2}
    // First: First segment of the video
    // Sequence: Last segment of the video
    // Duration of the video

    if (this.hlsInfo.clientId === null) {
      this.openPlayer(this._data.token, this.passwordJson)
        .then((json: any) => {
          if (typeof json === 'number') {
            let msg = 'player.unknownError';

            if (json == 401) {
              msg = 'player.invalidPassword';
            } else if (json === 429) {
              msg = 'player.tooManyRequests';
            } else if (json === 423) {
              msg = 'player.tooManyViewers';
            } else if (json === 503) {
              msg = 'player.notReady';
            }

            if ((this._data.protected)&&(json === 401)) {
              console.warn('Data protected');

              if (this.dataJsonPlayerSubscription) {
                this.dataJsonPlayerSubscription.unsubscribe();
              }

              this._data.password = '';
              this.passwordMsgText = msg;
              this.displayModal(PlayerModals.passwordPrompt, 'player.protectedPasswordTitle');
            }
            // else if (json === 503) {
            //   // NOTE: this status code is sent when the event is not ready (the PM has not received yet the information
            //   // about the event) or, in VOD, if there are no information about intervals
            //   console.warn('Event not ready');
            //   // TODO: show an alert to let user know the event is not ready and request .data until event is finally ready
            // }
            else {
              console.warn('[PlayerClass] error: ' + msg);
              this.dataJsonPlayerSubscription?.unsubscribe();

              this.displayAlert({
                show: true,
                status: AlertStatus.error,
                title: 'general.error',
                text: msg,
                closeButton: true,
                buttons: [{
                    text: 'general.exit',
                    color: 'primary',
                    fill: 'outline',
                    closeButton: true,
                    handler: (): void => {
                      this.emitExitPage(true);
                    }
                  },
                  {
                    text: 'general.retry',
                    color: 'primary',
                    closeButton: true,
                    handler: (): void => {
                      this.getJsonDataPlayer();
                    }
                  }]
              });
            }
          } else {
            this.displayModal(PlayerModals.none, '');
            this.receiveVideoServerInitialAnswer(json);

            if (this._mode === 'live' && !this.playerControls.liveFinished) {
              this.dataJsonPlayerSubscription = timer(10000, 10000).subscribe(() => this.getJsonDataPlayer());
            }
          }
        });
    } else {
      this.requestJsonDataPlayer(this.hlsInfo.url, this.passwordJson)
        .subscribe(answer => this.receiveVideoServerInitialAnswer(answer));
    }
  }

  protected createHls(): void {
    if (this.player !== null) {
      if (Hls.isSupported()) {
        if (this.hlsPlayer !== null) {
          this.hlsPlayer.detachMedia();
          this.hlsPlayer.stopLoad();
        }
        this.hlsPlayer = new Hls();

        this.hlsPlayer.loadSource(this.hlsInfo.url);
        this.hlsPlayer.attachMedia(this.player);
        this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
          if (this.player != null) {
            this.player.play()
              .catch(() => {
                if (this.player) {
                  this.player.muted = true;
                  this.player.play().catch(console.error);
                }
              });
          }
        });
      } else if (this.player.canPlayType('application/vnd.apple.mpegurl')) {
        this.player.src = this.hlsInfo.url;
        this.player.play()
          .catch(() => {
            if (this.player) {
              this.player.muted = true;
              this.player.play().catch(console.error);
            }
          });
      }

      this.applyAudioSettings();
    }
  }

  protected startPlayer(): void {
    if (this.hlsInfo.valid) {
      if (this._mode === 'live') {
        this.hlsInfo.url = this.urlPlayerDomain + this._data.token + '?id=' + this.hlsInfo.clientId + '&offset=' + this.offsetCurrent;
      } else {
        this.hlsInfo.url = this.urlPlayerDomain + this._data.token + '?id=' + this.hlsInfo.clientId;
      }
      this.playerControls.isPaused = false;

      // Better using @ViewChild
      this.player = this._videoElement?.nativeElement as HTMLMediaElement;
      this.videoPlayer = this._videoElement?.nativeElement as HTMLVideoElement;

      this.createHls();

      if (this.player !== null) {
        this.player.addEventListener('stalled', this.handlePlayerError.bind(this), true);
        this.player.addEventListener('ended', this.handlePlayerError.bind(this), true);
        this.player.addEventListener('error', this.handlePlayerError.bind(this), true);
        this.player.addEventListener('pause', this.handlePlayerError.bind(this), true);
      }
    }
  }

  protected destroyPlayer(): void {
    if (this.hlsPlayer != null) {
      this.hlsPlayer.stopLoad();
      this.hlsPlayer.destroy();
      this.hlsPlayer = null;
    }
    if (this.player != null) {
      this.player.removeEventListener('stalled', this.handlePlayerError.bind(this), true);
      this.player.removeEventListener('ended', this.handlePlayerError.bind(this), true);
      this.player.removeEventListener('error', this.handlePlayerError.bind(this), true);
      this.player.addEventListener('pause', this.handlePlayerError.bind(this), true);
      this.player.src = '';
      this.player.currentTime = 0;
      this.player = null;
    }
    if (this.videoPlayer !== null) {
      this.videoPlayer = null;
    }
    if (this.dataJsonPlayerSubscription) {
      this.dataJsonPlayerSubscription.unsubscribe();
    }
  }

  protected receiveVideoServerInitialAnswer(answer: JsonSegmentPlayer): void {
    console.log('[PlayerComponent] Initial answer: ' + JSON.stringify(answer));
    this.hlsInfo.serverTotalTimeCurrent = answer.now;

    if (this.hlsInfo.serverTotalTimeCurrent) {
      if (Math.abs(Date.now() - this.hlsInfo.serverTotalTimeCurrent) > 3000) {
        this.timeDifference = Date.now() - this.hlsInfo.serverTotalTimeCurrent;
      }
    }

    if (this.hlsInfo.clientId === null) {
      this.hlsInfo.clientId = answer.id;
      this.hlsInfo.segmentFirst = answer.first;
      this.hlsInfo.segmentDuration = answer.duration;
      this.hlsInfo.serverTotalTimeCurrent = answer.now;
      this.hlsInfo.start = new Date(answer.start);
      this.hlsInfo.valid = true;
      this.startPlayer();
    } else {
      // If we receive an end signal from event finalization
      if (answer.end) {
        if (answer.end > 0 && !this.playerControls.liveFinished) {
          this.playerControls.liveFinished = true;
          this.totalTimeFinished = answer.end;
        }
      }
    }

    // Avoid advertisements for now
    //if (answer.show_ad && !ENV_COMMON.production) {
    //  this.showAd('full');
    //}
  }

  protected tickInterval(): void {

    if (this.isOverlayOn && (Date.now() > this.mouseMoveTimestamp + this.playerControls.overlayTimeout) && (!this.isOnOverlayBottom)) {
      this.isOverlayOn = false;
    }

    if ((this.hlsInfo !== undefined) && (this.player != null)) {
      let totalTime;
      const dateNow = this.getTimeNow();
      let realTotalTime = 0;
      if (this._mode === 'live') {
        totalTime = this.getTotalTime();
        if (this.playerControls.liveFinished) {
          realTotalTime = Math.floor((this.totalTimeFinished - this.hlsInfo.start.getTime()) / 1000);
          if (this.offsetCurrent > 0 && !this.isProgressChanging) {
            this.offsetCurrent--;
          } else {
            if (this.player !== null) {
              this.destroyPlayer();
            }
          }
          if (this.dataJsonPlayerSubscription) {
            this.dataJsonPlayerSubscription.unsubscribe();
          }
        }
        if (this.playerControls.isPaused) {
          if (this.pauseTimeStamp !== 0) {
            this.offsetCurrent = Math.floor((dateNow - this.pauseTimeStamp) / 1000);
            this.currentTime = Math.round((this.pauseTimeStamp - this.hlsInfo.start.getTime()) / 1000);
          }
        } else {
          this.currentTime = Math.round(((dateNow - this.hlsInfo.start.getTime()) / 1000) - this.offsetCurrent);
        }
      } else {
        totalTime = Math.floor(this.player?.duration);
        this.currentTime = Math.floor(this.player?.currentTime);
      }

      if (this._mode === 'live' && this.playerControls.liveFinished && (this.currentTime >= realTotalTime)) {
        if (this.player !== null) {
          // PlayerService.setEventInfo(this._data.token, null);
          this.destroyPlayer();
        }

        this.showEventFinishedAlert();
      }

      if (this.playerControls.isLive && this._mode === 'live') {
        this.currentTimeFormatted = this.formatTime.transform(totalTime, 0);
      } else {
        this.currentTimeFormatted = this.formatTime.transform(this.currentTime, 0) + ' / ' + this.formatTime.transform(totalTime, 0);
      }

      if (!this.isProgressChanging) {
        this.updateProgress();
      }
      if (this.player !== null && !this.showingAd) {
        //if (this.player.ended && this._mode !== 'live'){
        // Update isPaused flag if not aligned
        if (this.player.paused && !this.playerControls.isPaused) {
          this.playerControls.isPaused = true;
        } else if (!this.player.paused && this.playerControls.isPaused) {
          this.playerControls.isPaused = false;
        }
        //}
      }
    }
  }

  protected mouseMove(): void {
    this.mouseIn();
  }

  protected mouseIn(): void {
    if (this.isReady) {
      this.mouseMoveTimestamp = Date.now();
      this.isOverlayOn = true;
    }
  }

  protected mouseOff(): void {
    this.mouseMoveTimestamp = this.playerControls.overlayTimeout;
    this.isOverlayOn = false;
    this.isVolumeControl = false;
  }

  protected mutePressed(): void {
    this.mouseIn();
    this.playerControls.isMuted = !this.playerControls.isMuted;
    if (this.player) {
      this.player.muted = this.playerControls.isMuted;
    }
    if (!this.playerControls.isMuted) {
      this.playerControls.volume = (this.playerControls.currentVolume > 0) ? this.playerControls.currentVolume : 50;
    } else {
      this.playerControls.currentVolume = this.playerControls.volume;
      this.playerControls.volume = 0;
    }
    this.setVolume();
    if (!this.isVolumeControl) {
      this.showVolumeControls();
    } else {
      this.isVolumeControl = false;
    }
  }

  protected showVolumeControls(): void {
    this.isVolumeControl = true;
  }

  protected volumePressed(event: any, value?: number | undefined): void {
    //if (typeof (value) !== 'undefined') {
    //  this.playerControls.volume = value;
    //} else {
    //  this.playerControls.volume = event.value;
    //}

    this.playerControls.isMuted = this.playerControls.volume <= 0;
    if (this.player) {
      this.player.muted = this.playerControls.isMuted;
    }
    this.playerControls.currentVolume = this.playerControls.volume;
    this.setVolume();
  }

  protected setVolume(): void {
    if (this.player != null) {
      this.player.volume = this.playerControls.volume / 100;
    }
  }

  protected applyAudioSettings(): void {
    console.log('[PlayerComponent] Apply audio');
    if (this.player != null) {
      this.player.muted = this.playerControls.isMuted;
    }
    this.setVolume();
  }

  protected playPressed(): void {
    this.mouseIn();
    const dateNow = this.getTimeNow();
    if (this.player !== null) {
      if (this.player.paused) {
        this.player.play()
          .catch(console.error);
        this.playerControls.isPaused = false;
        // Calculate new current Offset
        if (this.pauseTimeStamp !== 0) {
          this.offsetCurrent = Math.floor((dateNow - this.pauseTimeStamp) / 1000);
          this.pauseTimeStamp = 0;
        } else {
          this.offsetCurrent = 0;
          this.startPlayer();
        }
      } else {
        // Calculate paused time stamp
        this.pauseTimeStamp = dateNow - (this.offsetCurrent * 1000);
        this.playerControls.isPaused = true;
        this.player.pause();
      }
    }
  }

  protected updateProgress(): void {
    if (this.player) {
      let newProgress;
      let totalTime = 0;
      let currentTime = 0;
      if (this.offsetCurrent === 0 && this._mode === 'live') {
        this.playerControls.isLive = true;
        this.progressValue = 100;
      } else {
        if (this._mode === 'live') {
          this.playerControls.isLive = false;
          totalTime = this.getTotalTime();
          currentTime = this.offsetCurrent;
        } else {
          totalTime = Math.floor(this.player.duration);
          currentTime = totalTime - this.currentTime;
        }
        newProgress = ((totalTime - currentTime) / totalTime) * 100;
        if (newProgress < 0) {
          newProgress = 0;
        }
        this.progressValue = newProgress;
      }
    }
  }

  protected changeProgress(): void {
    this.isProgressChanging = true;
    this.progressValueSubject.next(this.progressValue);
    if (this.player === null && this.playerControls.liveFinished) {
      this.createPlayer();
    }
  }

  protected changeProgressFiltered(): void {
    this.playerControls.isLive = this.progressValue === 100;

    if (this.player && this.hlsInfo !== undefined) {
      this.isOverlayOn = true;
      this.mouseMoveTimestamp = Date.now();

      let newOffset;
      let totalTime;
      let currentTime;

      if (this._mode === 'live') {
        totalTime = this.getTotalTime();
        currentTime = this.offsetCurrent;
      } else {
        totalTime = Math.floor(this.player.duration);
        currentTime = this.player.currentTime;
      }
      if (this.progressValue === 100) {
        newOffset = 0;
      } else if (this.progressValue === 0) {
        newOffset = totalTime;
      } else {
        newOffset = (100 - this.progressValue) * totalTime / 100;
      }

      if (newOffset < 0) {
        newOffset = 0;
      } else if (newOffset > totalTime) {
        newOffset = totalTime;
      }

      if (newOffset !== currentTime) {
        this.changeOffset(newOffset);
      }
    }
    this.isProgressChanging = false;
  }

  protected changeOffset(offset: number): void {
    if (this.player === null && this.playerControls.liveFinished) {
      this.createPlayer();
    }
    this.offsetCurrent = Math.round(offset);

    if (this._mode === 'live') {
      this.hlsInfo.url = this.urlPlayerDomain + this._data.token + '?id=' + this.hlsInfo.clientId + '&offset=' + this.offsetCurrent;
      this.playerControls.isPaused = false;

      if (this.lowLatency) {
        this.hlsInfo.url += '&mode=ll';
      }

      this.createHls();
      this.updateProgress();
    } else {
      if (this.player) {
        this.player.currentTime = Math.floor(this.player.duration) - offset;
      }
    }
  }

  protected stepBackPressed(): void {
    if (this.player) {
      let newOffset;
      let totalTime;
      let currentTime;
      if (this._mode === 'live') {
        newOffset = this.offsetCurrent + 10;
        totalTime = this.getTotalTime();
        currentTime = this.offsetCurrent;
      } else {
        totalTime = Math.floor(this.player.duration);
        currentTime = Math.floor(this.player.currentTime);
        newOffset = (totalTime - currentTime) + 10;
      }

      if (newOffset < 0) {
        newOffset = 0;
      } else if (newOffset > totalTime) {
        newOffset = totalTime;
      }

      if (newOffset !== currentTime) {
        this.changeOffset(newOffset);
      }
    }
  }

  protected stepForthPressed(): void {
    if (this.player) {
      let newOffset: number;
      let currentTime;
      let totalTime;
      if (this._mode === 'live') {
        newOffset = this.offsetCurrent - 10;
        currentTime = this.offsetCurrent;
      } else {
        totalTime = Math.floor(this.player.duration);
        currentTime = Math.floor(this.player.currentTime);
        newOffset = (totalTime - currentTime) - 10;
      }

      if (newOffset < 0) {
        newOffset = 0;
      }

      if (newOffset !== currentTime) {
        this.changeOffset(newOffset);
      }
    }
  }

  protected openFullscreen(elem: any): void {
    //# for most browsers
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }

    //# for Safari (older versions)
    else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }

    //# for Safari (newer versions)
    else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen();
    }

    //# for Safari iPhone (where only the Video tag itself can be fullscreen)
    else if (elem.children && elem.children[0].webkitEnterFullscreen) {
      elem.children[0].webkitEnterFullscreen();
      //toggle_controls(); //# your own function to show/hide iOS media controls
    }

    //# for Internet Explorer 11
    else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  protected closeFullscreen(elem: any): void {
    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.mozCancelFullScreen) {
      elem.mozCancelFullScreen();
    } else if (elem.webkitExitFullscreen) {
      elem.webkitExitFullscreen();
    } else if (elem.children && elem.children[0].webkitExitFullscreen) {
      elem.children[0].webkitExitFullscreen();
    }
    // else if (elem.msExitFullscreen) {
    //   window.top!.document.msExitFullscreen();
    // }
  }

  protected fullScreenPressed(): void {
    if (this._containerElement !== undefined) {
      if (this.playerControls.isFullscreen) {
        this.playerControls.isFullscreen = false;
        // this.document.exitFullscreen();
        this.closeFullscreen(this.document);
      } else {
        if (this.document.pictureInPictureElement) {
          this.document.exitPictureInPicture()
            .catch(() => console.error('This platform does not have PiP to exit'));
        }
        if (this.player !== null) {
          if (this.player.canPlayType('application/vnd.apple.mpegurl')) {
            // Enter full screen for iOS (check several cases)
            this.openFullscreen(this._containerElement.nativeElement);
          } else {
            this._containerElement.nativeElement.requestFullscreen()
              .catch(console.error);
          }
        }

        this.playerControls.isFullscreen = true;
      }
    }
  }

  protected pipScreenPressed(): void {
    if (this.videoPlayer !== null) {
      this.pipActive = true;

      if (this.deviceService.device?.platform === 'android') {
        if(!this.playerControls.isFullscreen) {
          this.openFullscreen(this._containerElement?.nativeElement);
          window.PictureInPicture.enter(384, 216, () => {});
        }

      } else {
        this.playerControls.isFullscreen = false;
        this.videoPlayer?.requestPictureInPicture()
          .catch(console.error);
      }
    }
  }

  protected goToLive(): void {
    this.mouseIn();
    this.progressValue = 100;
    this.playerControls.isLive = true;
    this.changeProgress();
  }

  protected goToStart(): void {
    this.mouseIn();
    this.progressValue = 0;
    this.playerControls.isLive = false;
    this.changeProgress();
  }

  protected getTotalTime(): number {
    const dateNow = this.getTimeNow();
    return Math.floor((dateNow - this.hlsInfo.start.getTime()) / 1000);
  }

  // We have to calculate time difference between server and client
  protected getTimeNow(): number {
    let dateNow;
    if (this.playerControls.liveFinished) {
      dateNow = this.totalTimeFinished - this.timeDifference;
    } else {
      dateNow = Date.now() - this.timeDifference;
    }
    return dateNow;
  }

  protected setPassword(pwd: string): void {
    if (pwd !== ''){
      this._data.password = pwd;
      this.passwordJson.password = this._data.password;
      this.createPlayer();
    }
    else{
      this.displayModal(PlayerModals.passwordPrompt, 'player.protectedPasswordTitle');
    }
  }

  protected onButtonDblClick(event: any): void {
    // Do nothing on button double click, but avoid general double click for full screen
    event.stopPropagation();
  }

  protected handlePlayerError(error: any): void {
    if (error.type === 'pause') {
      if (document.hidden && !this.playerControls.isPaused && this.player) {
        this.player.play().catch(console.error);
      }
    } else {
      console.error('HandlePlayerError:', error.type, error);
      console.log('Error code: ' + this.player?.error?.code);
      console.log('Error message: ' + this.player?.error?.message);

      this.checkEventFinished();
    }
  }

  protected checkEventFinished(): void {
    if ((this._mode === 'live') && (!this.playerControls.liveFinished)) {
      this.getJsonDataPlayer();

      // Call tickInterval to check if event has finished and show alert
      this.tickInterval();
    }
  }

  protected showEventFinishedAlert(): void {
    //this.displayModal(PlayerModals.eventFinished, 'player.eventFinished');
    this.displayAlert({
      show: true,
      status: AlertStatus.warning,
      title: 'player.eventFinished',
      text: 'player.eventFinishedDescription',
      closeButton: true,
      buttons: [{
          text: 'general.exit',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
          handler: (): void => {
            this.emitExitPage(true);
          }
        }]
    });
  }

  protected navigateHome(): void {
    // Empty parent class to be redefined by child
  }

  // protected finishedGoHome(): void {
  //   this.displayModal(PlayerModals.eventFinished, 'player.eventFinished');
  //   this.navigateHome();
  // }

  protected onClickLowLatency(): void {
    this.lowLatency = !this.lowLatency

    this.hlsInfo.url = this.urlPlayerDomain + this._data.token + '?id=' + this.hlsInfo.clientId;
    this.playerControls.isPaused = false;
    this.playerControls.isLive = true;

    // Enable low latency mode
    if (this.lowLatency) {
      this.hlsInfo.url += '&mode=ll';
      this.progressValue = 100;
      this.offsetCurrent = 0;

      // Disable low latency mode
    } else {
      console.log('LL mode disabled');
    }
    this.createHls();
    this.updateProgress();
  }

  protected showAd(type_display: string): void {
    fetch('https://www.videosprofitnetwork.com/watch.xml?key=' + ENV_WEB.vastKey)
      .then(response => response.text())
      .then(vastXml => {
        // Parse the VAST XML into a DOM object
        const parser = new DOMParser();
        const vastDom = parser.parseFromString(vastXml, 'text/xml');

        // Get the first ad and the first media file
        const ad = vastDom.querySelector('Ad');
        if (!ad) {
          throw new Error('No ad found in the VAST tag');
        }
        const mediaFile = ad.querySelector('MediaFile');
        if (!mediaFile) {
          throw new Error('No media file found in the VAST tag');
        }
        const mediaFileUrl = mediaFile.textContent;
        if (!mediaFileUrl) {
          throw new Error('Media file URL not found in the VAST tag');
        }
        const container_div = this.document.querySelector('.video-container')
        const video_div = this.document.querySelector('#video-azz')
        // Create an HTMLVideoElement to display the ad and remove if there was a previous
        if (this.document.querySelector('#vast-ad')) {
          this.document.querySelector('#vast-ad').remove();
        }
        const AD_PLAYER: HTMLVideoElement = document.createElement('video') as HTMLVideoElement;
        AD_PLAYER.id = 'vast-ad';
        AD_PLAYER.src = mediaFileUrl;
        AD_PLAYER.style.width = '100%'; //container_div.offsetWidth;
        AD_PLAYER.style.display = 'none';
        AD_PLAYER.muted = true;
        AD_PLAYER.playsInline = true;
        AD_PLAYER.autoplay = true;

        container_div.appendChild(AD_PLAYER);

        // Show the ad and hide the video player when the ad starts playing
        AD_PLAYER.onplay = () => {
          this.showingAd = true;
          // Disabling buttons
          //this.document.querySelector('#overlayAdButton').disabled = true;
          //this.document.querySelector('#fullScreenAdButton').disabled = true;
          if (type_display == 'full') {
            // Pause the HLS video if we want
            // if not live
            if (this._mode !== 'live') {
              this.videoPlayer?.pause();
              if (this.videoPlayer) {
                this.videoPlayer.currentTime -= 1; // Rewind 1s to keep track of the video after the ad
              }
            }
            if (this.videoPlayer) {
              this.videoPlayer.muted = true;
              this.videoPlayer.style.display = 'none';
            }
          } else if (type_display == 'overlay') {
            AD_PLAYER.style.width = '35%';
            AD_PLAYER.style.position = 'absolute';
            video_div.style.position = 'relative';
            AD_PLAYER.style.bottom = '15%';
            AD_PLAYER.style.marginLeft = 'auto';
            AD_PLAYER.style.marginRight = '0';
            AD_PLAYER.style.right = '2%';
            AD_PLAYER.style.boxShadow = '0 3px0.5rem';
          }
          AD_PLAYER.style.display = 'block'; // Show the ad
        };

        // Play the ad
        AD_PLAYER.play().catch(console.warn);
        // Record the impression
        // We obtain the impression track url
        const impression = ad.querySelector('Impression');
        if (!impression) {
          throw new Error('No impression element found in the VAST tag');
        }
        const impressionUrl = impression.textContent;
        if (!impressionUrl) {
          throw new Error('Impression URL not found in the VAST tag');
        }
        // Append the impression track url to an image to track impression
        const IMAGE = new Image();
        IMAGE.src = impressionUrl;

        // Record the click
        // We obtain the video destination url
        const adClickThrough = ad.querySelector('ClickThrough');
        if (!adClickThrough) {
          throw new Error('No video click through element found in the VAST tag');
        }
        const adClickThroughUrl = adClickThrough.textContent;
        if (!adClickThroughUrl) {
          throw new Error('Click Tracking URL not found in the VAST tag');
        }
        // We obtain the video click tracking url
        const adClickTracking = ad.querySelector('ClickTracking');
        if (!adClickTracking) {
          throw new Error('No video click tracking element found in the VAST tag');
        }
        const adClickTrackingUrl = adClickTracking.textContent;
        if (!adClickTrackingUrl) {
          throw new Error('Click Tracking URL not found in the VAST tag');
        }
        // Append information to an event listener in case of click to track video click
        console.log(adClickTrackingUrl);
        AD_PLAYER.addEventListener('click', () => {
          window.open(adClickThroughUrl, '_blank');
          new Image().src = adClickTrackingUrl;
        });

        // Getting duration info in case needed
        const duration = ad.querySelector('Duration');
        if (!duration) {
          throw new Error('No duration element found in the VAST tag');
        }
        const durationInfo = duration.textContent;
        if (!durationInfo) {
          throw new Error('Duration info not found in the VAST tag');
        }
        const PARTS: string[] = durationInfo.split(':');
        const HOURS: number = parseInt(PARTS[0], 10);
        const MINUTES: number = parseInt(PARTS[1], 10);
        const SECONDS: number = parseInt(PARTS[2], 10);

        const durationAd = HOURS * 3600 + MINUTES * 60 + SECONDS;

        let currentTime = 0;
        const msg = this.translate.instant('player.showAdd');

        // Create a timer element
        const timerElement = document.createElement('div');
        container_div.appendChild(timerElement);
        timerElement.innerHTML = `${msg} ${('0' + Math.floor(durationAd / 60)).slice(-2)}:${('0' + Math.floor(durationAd % 60)).slice(-2)}`;
        timerElement.style.position = 'absolute';
        timerElement.style.bottom = '5%';
        timerElement.style.marginLeft = 'auto';
        timerElement.style.marginRight = '0';
        timerElement.style.padding = '1%';
        timerElement.style.right = '2%';
        timerElement.style.boxShadow = '0 3px0.5rem';
        timerElement.style.backgroundColor = 'white';

        // Update the timer on every timeupdate event
        AD_PLAYER.addEventListener('timeupdate', () => {
          currentTime = durationAd - AD_PLAYER.currentTime;
          timerElement.innerHTML = `${msg} ${('0' + Math.floor(currentTime / 60)).slice(-2)}:${('0' + Math.floor(currentTime % 60)).slice(-2)}`;
        });

        // After the ad finishes playing, hide the ad player and resume playing the HLS video
        AD_PLAYER.addEventListener('ended', () => {
          this.closeAdPlayer(AD_PLAYER, timerElement, video_div);
        });

        // Close adPlayer if error occurs (eg: video cannot be fetched due to Vodafone's adBlocker)
        AD_PLAYER.addEventListener('error', () => {
          console.warn('Ad cannot be fetched');
          this.closeAdPlayer(AD_PLAYER, timerElement, video_div);
        });
      });
  }

  protected closeAdPlayer(adPlayer: HTMLVideoElement, timerElement: HTMLDivElement, video_div: HTMLDivElement): void {
    if (this.player) {
      this.showingAd = false;
      timerElement.remove();
      adPlayer.style.display = 'none';
      this.player.style.display = 'block';
      video_div.style.width = '100%';
      video_div.style.height = '100%';
      this.videoPlayer?.play().catch(console.warn);
      this.applyAudioSettings();
    }
  }
}
